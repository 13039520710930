import { AppContext } from "../state";
import { ModelName } from "../../constants";
import { useContext } from "react";

export const usePermissions = (modelName: ModelName) => {
  const context = useContext(AppContext);
  const allowedTableInformation = context.allowedTables;

  const permissions = allowedTableInformation.find(
    (e) => e.intPkTable?.tableName === modelName
  );

  return {
    allowCreate: !!permissions?.allowCreate,
    allowRead: !!permissions?.allowRead,
    allowUpdate: !!permissions?.allowUpdate,
    allowDelete: !!permissions?.allowDelete,
  };
};
