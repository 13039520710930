import moment from "moment";
import { useMemo, useState } from "react";
import { MoonLoader } from "react-spinners";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { PaginationFooter } from "../../components/EditPage";
import { Switch } from "../../components/FormElements/Switch";
import { TabPane, TabView } from "../../components/TabView";
import { ModelName } from "../../constants";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import useModelAggregationQuery from "../../libs/hooks/useModelAggregationQuery";
import { usePermissions } from "../../libs/hooks/usePermissions";

const getVtbStateFromNumber = (number: number) => {
  switch (number) {
    case 0:
      return "failed";
    case 1:
      return "successful";
    case 2:
      return "doubt";
    case -1:
      return "null";
  }

  return "unknown";
};

const getColorFromState = (string: string) => {
  switch (string) {
    case "failed":
      return "#d63939";
    case "successful":
      return "#2fb344";
    case "doubt":
      return "#f59f00";
  }
};

export function VtbDashboard() {
  return (
    <div className="page-wrapper">
      <div className="container-xl">
        <div className="d-flex flex-row gap-2">
          <VtbPieChart model="node" title="Cisco Nodes" />
          <VtbPieChart model="proxyNode" title="Proxy Nodes" />
          <VtbPieChart model="vezSsNode" title="Shadowsocks Nodes" />
        </div>
        <TabView className="my-3">
          <TabPane title="Proxy Nodes">
            <VtbFlaggedTable
              model="proxyNode"
              nodeKeys={{
                id: "intPkProxyNodeID",
                sshUsername: "userName",
                sshPassword: "password",
                ip: "proxyIP",
              }}
            />
          </TabPane>
          <TabPane title="Cisco Nodes">
            <VtbFlaggedTable
              model="node"
              nodeKeys={{
                id: "intPkNodeID",
                sshUsername: "sshUserName",
                sshPassword: "sshPassword",
                ip: "nodeIP",
              }}
            />
          </TabPane>
          <TabPane title="ShadowSocks Nodes">
            <VtbFlaggedTable
              model="vezSsNode"
              nodeKeys={{
                id: "intPkVezSSNodeID",
                sshUsername: "sshUserName",
                sshPassword: "sshPassword",
                ip: "nodeIP",
              }}
            />
          </TabPane>
        </TabView>
      </div>
    </div>
  );
}

interface VtbPieChartProps {
  model: ModelName;
  title: string;
}

const formatPercentage = (value: number) => `${value?.toFixed(0) || " - "}%`;

function VtbPieChart({ model, title }: VtbPieChartProps) {
  const nodePermission = usePermissions(model);
  const { data, isLoading } = useModelAggregationQuery({
    model,
    enabled: nodePermission.allowRead,
  });
  const pieChartData = useMemo(
    () =>
      data?.result
        ?.find((e) => e.name === "vtbPieChart")
        ?.value?.map((each: any) => ({
          name: getVtbStateFromNumber(each["lastVtbTestResult"]),
          value: each["count"],
        })) || [],
    [data]
  );

  const totalNodes = useMemo(
    () =>
      pieChartData?.reduce(
        (prev: number, curr: any) => (prev += curr.value),
        0
      ),
    [pieChartData]
  );
  const successfulNodesPer = formatPercentage(
    ((pieChartData.find((each: any) => each.name === "successful")?.value ||
      0) /
      totalNodes) *
      100
  );
  const doubtNodesPer = formatPercentage(
    ((pieChartData.find((each: any) => each.name === "doubt")?.value || 0) /
      totalNodes) *
      100
  );
  const failedNodesPer = formatPercentage(
    ((pieChartData.find((each: any) => each.name === "failed")?.value || 0) /
      totalNodes) *
      100
  );

  if (!nodePermission.allowRead) {
    return <span>Permission Denied</span>;
  }

  return (
    <div className="card flex-fill">
      <div className="card-header">
        <h2>{title}</h2>
      </div>
      <div className="card-body">
        {isLoading && <MoonLoader size={30} loading={true} />}
        <div className="row justify-content-between">
          <div className="col-5">
            <div>
              <b>Enabled Nodes:</b>{" "}
              {data?.result?.find((aggre) => aggre.name === "Enabled")?.value}{" "}
              <br />
              <b>Successful:</b> {successfulNodesPer}
              <br />
              <b>Doubt:</b> {doubtNodesPer}
              <br />
              <b>Failed:</b> {failedNodesPer}
            </div>
          </div>
          <div className="col-1" style={{ width: "225px" }}>
            <PieChart width={225} height={200}>
              <Pie
                data={pieChartData}
                cx="50%"
                cy="39%"
                dataKey="value"
                legendType="triangle"
              >
                {pieChartData.map((datapoint: any, index: number) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={getColorFromState(datapoint.name)}
                    />
                  );
                })}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
        </div>
      </div>
    </div>
  );
}

interface VtbFlaggedTableProps {
  model: ModelName;
  nodeKeys: NodeKeys;
}

interface NodeKeys {
  id: string;
  ip: string;
  sshUsername: string;
  sshPassword: string;
}

function VtbFlaggedTable({ model, nodeKeys }: VtbFlaggedTableProps) {
  const fetcher = useFetcher({ alerts: true });
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const idKey = nodeKeys["id"];

  const [busy, setBusy] = useState<Record<string, boolean>>({});
  const { data, isLoading, refetch } = useAuthorizedQuery({
    model,
    limit,
    offset,
    filter: {
      flagged: { equals: 0 },
      lastVtbTestResult: {
        not: 1,
      },
    },
  });

  const updateNode = async (id: number, data: any) => {
    setBusy((prev) => ({ ...prev, [id]: true }));
    await fetcher({
      url: `/api/v1/data/${model}/${id}/edit`,
      method: "put",
      data,
      successMessage: "Successfully Edited",
    }).finally(() => setBusy((prev) => ({ ...prev, [id]: false })));
    refetch();
  };

  const runAction = async (action: string, nodeId: number) => {
    setBusy((prev) => ({ ...prev, [nodeId + action]: true }));

    try {
      await fetcher({
        url: `/api/v1/data/${model}/runAction`,
        method: "post",
        data: {
          action,
          nodeId,
        },
      });
    } catch (e) {}

    setBusy((prev) => ({ ...prev, [nodeId + action]: false }));
  };

  return (
    <div>
      {isLoading && "Loading..."}
      <table className="table  card-table text-nowrap datatable table-vcenter">
        <thead>
          <tr>
            <th>Id</th>
            <th>IP / URL</th>
            <th>Test time</th>
            <th>Last Status</th>
            <th>Enable</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.result?.rows.map((row: any) => {
            return (
              <tr>
                <td>{row[idKey]}</td>
                <td>{row["nodeIP"] || row["proxyDomain"]}</td>
                <td>{moment(row["lastVtbTime"]).fromNow()}</td>
                <td
                  style={{
                    color: getColorFromState(
                      getVtbStateFromNumber(row["lastVtbTestResult"]) || ""
                    ),
                  }}
                >
                  {getVtbStateFromNumber(row["lastVtbTestResult"])}
                </td>
                <td>
                  <Switch
                    value={row["enable"]}
                    disabled={busy[row[idKey]]}
                    onChange={(value) =>
                      updateNode(row[idKey], { enable: value })
                    }
                  />
                </td>
                <td className="d-flex gap-1">
                  <button
                    className="btn btn-link"
                    disabled={busy[row[idKey] as string] as boolean}
                    onClick={() => {
                      updateNode(row[idKey], {
                        flagged: true,
                      });
                    }}
                  >
                    Flag
                  </button>
                  <button
                    className="btn btn-danger"
                    disabled={busy[row[idKey] + "reboot"] as boolean}
                    onClick={() => {
                      runAction("reboot", row[idKey]);
                    }}
                  >
                    Reboot
                  </button>
                  <a
                    href={`ssh://${row[nodeKeys.sshUsername]}@${
                      row[nodeKeys.ip]
                    }:22`}
                    onClick={async (e) => {
                      console.log(
                        row[nodeKeys.sshPassword],
                        row[nodeKeys.ip],
                        row[nodeKeys.sshUsername]
                      );
                      await navigator.clipboard?.writeText(
                        row[nodeKeys.sshPassword] || ""
                      );
                    }}
                    className="btn btn-primary"
                  >
                    SSH
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <PaginationFooter
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        data={data}
      />
    </div>
  );
}
