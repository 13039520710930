import axios, { AxiosInstance } from "axios";

import { RolePermissionModel } from "common";
import { createContext } from "react";

export interface User {
  userName: string;
  role: string;
  token: string;
  intPkUserId: number;
};
export interface AppState {
  isAuthenticated: boolean;
  user: User | null;
  authenticate: (user: User) => void;
  logout: () => void;
  fetcher: AxiosInstance;
  allowedTables: RolePermissionModel[];
}

export const initialAppState: AppState = {
  isAuthenticated: false,
  authenticate: (_) => {},
  user: null,
  logout: () => {},
  fetcher: axios.create(),
  allowedTables: [],
};

export const AppContext = createContext<AppState>(initialAppState);

export const { Provider: StateProvider, Consumer: StateConsumer } = AppContext;
