import EditPage from "../../components/EditPage";
import Input from "../../components/FormElements/ControlledInput";
import { CrudTableModel, RoleAttributes } from "common";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import { useFormContext } from "react-hook-form";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import { Fragment } from "react";
import { CheckBox } from "../../components/FormElements/OneStateInputs";
import useFetcher from "../../libs/hooks/useFetcher";

export default function RolesPage() {
  return (
    <EditPage<RoleAttributes>
      fields={["intPkRoleID", "roleName"]}
      idField="intPkRoleID"
      nameField="roleName"
      title="Roles"
      modelName="role"
      renderForm={({ model, isEditMode, readOnly }) => (
        <RolesForm model={model} isEditMode={isEditMode} readOnly={readOnly} />
      )}
    />
  );
}

function RolesForm({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<RoleAttributes>) {
  const {
    register,
    formState: { errors },
  } = useFormContext<RoleAttributes>();
  const fetcher = useFetcher({});

  const { data: crudTables } = useAuthorizedQuery<CrudTableModel>({
    model: "crudTable",
    enabled: isEditMode,
    filter: {
      // @ts-ignore
      role_permissions: { some: { intPkRoleID: model?.intPkRoleID } },
    },
    limit: 100,
  });

  const updatePermission = async (
    intPkRolePermissionID: number,
    key: string,
    state: boolean
  ) => {
    try {
      await fetcher({
        method: "put",
        url: "api/v1/data/rolePermission/" + intPkRolePermissionID + "/edit",
        data: {
          [key]: Number(state),
        },
      });
    } catch (e) {}
  };

  const createPermission = async (
    intPkTableID: number,
    key: string,
    state: boolean
  ) => {
    try {
      await fetcher({
        method: "post",
        url: "api/v1/data/rolePermission/create",
        data: {
          intPkTableID,
          intPkRoleID: model!.intPkRoleID,
          allowCreate: 0,
          allowUpdate: 0,
          allowRead: 0,
          allowDelete: 0,
          [key]: Number(state),
        },
      });
    } catch (e) {}
  };

  return (
    <Fragment>
      <div className="m-3">
        <Input
          register={register("roleName", { required: "Required" })}
          title="Role Name"
          readOnly={readOnly}
          className="mb-3"
          errorMessage={errors.roleName?.message}
        />
      </div>
      {crudTables && (
        <div className="p-2 table-responsive">
          <table className="table card-table table-vcenter datatable">
            <thead>
              <th>Table</th>
              <th>Create</th>
              <th>Read</th>
              <th>Edit</th>
              <th>Delete</th>
            </thead>
            <tbody>
              {crudTables?.result?.rows.map((crudTable) => (
                <tr>
                  <td>{crudTable.tableName}</td>
                  <td>
                    <CheckBox<boolean>
                      name="allowCreate"
                      checked={!!crudTable?.role_permissions?.[0]?.allowCreate}
                      useCheckedStateAsValue
                      onSelected={(val) => {
                        if (
                          crudTable.role_permissions?.length &&
                          crudTable.role_permissions?.length > 0
                        ) {
                          updatePermission(
                            crudTable.role_permissions?.[0]
                              ?.intPkRolePermissionID,
                            "allowCreate",
                            !!val
                          );
                        } else {
                          createPermission(
                            crudTable.intPkTableID,
                            "allowCreate",
                            !!val
                          );
                        }
                      }}
                    />
                  </td>
                  <td>
                    <CheckBox<boolean>
                      name="allowRead"
                      checked={!!crudTable?.role_permissions?.[0]?.allowRead}
                      useCheckedStateAsValue
                      onSelected={(val) => {
                        if (
                          crudTable.role_permissions?.length &&
                          crudTable.role_permissions?.length > 0
                        ) {
                          updatePermission(
                            crudTable.role_permissions?.[0]
                              ?.intPkRolePermissionID,
                            "allowRead",
                            !!val
                          );
                        } else {
                          createPermission(
                            crudTable.intPkTableID,
                            "allowRead",
                            !!val
                          );
                        }
                      }}
                    />
                  </td>
                  <td>
                    <CheckBox<boolean>
                      name="allowUpdate"
                      checked={!!crudTable?.role_permissions?.[0]?.allowUpdate}
                      useCheckedStateAsValue
                      onSelected={(val) => {
                        if (
                          crudTable.role_permissions?.length &&
                          crudTable.role_permissions?.length > 0
                        ) {
                          updatePermission(
                            crudTable.role_permissions?.[0]
                              ?.intPkRolePermissionID,
                            "allowUpdate",
                            !!val
                          );
                        } else {
                          createPermission(
                            crudTable.intPkTableID,
                            "allowUpdate",
                            !!val
                          );
                        }
                      }}
                    />
                  </td>
                  <td>
                    <CheckBox<boolean>
                      name="allowDelete"
                      checked={!!crudTable?.role_permissions?.[0]?.allowDelete}
                      useCheckedStateAsValue
                      onSelected={(val) => {
                        if (
                          crudTable.role_permissions?.length &&
                          crudTable.role_permissions?.length > 0
                        ) {
                          updatePermission(
                            crudTable.role_permissions?.[0]
                              ?.intPkRolePermissionID,
                            "allowDelete",
                            !!val
                          );
                        } else {
                          createPermission(
                            crudTable.intPkTableID,
                            "allowDelete",
                            !!val
                          );
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </Fragment>
  );
}
