import {
  BrandAttributes,
  BrandModel,
  NodeAttributes,
  PoolModel,
  PoolsStatusModel,
  SplitTunnelProfileAttributes,
  VezgateProxyProfileAttributes,
  VezSockProfileAttributes,
  VezSsListAttributes,
} from "common";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import { Fragment, FunctionComponent } from "react";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import ControlledInput from "../../components/FormElements/ControlledInput";
import EditPage from "../../components/EditPage";
import { JsonConfigAttributes } from "common";
import { PoolProfileAttributes } from "common";
import { ItemId, TreeData, TreeItem } from "@atlaskit/tree";
import { useFormContext } from "react-hook-form";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";

type BrandForm = BrandAttributes & {
  intPkPoolProfileID: number;
  intPkConfigID: number;
  intPkVezgateProxyProfileID: number;
  intPkSplitTunnelID: number;
  intPkVezSockProfileID: number;
  intPkVezSSListID: number;
};

const BrandsPage: FunctionComponent = () => {
  return (
    <EditPage<BrandForm, BrandModel & BrandForm>
      modelName="brand"
      idField="intPkBrandID"
      defaultValues={{ enable: 1 }}
      nameField="brandName"
      buildTreeView={(data): TreeData | undefined => {
        if (!data.result?.rows) return;

        const rootId = "brand";
        const { rows } = data.result;
        const items: Record<ItemId, TreeItem> = {};

        const brandTreeItems = rows.map((brand) => {
          const brandStatuses = brand.brands_statuses;
          const brandItemId = `${rootId}-${brand.intPkBrandID}`;
          const brandStatusesItemId = `${brandItemId}-statuses`;

          const brandStatusTreeItems = brandStatuses?.map((brandStatus) => {
            const jsonConfig = brandStatus.intPkConfig;
            const splitTunnelProfile = brandStatus.intPkSplitTunnel;
            const vezgateProxyProfile = brandStatus.intPkVezgateProxyProfile;
            const poolProfile = brandStatus.intPkPoolProfile;

            const brandStatusItemId = `${brandStatusesItemId}-${brandStatus.intPkBrandsStatusID}`;
            items[brandStatusItemId] = {
              id: brandStatusItemId,
              data: {
                label: `Brand Status - ${brandStatus.intPkBrandsStatusID} ${
                  brandStatus.enable === 1 ? "🟢" : "🔴"
                }`,
              },
              children: [],
            };

            if (jsonConfig) {
              const jsonConfigsItemId = `${brandStatusItemId}-config`;
              const jsonConfigItemId = `${jsonConfigsItemId}-${jsonConfig?.intPkConfigID}`;

              items[brandStatusItemId].children.push(jsonConfigsItemId);

              items[jsonConfigsItemId] = {
                id: jsonConfigsItemId,
                children: [jsonConfigItemId],
                data: { label: "JSON Configs" },
              };

              items[jsonConfigItemId] = {
                id: jsonConfigItemId,
                children: [],
                data: { label: jsonConfig.configName },
              };
            }

            if (splitTunnelProfile) {
              const splitTunnelProfilesItemId = `${brandStatusItemId}-splitTunnel`;
              const splitTunnelProfileItemId = `${splitTunnelProfilesItemId}-${splitTunnelProfile.intPkSplitTunnelID}`;

              items[brandStatusItemId].children.push(splitTunnelProfilesItemId);

              items[splitTunnelProfilesItemId] = {
                id: splitTunnelProfilesItemId,
                children: [splitTunnelProfileItemId],
                data: { label: "Split Tunnel Profiles" },
              };

              items[splitTunnelProfileItemId] = {
                id: splitTunnelProfileItemId,
                children: [],
                data: { label: splitTunnelProfile.configName },
              };
            }

            if (vezgateProxyProfile) {
              const vezgateProxyProfilesItemId = `${brandStatusItemId}-proxy`;
              const vezgateProxyProfileItemId = `${vezgateProxyProfilesItemId}-${vezgateProxyProfile.intPkVezgateProxyProfileID}`;

              items[brandStatusItemId].children.push(
                vezgateProxyProfilesItemId
              );

              items[vezgateProxyProfilesItemId] = {
                id: vezgateProxyProfilesItemId,
                children: [vezgateProxyProfileItemId],
                data: { label: "Vezgate Proxy Profiles" },
              };

              items[vezgateProxyProfileItemId] = {
                id: vezgateProxyProfileItemId,
                children: [],
                data: { label: vezgateProxyProfile.profileName },
              };
            }

            if (poolProfile) {
              const poolProfilesItemId = `${brandStatusItemId}-poolProfiles`;
              const poolProfileItemId = `${poolProfilesItemId}-${poolProfile.intPkPoolProfileID}`;

              items[brandStatusItemId].children.push(poolProfilesItemId);

              const poolStatuses: PoolsStatusModel[] =
                // @ts-ignore
                poolProfile.pools_statuses;
              const poolsItemId = `${poolProfileItemId}-pools`;

              const poolTreeItems = poolStatuses
                .map((poolStatus) => {
                  // @ts-ignore
                  const pool: PoolModel = poolStatus.intPkPool;
                  const poolItemId = `${poolsItemId}-${pool?.intPkPoolID}`;
                  const nodesItemsId = `${poolItemId}-nodes`;

                  if (items[poolItemId]) {
                    return null;
                  }

                  const nodes = pool.pools_m2m_nodes?.map(
                    // @ts-ignore
                    (relation) => relation.intPkNode
                  ) as NodeAttributes[];

                  const nodeTreeItems = nodes.map((node) => {
                    const nodeItemId = `${nodesItemsId}-${node.intPkNodeID}`;

                    items[nodeItemId] = {
                      id: nodeItemId,
                      data: {
                        label: `${node.name} ${
                          node.enable === 1 ? "🟢" : "🔴"
                        }`,
                      },
                      children: [],
                    };

                    return nodeItemId;
                  });

                  items[nodesItemsId] = {
                    id: nodesItemsId,
                    data: { label: "Nodes" },
                    children: nodeTreeItems || [],
                  };

                  items[poolItemId] = {
                    id: poolItemId,
                    data: {
                      label: `${pool?.poolName} ${
                        pool.enable === 1 ? "🟢" : "🔴"
                      }`,
                    },
                    children: [nodesItemsId],
                  };

                  return poolItemId;
                })
                .filter((e) => e !== null) as string[];

              items[poolsItemId] = {
                id: poolsItemId,
                children: poolTreeItems || [],
                data: { label: "Pools" },
              };

              items[poolProfilesItemId] = {
                id: poolProfilesItemId,
                children: [poolProfileItemId],
                data: { label: "Pool Profiles" },
              };

              items[poolProfileItemId] = {
                id: poolProfileItemId,
                children: [poolsItemId],
                data: {
                  label: `${poolProfile.poolProfileName} ${
                    poolProfile.enable === 1 ? "🟢" : "🔴"
                  }`,
                },
              };
            }

            return brandStatusItemId;
          });

          items[brandStatusesItemId] = {
            id: brandStatusesItemId,
            children: brandStatusTreeItems || [],
            data: { label: "Brand Statuses" },
          };

          items[brandItemId] = {
            id: brandItemId,
            data: {
              label: `${brand.brandName} ${brand.enable === 1 ? "🟢" : "🔴"}`,
            },
            children: [brandStatusesItemId],
          };

          return brandItemId;
        });

        items[rootId] = {
          id: rootId,
          data: { label: "Brands" },
          children: brandTreeItems || [],
        };

        const treeData: TreeData = {
          rootId: rootId,
          items,
        };
        console.log(treeData);

        return treeData;
      }}
      fields={[
        "intPkBrandID",
        { key: "iconUrl", type: "image" },
        "brandName",
        { key: "enable", type: "boolean" },
      ]}
      renderForm={({ model, isEditMode, readOnly }) => {
        return (
          <BrandsForm
            readOnly={readOnly}
            model={model}
            isEditMode={isEditMode}
          />
        );
      }}
    />
  );
};

export const BrandsForm = ({
  readOnly,
  model,
  isEditMode,
}: CRUDFormProps<BrandForm>) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<BrandForm>();

  return (
    <div className="m-3">
      <ControlledInput
        register={register("brandName", { required: "Required" })}
        errorMessage={errors.brandName?.message}
        title="Brand Name"
        readOnly={readOnly}
      />

      {!isEditMode && (
        <Fragment>
          <div className="form-group mt-3 flex-fill ">
            <label className="form-label">Generic Pool Profile</label>
            <AsyncSelectWithController<BrandForm, PoolProfileAttributes>
              control={control}
              valueFieldName="intPkPoolProfileID"
              searchField="poolProfileName"
              readOnly={isEditMode || readOnly}
              errorMessage={errors.intPkPoolProfileID?.message}
              restricted={(e) => e.enable === 0}
              rules={{ required: "Required" }}
              model="poolProfile"
            />
          </div>
          <div className="form-group mt-3 flex-fill">
            <label className="form-label">Generic Config</label>
            <AsyncSelectWithController<BrandForm, JsonConfigAttributes>
              control={control}
              valueFieldName="intPkConfigID"
              searchField="configName"
              readOnly={isEditMode || readOnly}
              errorMessage={errors.intPkConfigID?.message}
              rules={{ required: "Required" }}
              model="jsonConfig"
            />
          </div>
          <div className="form-group mt-3 flex-fill">
            <label className="form-label">Generic Split Tunnel Policy</label>
            <AsyncSelectWithController<BrandForm, SplitTunnelProfileAttributes>
              control={control}
              valueFieldName="intPkSplitTunnelID"
              searchField="configName"
              readOnly={isEditMode || readOnly}
              errorMessage={errors.intPkSplitTunnelID?.message}
              rules={{ required: "Required" }}
              model="splitTunnelProfile"
            />
          </div>
          <div className="form-group my-3 flex-fill">
            <label className="form-label">Generic Vezgate Proxy Profile </label>
            <AsyncSelectWithController<BrandForm, VezgateProxyProfileAttributes>
              control={control}
              valueFieldName="intPkVezgateProxyProfileID"
              searchField="profileName"
              readOnly={isEditMode || readOnly}
              errorMessage={errors.intPkVezgateProxyProfileID?.message}
              rules={{ required: "Required" }}
              model="vezgateProxyProfile"
            />
          </div>
          <div className="form-group my-3 flex-fill">
            <label className="form-label">Generic VezSock Profile</label>
            <AsyncSelectWithController<BrandForm, VezSockProfileAttributes>
              control={control}
              valueFieldName="intPkVezSockProfileID"
              searchField="profileName"
              readOnly={isEditMode || readOnly}
              errorMessage={errors.intPkVezSockProfileID?.message}
              rules={{ required: "Required" }}
              model="vezSockProfile"
            />
          </div>
          <div className="form-group my-3 flex-fill">
            <label className="form-label">Generic VezSs List</label>
            <AsyncSelectWithController<BrandForm, VezSsListAttributes>
              control={control}
              valueFieldName="intPkVezSSListID"
              searchField="listName"
              readOnly={isEditMode || readOnly}
              errorMessage={errors.intPkVezSSListID?.message}
              rules={{ required: "Required" }}
              model="vezSsList"
            />
          </div>
        </Fragment>
      )}
      <ControlledInput
        register={register("iconUrl", { required: "Required" })}
        errorMessage={errors.iconUrl?.message}
        title="Icon URL"
        className="my-3"
        readOnly={readOnly}
      />
      <CheckboxTileGroup>
        <CheckboxTile
          control={control}
          name="enable"
          title="Enable"
          readOnly={readOnly}
        />
      </CheckboxTileGroup>
    </div>
  );
};

export default BrandsPage;
