import { UserPreferenceAttributes } from "common";
import { useState } from "react";
import { FieldRecord } from "../../interfaces/DataTypes";
import useAuthorizedQuery from "./useAuthorizedQuery";
import useFetcher from "./useFetcher";

export default function useUserPreferencesQuery(preferenceName: string) {
  const [processedData, setProcessedData] = useState<
    UserPreferenceAttributes
  >();

  const fetcher = useFetcher({});

  const savePreference = async (preferenceValue: FieldRecord<any, boolean>) => {
    await fetcher({
      url: `api/v1/data/userPreference/createOrEdit`,
      method: "post",
      successMessage: "Saved Preference",
      data: {
        preferenceName,
        preferenceValue: JSON.stringify(preferenceValue),
      },
    });
  };

  const queryMethods = useAuthorizedQuery<UserPreferenceAttributes>({
    model: "userPreference",
    filter: {
      preferenceName,
    },
    queryOptions: {
      onSuccess: (data) => {
        const newData = data.result?.rows.map((e) => {
          return {
            ...e,
            preferenceValue: JSON.parse(e.preferenceValue),
          };
        });

        if (newData) setProcessedData(newData[0]);
      },
    },
  });

  return { ...queryMethods, data: processedData, savePreference };
}
