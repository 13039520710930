import { ItemId, TreeData, TreeItem } from "@atlaskit/tree";
import { NodeStatusMap } from "../../constants";
import Icon from "@mdi/react";
import {
  BrandsStatusModel,
  DataCenterModel,
  VendorAttributes,
  VezSsListM2MVezSsNodeModel,
  VezSsListModel,
  VezSsNodeAttributes,
  VezSsNodeModel,
  VezSsNodesDeletedAttributes,
} from "common";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { useFormContext } from "react-hook-form";
import Moment from "react-moment";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import Input from "../../components/FormElements/ControlledInput";
import { CheckBox } from "../../components/FormElements/OneStateInputs";
import { RadioSelectWithController } from "../../components/FormElements/RadioSelect";
import CustomModal from "../../components/Modals/Modal";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { useIsNoc } from "../../libs/hooks/useIsNoc";

export const VezSSNodePage: React.FunctionComponent = () => {
  const [filter, setFilter] = useState();
  const [lastUpdateTime, setLastUpdateTime] = useState<Date>();
  const isNoc = useIsNoc();

  return (
    <EditPage<VezSsNodeAttributes, VezSsNodeModel & { nodeStat: any }>
      modelName="vezSsNode"
      nameField="vezSSNodeName"
      idField="intPkVezSSNodeID"
      title="VezSS Nodes"
      filter={filter}
      defaultValues={{
        enable: 1,
      }}
      buildTreeView={(data) => {
        if (!data?.result?.rows) return;

        const nodeInfos = data.result.rows.reduce((record, node) => {
          const rootId = node["intPkVezSSNodeID"];
          const poolsRootId = `${rootId}-pools`;

          const poolTreeItemIds = node.vez_ss_list_m2m_vez_ss_nodes?.map(
            (poolRelation: VezSsListM2MVezSsNodeModel) => {
              // @ts-ignore
              const pool: VezSsListModel = poolRelation.intPkVezSSList;
              const poolItemId = `${poolsRootId}-${poolRelation.intPkVezSSListID}`;
              const brandRootItemId = `${poolItemId}-brands`;

              // @ts-ignore
              const brandTreeItems = pool.brands_statuses
                ?.map((brandStatus: BrandsStatusModel) => {
                  // @ts-ignore
                  const brandItemId = `${brandRootItemId}-${brandStatus.intPkBrandID}`;

                  if (record[brandItemId]) return null;

                  record[brandItemId] = {
                    id: brandItemId,
                    // @ts-ignore
                    data: {
                      label:
                        brandStatus?.intPkBrand?.brandName +
                        (brandStatus["enable"] === 1 ? " 🟢" : " 🔴"),
                    },
                    children: [],
                  };
                  return brandItemId;
                })
                .filter((e: any) => e !== null) as string[];

              record[brandRootItemId] = {
                id: brandRootItemId,
                data: { label: "Brands" },
                children: brandTreeItems || [],
                hasChildren: false,
                isExpanded: false,
              };

              record[poolItemId] = {
                id: poolItemId,
                data: {
                  label: pool.listName + (pool["enable"] === 1 ? " 🟢" : " 🔴"),
                },
                children: [brandRootItemId],
              };

              return poolItemId;
            }
          );

          record[poolsRootId] = {
            id: poolsRootId,
            data: { label: "Lists" },
            children: poolTreeItemIds || [],
          };

          record[rootId] = {
            id: rootId,
            data: {
              label:
                node["vezSSNodeName"] + (node["enable"] === 1 ? " 🟢" : " 🔴"),
            },
            children: [poolsRootId],
          };

          return record;
        }, {} as Record<ItemId, TreeItem>);

        const treeData: TreeData = {
          rootId: "node",
          items: {
            ...nodeInfos,
            node: {
              id: "node",
              data: { label: "Node" },
              children: data.result.rows.map((e) => e["intPkVezSSNodeID"]),
              hasChildren: true,
              isExpanded: false,
              isChildrenLoading: false,
            },
          },
        };

        return treeData;
      }}
      onNewData={(data) => {
        const rows = data.result?.rows;
        const lastUpdateTime = rows?.reduce((prev, acc) => {
          const cur = new Date(acc.nodeStat?.createdAt);
          if (prev < cur) {
            prev = cur;
          }

          return prev;
        }, new Date(0));

        if (lastUpdateTime && rows) {
          for (let i = 0; i < (rows?.length || 0); i++) {
            if (
              !rows[i].nodeStat?.createdAt ||
              Math.abs(
                new Date(rows[i].nodeStat.createdAt).getTime() -
                  lastUpdateTime.getTime()
              ) >
                5 * 60 * 1000 /* 3min */
            ) {
              if (!rows[i]?.nodeStat) continue;
            }
          }
        }

        setLastUpdateTime(lastUpdateTime);
      }}
      renderCardHeader={() => {
        return (
          <Fragment>
            {lastUpdateTime && (
              <div className="d-flex align-items-center">
                Status was last checked at&nbsp;
                <b>
                  <Moment fromNow withTitle>
                    {lastUpdateTime}
                  </Moment>
                </b>
              </div>
            )}
            <FilterPicker onFilter={setFilter} filter={filter}>
              <VezSsNodeFilter />
            </FilterPicker>
          </Fragment>
        );
      }}
      recordStyles={(z) => {
        if (!lastUpdateTime) return;
        if (
          !z.nodeStat?.createdAt ||
          Math.abs(
            new Date(z.nodeStat.createdAt).getTime() - lastUpdateTime.getTime()
          ) >
            3 * 60 * 1000 /* 3min */
        ) {
          return {
            backgroundColor: "rgba(241,194,50, .15)",
          };
        }
      }}
      fields={[
        "vezSSNodeName",
        "nodeIP",
        { name: "cpu", value: (z) => <>{z.nodeStat?.cpu?.toFixed(1)}%</> },
        { name: "mem", value: (z) => <>{z.nodeStat?.mem?.toFixed(1)}%</> },
        {
          name: "rx",
          value: (z) => <>{((z.nodeStat?.rx || 0) / 1024 / 1024).toFixed(2)}</>,
        },
        {
          name: "tx",
          value: (z) => <>{((z.nodeStat?.tx || 0) / 1024 / 1024).toFixed(2)}</>,
        },
        "nodeStat.connected_users",
        {
          name: "service_status",
          value: (z) => {
            const status =
              NodeStatusMap[z.nodeStat?.service_status] || NodeStatusMap.error;

            return (
              <Fragment>
                <Icon path={status?.icon} color={status?.color} size={0.9} />
                <span
                  title={z.nodeStat?.service_status}
                  className="ms-1"
                  style={{
                    color: status?.color,
                    borderBottom: z.nodeStat?.service_status && "1px dashed",
                  }}
                >
                  {status?.text}
                </span>
              </Fragment>
            );
          },
        },
        {
          type: "image",
          key: "intPkDataCenter.intPkCountry.flagImageUrl",
          name: "country",
        },
        { type: "switch", key: "enable" },
        { type: "date", key: "nodeStat.createdAt" },
        {
          value: (z) => (!isNoc ? <VezSSOperations model={z} /> : undefined),
          name: "",
        },
      ]}
      isRecordEditable={(z) => !isNoc}
      renderForm={({ isEditMode, model, readOnly }) => (
        <VezSSNodeForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

// const ShadowSocksNodeHideUI = ({ model }: { model: VezSsNodeModel }) => {
//   const fetcher = useFetcher({});
//   const [busy, setBusy] = useState(false);

//   const hide = async () => {
//     setBusy(true);

//     try {
//       await fetcher({
//         url: `/api/v1/data/vezSsNode/${model.intPkVezSSNodeID}/edit`,
//         method: "put",
//         data: { hidden: Number(!model.hidden) },
//       });
//     } catch (e) {}

//     setBusy(false);
//   };

//   return (
//     <button className="btn btn-red" onClick={hide} disabled={busy}>
//       {model.hidden ? "Un-Hide" : "Hide"}
//     </button>
//   );
// };

export const VezSSOperations = ({ model }: { model: VezSsNodeModel }) => {
  const [modelShown, setModelShown] = useState(false);
  const closeModel = () => setModelShown(false);
  const fetcher = useFetcher({});

  const { data: prevRecords } = useAuthorizedQuery<any>({
    filter: {
      actionName: "ShadowSocks_InstallServer",
      id: model.intPkVezSSNodeID,
    },
    model: "actionStatus",
    enabled: modelShown,
  });

  const [selectedRecord, setSelectedRecord] = useState<any>();

  const isConfiguredBefore = useMemo(
    () => prevRecords?.result?.count && prevRecords?.result?.count > 0,
    [prevRecords]
  );

  const isInstallingServerBusy = useMemo(
    () => !!prevRecords?.result?.rows.find((e) => e.status === "RUNNING"),
    [prevRecords]
  );

  const [isActionBusy, setActionAsBusy] = useState<Record<string, boolean>>({});

  const runAction = async (action: string) => {
    setActionAsBusy((prev) => ({ ...prev, [action]: true }));

    try {
      await fetcher({
        url: "/api/v1/data/vezSsNode/runAction/" + model.intPkVezSSNodeID,
        method: "post",
        data: {
          action: action,
        },
      });
    } catch (e) {}

    setActionAsBusy((prev) => ({ ...prev, [action]: false }));
  };

  const alert = useAlert();

  return (
    <Fragment>
      <CustomModal
        show={modelShown}
        title={`${model.vezSSNodeName} / ${model.nodeIP}`}
        onHide={closeModel}
        onPrimaryButtonClick={closeModel}
        primaryButtonText="Close"
        showCancelButton={false}
      >
        <div className="m-3">
          <div className="d-flex flex-row justify-content-between w-100">
            <div>
              <h3>Actions</h3>
              <div className="btn-group mb-3">
                <button
                  className={`btn btn-${
                    isConfiguredBefore ? "yellow" : "green"
                  }`}
                  onClick={(_) => runAction("installServer")}
                  disabled={isInstallingServerBusy}
                >
                  {isConfiguredBefore ? "Re-Install SS" : "Install SS"}
                </button>

                <button
                  className={"btn btn-info"}
                  onClick={(_) => runAction("restartServer")}
                  disabled={isActionBusy["restartServer"]}
                >
                  Start/Restart Server
                </button>

                <button
                  className="btn btn-yellow"
                  onClick={(_) => runAction("stopServer")}
                  disabled={isActionBusy["stopServer"]}
                >
                  Stop SS
                </button>

                <button
                  className="btn btn-danger"
                  onClick={(_) => runAction("reboot")}
                  disabled={isInstallingServerBusy}
                >
                  Reboot
                </button>
                <a
                  href={`ssh://${model.sshUserName}@${model.nodeIP}:22`}
                  onClick={async (e) => {
                    await navigator.clipboard.writeText(model.sshPassword);
                    alert.info("SSH Password copied to clipboard");
                  }}
                  className="btn btn-primary"
                >
                  SSH
                </a>
              </div>
            </div>
          </div>
          <h3>Install Logs</h3>
        </div>
        <table className="table card-table">
          <thead>
            <tr>
              <th>Action</th>
              <th>Status</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {prevRecords?.result?.rows.map((row) => (
              <tr>
                <td>{row.actionName}</td>
                <td
                  className={
                    row.status === "FAILED"
                      ? "text-danger"
                      : row.status === "RUNNING"
                      ? "text-yellow"
                      : "text-success"
                  }
                >
                  {row.status}
                </td>
                <td>{moment(row.startedAt).format("HH:mm - DD/MM/YYYY")}</td>
                <td>
                  {row.endedAt
                    ? moment(row.endedAt).format("HH:mm - DD/MM/YYYY")
                    : "-"}
                </td>
                <td>
                  <button
                    className="btn"
                    onClick={(_) => setSelectedRecord(row)}
                  >
                    Logs
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <CustomModal
          show={!!selectedRecord}
          showCancelButton={false}
          onHide={() => setSelectedRecord(undefined)}
          primaryButtonText="Close"
          title="Logs"
          onPrimaryButtonClick={() => setSelectedRecord(undefined)}
        >
          <div className="m-3">
            <h3>STDOUT</h3>
            <pre style={{ maxHeight: "40vh" }}>{selectedRecord?.stdout}</pre>
            <h3>STDERROR</h3>
            <pre style={{ maxHeight: "40vh" }} className="text-danger">
              {selectedRecord?.stderr}
            </pre>
          </div>
        </CustomModal>
      </CustomModal>
      <button className="btn" onClick={(_) => setModelShown(true)}>
        Open Control UI
      </button>
    </Fragment>
  );
};

export const VezSSNodeForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<VezSsNodeAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<VezSsNodeAttributes>();
  const nodeIP = watch("nodeIP");

  const [useRandomSSValues, setToUseRandomSSValues] = useState(!isEditMode);
  const { data } = useAuthorizedQuery<VezSsNodesDeletedAttributes>({
    model: "vezSsNodeDeleted",
    filter: { nodeIP },
    enabled: !!nodeIP,
  });

  return (
    <div className="m-3">
      <Input
        register={register("vezSSNodeName", {
          required: "Required",
        })}
        readOnly={readOnly}
        className="mb-3"
        title="Name"
        errorMessage={errors.vezSSNodeName?.message}
      />
      <div className="flex-fill row gap-1">
        <div className="col">
          <Input
            register={register("nodeIP", {
              required: "Required",
              setValueAs: (value) => value.trim(),
            })}
            readOnly={readOnly}
            className="mb-3"
            title="IP"
            errorMessage={errors.nodeIP?.message}
          />
        </div>
        <div className="col">
          <Input
            register={register("sshUserName", {
              required: "Required",
              setValueAs: (value) => value.trim(),
            })}
            readOnly={readOnly}
            className="mb-3"
            title="sshUserName"
            errorMessage={errors.sshUserName?.message}
          />
        </div>
        <div className="col">
          <Input
            register={register("sshPassword", {
              required: "Required",
              setValueAs: (value) => value.trim(),
            })}
            readOnly={readOnly}
            className="mb-3"
            title="sshPassword"
            errorMessage={errors.sshPassword?.message}
          />
        </div>
      </div>
      {(data?.result?.rows?.length || -1) > 0 && (
        <Fragment>
          <p className="text-warning">
            This IP address was used previously and was deleted, following is
            the information about it.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>IP</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {data?.result?.rows.map((node) => {
                return (
                  <tr>
                    <td>{node.vezSSNodeName}</td>
                    <td>{node.nodeIP}</td>
                    <td>{node.comment}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Fragment>
      )}

      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">DataCenter</label>
        <AsyncSelectWithController<VezSsNodeAttributes, DataCenterModel>
          control={control}
          valueFieldName="intPkDataCenterID"
          searchField="dataCenterName"
          rules={{ required: true }}
          readOnly={readOnly}
          imageField="intPkCountry.flagImageUrl"
          model="dataCenter"
        />
      </div>
      <div className="card mb-3 p-3">
        {!isEditMode && (
          <div className="mb-1">
            <CheckBox<boolean>
              checked={useRandomSSValues}
              onSelected={(val) => {
                setToUseRandomSSValues(!!val);
              }}
              name="doesntmatter"
              useCheckedStateAsValue
            />
            <span className="ms-2">
              Use random values for Port and Password
            </span>
          </div>
        )}
        <div className="row gap-1">
          <div className="col">
            <Input
              register={register("ssPort", {
                required: useRandomSSValues ? undefined : "Required",
                valueAsNumber: true,
              })}
              readOnly={readOnly}
              className="mb-3"
              title="ssPort"
              type="number"
              disabled={useRandomSSValues}
              errorMessage={errors.ssPort?.message}
            />
          </div>
          <div className="col">
            <Input
              register={register("ssPassword", {
                required: useRandomSSValues ? undefined : "Required",
                setValueAs: (value) => value.trim(),
              })}
              readOnly={readOnly}
              className="mb-3"
              disabled={useRandomSSValues}
              title="ssPassword"
              errorMessage={errors.ssPassword?.message}
            />
          </div>
        </div>
      </div>
      <CheckboxTileGroup>
        <CheckboxTile control={control} name="enable" title="Enable" />
        <CheckboxTile
          control={control}
          name="botControlled"
          title="Controlled by Bot"
        />
      </CheckboxTileGroup>
    </div>
  );
};

export const VezSsNodeFilter = () => {
  const methods = useFormContext();
  const [mainValue, intPkVendorID, intPkCountryID] = methods.watch([
    "or._0.vezSSNodeName",
    "vendorFilter.intPkVendorID",
    "countryFilter.intPkCountryID",
  ]);

  useEffect(() => {
    if (mainValue === "") {
      methods.setValue("or._1.nodeIP", "");
    }
  }, [mainValue]);

  return (
    <div className="m-3">
      <Input
        register={methods.register("or._0.vezSSNodeName")}
        title="Search by IP, Name, Domain"
        className="mb-3"
      />
      <Input
        register={methods.register("or._1.nodeIP", { value: mainValue })}
        title=""
        type="hidden"
        readOnly
      />
      <div className="d-flex flex-row">
        <Input
          register={methods.register("nodeStat.cpu.gt", {
            valueAsNumber: true,
          })}
          title="CPU >"
          type="number"
          className="mb-3 me-3"
        />
        <Input
          register={methods.register("nodeStat.cpu.lt", {
            valueAsNumber: true,
          })}
          title="CPU <"
          type="number"
          className="mb-3"
        />
      </div>
      <div className="d-flex flex-row">
        <Input
          register={methods.register("nodeStat.mem.gt", {
            valueAsNumber: true,
          })}
          title="MEM >"
          type="number"
          className="mb-3 me-3"
        />
        <Input
          register={methods.register("nodeStat.mem.lt", {
            valueAsNumber: true,
          })}
          title="MEM <"
          type="number"
          className="mb-3"
        />
      </div>
      <div className="d-flex flex-row">
        <Input
          register={methods.register("nodeStat.connected_users.gt", {
            valueAsNumber: true,
          })}
          title="CONNECTED USERS >"
          type="number"
          className="mb-3 me-3"
        />
        <Input
          register={methods.register("nodeStat.connected_users.lt", {
            valueAsNumber: true,
          })}
          title="CONNECTED USERS <"
          type="number"
          className="mb-3"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Vendor</label>
        <AsyncSelectWithController<any, VendorAttributes>
          searchField="vendorName"
          model="vendor"
          control={methods.control}
          valueFieldName="vendorFilter.intPkVendorID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Country</label>
        <AsyncSelectWithController<any, any>
          searchField="countryName"
          model="country"
          imageField="flagImageUrl"
          filter={{ datacenters: { some: { intPkVendorID } } }}
          control={methods.control}
          valueFieldName="countryFilter.intPkCountryID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Data Center</label>
        <AsyncSelectWithController<any, DataCenterModel>
          searchField="dataCenterName"
          model="dataCenter"
          imageField="intPkCountry.flagImageUrl"
          filter={{
            // @ts-ignore
            intPkVendorID: { equals: intPkVendorID },
            // @ts-ignore
            intPkCountryID: { equals: intPkCountryID },
          }}
          control={methods.control}
          valueFieldName="intPkDataCenterID"
        />
      </div>
      <RadioSelectWithController
        control={methods.control}
        name="nodeStat.service_status"
        title="Service Status"
        options={[
          { label: "All", value: undefined },
          ...Object.entries(NodeStatusMap).map(([key, value]) => {
            return { label: value.text, value: key };
          }),
        ]}
      />
      <RadioSelectWithController
        control={methods.control}
        name="enable"
        title="Enable Status"
        options={[
          { label: "All", value: undefined },
          { label: "Enabled", value: 1 },
          { label: "Disabled", value: 0 },
        ]}
      />
    </div>
  );
};
