import {
  Control,
  FieldPath,
  RegisterOptions,
  useController,
} from "react-hook-form";
import React, { useCallback, useEffect, useState } from "react";

import { ModelName } from "../../constants";
import Select from "react-select/creatable";
import { resolveObjectFromPath } from "../../libs/util/resolveObjectFromPath";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import { CSSObject } from "@emotion/css";

interface AsyncSelectWithControllerProps<
  T extends Record<string, any>,
  K extends Record<string, any>
> {
  value?: number;
  control: Control<T>;
  model: ModelName;
  nullFieldName?: string;
  filter?: Record<string, any> & Partial<K>;
  searchField: FieldPath<K>;
  valueFieldName: FieldPath<T | K>;
  imageField?: FieldPath<K>;
  errorMessage?: string;
  restricted?: (model: K) => boolean;
  readOnly?: boolean;
  onLoadingStateChange?: (loading: boolean) => void;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
}

export default function AsyncSelectWithController<
  T extends Record<string, any>,
  K extends Record<string, any>
>({
  control,
  searchField,
  model,
  rules,
  valueFieldName: fieldName,
  errorMessage,
  restricted,
  imageField,
  filter = {},
  nullFieldName = "None",
  readOnly = false,
  onLoadingStateChange,
}: React.PropsWithChildren<AsyncSelectWithControllerProps<T, K>>) {
  // current state of select
  let fieldNameForK = fieldName as FieldPath<K>;
  const [dataList, setDataList] = useState<K[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [limit, setLimit] = useState(10);

  const getPrecedingImageStyle = (base: CSSObject, data: K): CSSObject => {
    if (imageField) {
      const imageData = resolveObjectFromPath(data, imageField);
      if (data && imageData) {
        base = {
          ...base,
          ...{
            display: "flex",
            alignItems: "center",
            gap: "10px",

            ":before": {
              content: `" "`,
              backgroundImage: `url(${imageData})`,
              backgroundSize: "cover",
              height: "30px",
              minWidth: "30px",
              display: "inline-block",
            },
          },
        };
      }
    }

    return base;
  };

  if (fieldNameForK.includes(".")) {
    const parts = fieldNameForK.split(".");
    fieldNameForK = parts[parts.length - 1] as FieldPath<K>;
  }

  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid },
  } = useController({
    name: fieldName as FieldPath<T>,
    rules,
    control,
  });

  const {
    isLoading: isAllFiledInfoLoading,
    isFetching: isAllFiledInfoFetching,
    data: allFieldInfo,
  } = useAuthorizedQuery<K>({
    model,
    limit,
    filter: {
      ...filter,
      [searchField]: searchQuery,
    },
    enabled: !readOnly,
    queryOptions: {
      keepPreviousData: true,
    },
  });

  const {
    isLoading: isCurrentFieldInfoLoading,
    isFetching: isCurrentFieldInfoFetching,
    data: currentFieldInfo,
    // queryKey: currentFieldQueryKey,
  } = useAuthorizedQuery<K>({
    model,
    filter: {
      [fieldNameForK]: value && { equals: value },
      ...filter,
    } as K,
    enabled: !!value,
  });

  useEffect(() => {
    const newDataList = [];

    if (value && currentFieldInfo?.result?.rows) {
      newDataList.push(...currentFieldInfo.result.rows);
    }

    if (allFieldInfo?.result?.rows) {
      const rows = allFieldInfo.result.rows.filter(
        (e) => e[fieldNameForK] !== value
      );
      newDataList.push(...rows);
    }

    const nullEntry: Record<string, any> = {};
    nullEntry[fieldNameForK] = null;
    nullEntry[searchField] = nullFieldName;

    newDataList.unshift(nullEntry as K);

    setDataList(newDataList);
  }, [currentFieldInfo, allFieldInfo]);

  useEffect(() => {
    if (
      currentFieldInfo?.result?.rows &&
      currentFieldInfo?.result?.rows.length < 1
    ) {
      console.log(
        "[React-Select] Setting null, because filter doesnt contain currentValue"
      );
      onChange(null);
    }
  }, [currentFieldInfo]);

  useEffect(() => {
    if (onLoadingStateChange) {
      onLoadingStateChange(
        isCurrentFieldInfoFetching || isAllFiledInfoFetching
      );
    }
  }, [isCurrentFieldInfoFetching, isAllFiledInfoFetching]);

  const handleInputChangeOnSelect = useCallback((filter?: string) => {
    setSearchQuery(filter === "" ? undefined : filter);
  }, []);

  const onMenuScrollToBottom = useCallback(() => {
    if (allFieldInfo?.result) {
      const increment = Math.min(10, allFieldInfo.result.count - limit);
      if (increment > 0) {
        setLimit((e) => e + increment);
      }
    }
  }, [allFieldInfo, limit, setLimit]);

  let valueObj = !value
    ? null
    : dataList?.find((e) => e[fieldNameForK] === value) || null;

  return (
    <div className="d-flex flex-column">
      <Select<K>
        options={dataList}
        value={valueObj}
        isDisabled={readOnly}
        onInputChange={handleInputChangeOnSelect}
        loadingMessage={({ inputValue }) => `Finding... ${inputValue}`}
        onChange={(e) => {
          onChange(e ? e[fieldNameForK] : e === null ? null : undefined);
        }}
        isLoading={isAllFiledInfoLoading || isCurrentFieldInfoLoading}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onCreateOption={(val) => {
          return val;
        }}
        styles={{
          container: (base, _) => {
            base.flex = 1;
            return base;
          },
          control: (base, _) => {
            base.borderColor = invalid ? "#d63939" : "#dadcde";
            return base;
          },
          option: (base, props) => {
            if (props.isDisabled) {
              base.color = "#cc0000";
            }

            let data = props.options.find(
              (e) => props.data[fieldNameForK] === e[fieldNameForK]
            );
            if (data) return getPrecedingImageStyle(base, data);

            return base;
          },
          singleValue: (base, props) => {
            let data = props.options.find(
              (e) => props.data[fieldNameForK] === e[fieldNameForK]
            );
            if (data) {
              return getPrecedingImageStyle(base, data);
            }
            return base;
          },
        }}
        isOptionDisabled={(e) => (restricted ? restricted(e) : false)}
        getOptionValue={(options) => `${options[fieldNameForK]}`}
        getOptionLabel={(options) =>
          options[searchField] +
          (restricted && restricted(options) ? " (disabled)" : "")
        }
        onBlur={onBlur}
      />
      {errorMessage && (
        <div className="mt-1 d-block invalid-feedback">{errorMessage}</div>
      )}
    </div>
  );
}
