import "./Modal.scss";

import React, { FunctionComponent } from "react";

import { Modal } from "react-overlays";

export interface ModalProps {
  onHide: () => void;
  onPrimaryButtonClick: () => void;
  show: boolean;
  title: string;
  loading?: boolean;
  primaryButtonText: string;
  primaryButtonDisabled?: boolean;
  size?: "sm" | "lg" | "xl";
  theme?: "primary" | "danger";
  showCancelButton?: boolean;
}

interface ModalContextProps {
  closed: boolean;
}

const initialState: ModalContextProps = {
  closed: true,
};

export const ModalContext = React.createContext(initialState);

const CustomModal: FunctionComponent<ModalProps> = ({
  children,
  onHide,
  onPrimaryButtonClick,
  loading = false,
  title,
  size = "lg",
  primaryButtonText,
  theme = "primary",
  primaryButtonDisabled = false,
  show,
  showCancelButton = true,
}) => {
  return (
    <ModalContext.Provider value={{ closed: !show }}>
      <Modal
        autoFocus
        onHide={onHide}
        show={show}
        className="custom-modal"
        renderBackdrop={() => <div className="custom-modal-backdrop"></div>}
      >
        <div className={`modal-${size} w-100`}>
          <div className="modal-content my-3">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={(_) => onHide()}
              ></button>
            </div>
            <div className="modal-body p-0">{children}</div>

            <div className="modal-footer pt-3 border-top">
              {showCancelButton && (
                <button
                  onClick={(_) => onHide()}
                  type="button"
                  className={`btn btn-ghost-${theme}`}
                >
                  Cancel
                </button>
              )}
              <button
                className={`btn btn-${theme} ms-auto ${loading ? "btn-loading" : ""}`}
                onClick={(_) => onPrimaryButtonClick()}
                disabled={primaryButtonDisabled}
              >
                {primaryButtonText}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </ModalContext.Provider>
  );
};

export default CustomModal;
