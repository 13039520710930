import { VezSockNodeModel, VezSockListAttributes } from "common";
import { useState } from "react";
import { useCallback } from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import DraggablePicker from "../../components/DraggablePicker";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import Input from "../../components/FormElements/ControlledInput";
import { RadioSelectWithController } from "../../components/FormElements/RadioSelect";
import ListTile from "../../components/ListTile";
import CreateEditModal from "../../components/Modals/CreateEditModal";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { VezSockNodeForm } from "./VezSockNode";

export const VezSockListPage: React.FunctionComponent = () => {
  return (
    <EditPage<VezSockListAttributes, VezSockListAttributes>
      modelName="vezSockList"
      nameField="sockListName"
      idField="intPkVezSockListID"
      title="VezSock List"
      createEditModalSize="xl"
      defaultValues={{
        enable: 1,
      }}
      fields={["intPkVezSockListID", "sockListName"]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <VezSockListForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const VezSockListForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<VezSockListAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<VezSockListAttributes>();

  const [busy, setBusy] = useState(false);
  const [attachedVezSockNodeFilter, setAttachedVezSockNodeFilter] = useState(
    {}
  );
  const [vezSockFilter, setVezSockNodeFilter] = useState({});
  const [selectedVezSockNode, setSelectedVezSockNode] =
    useState<VezSockNodeModel>();

  const { data: attachedVezSockNodes, refetch: refetchAttachedSockNodes } =
    useAuthorizedQuery<VezSockNodeModel>({
      model: "vezSockNode",
      limit: 24,
      filter: {
        ...attachedVezSockNodeFilter,
        // @ts-ignore
        vezSockListFilter: {
          intPkVezSockListID: model?.intPkVezSockListID,
        },
      },
      enabled: isEditMode,
    });
  const { data: vezSockNodes, refetch: refetchVezSockNodes } =
    useAuthorizedQuery<VezSockNodeModel>({
      model: "vezSockNode",
      url: `/api/v1/data/vezSockNode/notAttached/${model?.intPkVezSockListID}`,
      filter: {
        ...vezSockFilter,
      },
      enabled: isEditMode,
    });

  const fetcher = useFetcher({});

  const renderVezSockNodeTile = (vezSockNode: VezSockNodeModel) => {
    return (
      <ListTile
        primary={vezSockNode.vezSockNodeName}
        secondary={`${vezSockNode.vezSockIP}:${vezSockNode.vezSockPort} / ${vezSockNode.vezSockDomain}:${vezSockNode.vezSockPort}`}
        image={
          <img
            src={vezSockNode.intPkDataCenter?.intPkCountry?.flagImageUrl}
            alt="countryFlag"
          />
        }
        onDblClick={() => setSelectedVezSockNode(vezSockNode)}
      >
        <div className="col-2 align-items-center d-flex justify-content-end">
          <span
            className={`badge align-left ${
              !!vezSockNode.enable ? "bg-success" : "bg-danger"
            }`}
          ></span>
        </div>
      </ListTile>
    );
  };

  const addRelation = async (vezSockNode: VezSockNodeModel) => {
    setBusy(true);
    try {
      await fetcher({
        method: "post",
        url: "/api/v1/data/vezSockListM2MVezSockNode/create",
        data: {
          intPkVezSockListID: model?.intPkVezSockListID,
          intPkVezSockNodeID: vezSockNode.intPkVezSockNodeID,
        },
        successMessage: "Successfully attached to VezSockList",
      });
      refetchAttachedSockNodes();
      refetchVezSockNodes();
    } catch (e: any) {}
    setBusy(false);
  };

  const removeRelation = async (relationID: number) => {
    setBusy(true);

    try {
      await fetcher({
        method: "delete",
        successMessage: "Successfully removed from VezSockList",
        url: `/api/v1/data/vezSockListM2MVezSockNode/${relationID}`,
      });
      refetchAttachedSockNodes();
      refetchVezSockNodes();
    } catch (e: any) {}
    setBusy(false);
  };

  const handleDragEnd = useCallback(
    async (result: DropResult, provided: ResponderProvided) => {
      if (!result.destination || busy) return;

      if (
        result.destination.droppableId === "connectedVezSockNodes" &&
        result.source.droppableId === "vezSockNodes"
      ) {
        const records = vezSockNodes?.result?.rows;
        const selected = records?.[result.source.index];

        if (selected) {
          await addRelation(selected);
        }
      } else if (
        result.source.droppableId === "connectedVezSockNodes" &&
        result.destination.droppableId === "vezSockNodes"
      ) {
        const records = attachedVezSockNodes?.result?.rows;
        const selected = records?.[result.source.index];

        const relationID = selected?.vez_sock_list_m2m_vez_sock_nodes?.find(
          (e) =>
            e.intPkVezSockListID === model?.intPkVezSockListID &&
            model.intPkVezSockListID
        )?.intPkVezSockListM2MVezSockNodeID;

        if (relationID) {
          await removeRelation(relationID);
        }
      }
    },
    [attachedVezSockNodes, vezSockNodes]
  );

  const renderPanes = () => {
    const panes: React.ReactElement[] = [];
    let paneClassName =
      "p-3 mw-33 flex-fill d-flex flex-column align-items-stretch mh-80vh overflow-auto";

    panes.push(
      <div className={paneClassName}>
        <Input
          register={register("sockListName", {
            required: "Required",
            validate: (value) => {
              if (!value?.match(/^[A-Za-z][A-Za-z0-9_]*$/s)) {
                return "Please correct the format, only '_', numbers and alphabets are allowed. First is always alphabet";
              }
            },
          })}
          readOnly={readOnly}
          title="List Name"
          className="mb-3"
          errorMessage={errors.sockListName?.message}
        />
        <CheckboxTileGroup>
          <CheckboxTile control={control} name="enable" title="Enable" />
        </CheckboxTileGroup>
      </div>
    );

    if (isEditMode) {
      paneClassName += " minh-80vh";

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Attached VezSock Nodes</h3>{" "}
          <FilterPicker
            filter={attachedVezSockNodeFilter}
            onFilter={(filter) => setAttachedVezSockNodeFilter(filter)}
          >
            <VezSockListFilter />
          </FilterPicker>
          <DraggablePicker id={"connectedVezSockNodes"}>
            {attachedVezSockNodes?.result?.rows.map(renderVezSockNodeTile)}
          </DraggablePicker>
        </div>
      );

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Available VezSock Nodes</h3>
          <FilterPicker
            filter={vezSockFilter}
            onFilter={(filter) => setVezSockNodeFilter(filter)}
          >
            <VezSockListFilter />
          </FilterPicker>
          <DraggablePicker id={"vezSockNodes"}>
            {vezSockNodes?.result?.rows.map(renderVezSockNodeTile)}
          </DraggablePicker>
        </div>
      );
    }

    return panes;
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <CreateEditModal
        model={selectedVezSockNode}
        idField="intPkVezSockNodeID"
        nameField="vezSockNodeName"
        modelName="vezSockNode"
        onHide={() => {
          setSelectedVezSockNode(undefined);
          refetchAttachedSockNodes();
          refetchVezSockNodes();
        }}
        open={!!selectedVezSockNode}
        renderForm={({ isEditMode, model, readOnly }) => {
          return (
            <VezSockNodeForm
              readOnly={readOnly}
              model={model}
              isEditMode={isEditMode}
            />
          );
        }}
      />
      <div className="d-flex flex-column align-items-stretch flex-lg-row">
        {renderPanes()}
      </div>
    </DragDropContext>
  );
};

export const VezSockListFilter = () => {
  const methods = useFormContext();

  return (
    <div className="m-3">
      <Input
        register={methods.register("or._0.sockListName")}
        title="Search by Name"
        className="mb-3"
      />
      <RadioSelectWithController
        control={methods.control}
        name="enable"
        title="Enable Status"
        options={[
          { label: "All", value: undefined },
          { label: "Enabled", value: 1 },
          { label: "Disabled", value: 0 },
        ]}
      />
    </div>
  );
};
