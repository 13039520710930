import CheckboxTile, { CheckboxTileGroup } from "../../components/FormElements/CheckboxTile";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Fragment, FunctionComponent, useCallback } from "react";
import { NodeAttributes, NodeModel, PoolProfileModel } from "common";
import { PoolAttributes, PoolCreationAttributes, PoolModel } from "common";
import { ReactQueryDevtools } from "react-query/devtools";

import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import ControlledInput from "../../components/FormElements/ControlledInput";
import DraggablePicker from "../../components/DraggablePicker";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import Icon from "@mdi/react";
import Input from "../../components/FormElements/ControlledInput";
import ListTile from "../../components/ListTile";
import { MoonLoader } from "react-spinners";
import { NodeFormAttributes, NodeModelExt, NodesFilterForm, NodesForm } from "./Nodes";
import { optionsForStatus, platformIconMap } from "../../constants";
import { resolveObjectFromPath } from "../../libs/util/resolveObjectFromPath";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useState } from "react";
import CreateEditModal from "../../components/Modals/CreateEditModal";
import { PieChart, Pie, Cell, Tooltip, Legend, Label } from "recharts";
import { ItemId, TreeItem } from "@atlaskit/tree";
import useModelAggregationQuery from "../../libs/hooks/useModelAggregationQuery";

const PoolsPage: FunctionComponent = () => {
  return (
    <EditPage<PoolAttributes, PoolModel>
      modelName="pool"
      fields={[
        "intPkPoolID",
        { key: "poolImageURL", type: "image" },
        "poolName",
        "displayString",
        { key: "includePoolInFreeAutoRouting", type: "boolean" },
        { key: "includePoolInPremiumAutoRouting", type: "boolean" },
        { key: "showPoolInProductionList", type: "boolean" },
        { key: "showPoolInTestList", type: "boolean" },
        { key: "enable", type: "boolean" },
      ]}
      idField="intPkPoolID"
      defaultValues={{
        includePoolInFreeAutoRouting: 1,
        includePoolInPremiumAutoRouting: 1,
        enable: 0,
      }}
      buildTreeView={(data) => {
        const items: Record<ItemId, TreeItem> = {};
        const rootId = "pools";
        const pools = data.result?.rows;

        const poolTreeItemIds = pools?.map((pool) => {
          const poolItemId = `${rootId}-${pool.intPkPoolID}`;
          const nodesItemId = `${poolItemId}-nodes`;
          const profilesItemId = `${poolItemId}-profiles`;

          const nodeTreeItemIds = pool.pools_m2m_nodes?.map((relationWithNode) => {
            // @ts-ignore
            const node: NodeModel = relationWithNode.intPkNode;
            const nodeItemId = `${nodesItemId}-${node.intPkNodeID}`;

            items[nodeItemId] = {
              id: nodeItemId,
              data: {
                label: `${node.name} ${node.enable === 1 ? "🟢" : "🔴"}`,
              },
              children: [],
            };

            return nodeItemId;
          });

          const profileTreeItemIds = pool.pools_statuses?.map((poolStatus) => {
            // @ts-ignore
            const profile: PoolProfileModel = poolStatus.intPkPoolProfile;
            const profileItemId = `${profilesItemId}-${poolStatus.intPkPoolsStatusID}`;

            items[profileItemId] = {
              id: profileItemId,
              data: {
                label: `${profile.poolProfileName} • ${
                  optionsForStatus.find((e) => e.value === poolStatus.isFreeOrTrialOrPremium)?.label
                } ${profile.enable === 1 ? "🟢" : "🔴"}`,
              },
              children: [],
            };

            return profileItemId;
          });

          items[profilesItemId] = {
            id: profilesItemId,
            children: profileTreeItemIds || [],
            data: { label: "Profiles" },
          };

          items[nodesItemId] = {
            id: nodesItemId,
            children: nodeTreeItemIds || [],
            data: { label: "Nodes" },
          };

          items[poolItemId] = {
            id: poolItemId,
            children: [nodesItemId, profilesItemId],
            data: {
              label: `${pool.poolName} ${pool.enable === 1 ? "🟢" : "🔴"}`,
            },
          };

          return poolItemId;
        });

        items[rootId] = {
          id: rootId,
          children: poolTreeItemIds || [],
          data: { label: "Pools" },
        };

        return {
          rootId: "pools",
          items,
        };
      }}
      createEditModalSize="xl"
      nameField="poolName"
      renderForm={({ isEditMode, model, readOnly }) => (
        <PoolsForm readOnly={readOnly} isEditMode={isEditMode} model={model} />
      )}
    />
  );
};

export const PoolsForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<PoolCreationAttributes>) => {
  const {
    formState: { errors },
    control,
    setValue,
    register,
  } = useFormContext<PoolCreationAttributes>();

  const relationModelName = "poolM2MNode";
  const relationWithModelName = "node";

  const [nodesFilter, setNodesFilter] = useState<Partial<NodeAttributes>>({});
  const [attachedNodeFilters, setAttachedNodeFilter] = useState<Partial<NodeAttributes>>({});

  const queryClient = useQueryClient();

  const {
    isLoading: isAttachedNodesLoading,
    data: attachedNodes,
    refetch: refetchAttachedNodes,
  } = useAuthorizedQuery<NodeModelExt>({
    model: "node",
    // limit: 1000,
    filter: {
      ...attachedNodeFilters,
      // @ts-ignore
      pools_m2m_nodes: { some: { intPkPoolID: model?.intPkPoolID } },
    },
    enabled: isEditMode && !!model?.intPkPoolID,
  });

  const {
    isLoading: areNodesLoading,
    data: nodeRecords,
    refetch: refetchNodes,
  } = useAuthorizedQuery<NodeModelExt>({
    model: "node",
    filter: {
      ...nodesFilter,
      pools_m2m_nodes: {
        // @ts-ignore
        none: {
          intPkPoolID: model?.intPkPoolID,
        },
      },
    },
    enabled: isEditMode && !!model?.intPkPoolID,
  });

  const { data: attachedNodesAggregations } = useModelAggregationQuery({
    model: "node",
    filter: {
      // @ts-ignore
      pools_m2m_nodes: { every: { intPkPoolID: model?.intPkPoolID } },
    },
    enabled: isEditMode,
  });

  const { data: nodesAggregation } = useModelAggregationQuery({
    model: "node",
    filter: {
      // @ts-ignore
      pools_m2m_nodes: {
        none: {
          intPkPoolID: model?.intPkPoolID,
        },
      },
    },
    enabled: isEditMode && !!attachedNodes,
  });

  const nodeFetcher = useFetcher({ invalidateCache: false });
  const attachedNodesFetcher = useFetcher({ invalidateCache: false });
  const [selectedNode, setSelectedNode] = useState<NodeFormAttributes>();

  const [busy, setBusy] = useState(false);

  const getDisabledErrorMessage = (): [boolean, string | undefined] => {
    if (!isEditMode)
      return [true, "This option will be available after you save this pool."];

    if (isAttachedNodesLoading) {
      return [true, "Loading..."];
    }

    if (attachedNodes?.result && attachedNodes.result.count < 1) {
      setValue("enable", 0);
      return [
        true,
        "This option will be enabled when you add a Node to this pool",
      ];
    }

    return [false, undefined];
  };

  const removeNodeRelation = useCallback(
    async (relationID: number) => {
      try {
        await attachedNodesFetcher({
          url: `/api/v1/data/poolM2MNode/${relationID}`,
          method: "delete",
          successMessage: "Node deleted successfully",
        });
        refetchAttachedNodes();
        refetchNodes();
      } catch (e: any) {}
    },

    [nodeFetcher, queryClient, attachedNodes]
  );

  const addNode = useCallback(
    async (nodeID: number) => {
      if (model) {
        try {
          await nodeFetcher({
            url: "/api/v1/data/poolM2MNode/create",
            method: "post",
            data: {
              intPkNodeID: nodeID,
              intPkPoolID: model.intPkPoolID,
            },
            successMessage: "Node added successfully",
          });
          refetchAttachedNodes();
          refetchNodes();
        } catch (e: any) {}
      }
    },

    [nodeFetcher, model, attachedNodes, queryClient, nodeRecords]
  );

  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      console.log(busy, result.destination, result.source);

      if (busy) return;

      setBusy(true);

      if (
        result.destination.droppableId === relationModelName &&
        result.source.droppableId === relationWithModelName
      ) {
        if (nodeRecords?.result) {
          const entry = nodeRecords.result.rows[result.source.index];
          if (entry) addNode(entry.intPkNodeID);
        }
      } else if (
        result.source.droppableId === relationModelName &&
        result.destination.droppableId === relationWithModelName
      ) {
        console.log("remove");
        if (attachedNodes?.result) {
          const entry = attachedNodes.result.rows[result.source.index];
          const value = entry.pools_m2m_nodes?.find(
            (e) => e.intPkPoolID === model?.intPkPoolID
          )?.intPkPoolsM2MNodes;
          if (value) removeNodeRelation(value);
        }
      }

      setBusy(false);
    },
    [addNode, removeNodeRelation, attachedNodes]
  );

  const [enableCheckboxDisabled, errorMessage] = getDisabledErrorMessage();

  const renderNodeListTile = (model: NodeModelExt) => {
    return (
      <ListTile
        primary={resolveObjectFromPath(model, "name")}
        onDblClick={() => {
          setSelectedNode(model);
        }}
        secondary={
          <div>
            {resolveObjectFromPath(model, "intPkNodeIP.ipAddress") || "No IP"} |{" "}
            {model.node_m2m_platforms && model.node_m2m_platforms?.length < 1 && "No Platforms"}
            {model.node_m2m_platforms?.map(
              (e) =>
                e.intPkDevicePlatform && (
                  <Icon size={0.9} path={platformIconMap[e.intPkDevicePlatform.platform]} />
                )
            )}
          </div>
        }
        image={
          <img
            src={resolveObjectFromPath(model, "intPkDataCenter.intPkCountry.flagImageUrl")}
            alt="Pool"
          />
        }
      >
        <div className="col-2 align-items-center d-flex justify-content-end">
          <span
            className={`badge align-left ${
              !!resolveObjectFromPath(model, "enable") ? "bg-success" : "bg-danger"
            } me-2 px-3`}
          >
            {model.currentConnectedSessions || 0}/{model.maxUsers}
          </span>
        </div>
      </ListTile>
    );
  };

  const renderPanes = () => {
    const panes: React.ReactElement[] = [];
    const paneClassName =
      "p-3 mw-33 flex-fill d-flex flex-column align-items-stretch mh-80vh overflow-auto";

    const enabledCount = attachedNodesAggregations?.result?.find(
      (e) => e.name === "Enabled"
    )!.value;

    const totalCapacity = attachedNodesAggregations?.result?.find(
      (e) => e.name === "Total Capacity"
    )!.value;

    const enabledCapacity = attachedNodesAggregations?.result?.find(
      (e) => e.name === "Enabled Capacity"
    )!.value;

    const attachedNodesChartData = [
      {
        name: "Enabled Nodes",
        value: enabledCount,
      },
      {
        name: "Attached Nodes",
        value: attachedNodes?.result?.count ? attachedNodes?.result?.count - enabledCount! : 0,
      },
    ];

    const attachedNodesCapacityChartData = [
      {
        name: "Enabled Cap.",
        value: enabledCapacity,
      },
      {
        name: "Attached Cap.",
        value: totalCapacity - enabledCapacity,
      },
    ];

    const currentCapcityChartData = [
      {
        name: "Curr Cap.",
        value: enabledCapacity - 10,
      },
      {
        name: "Attached Cap.",
        value: enabledCapacity - (enabledCapacity - 10),
      },
    ];

    const colors = ["var(--tblr-success)", "var(--tblr-primary)"];

    panes.push(
      <div className={paneClassName}>
        <div className="d-flex flex-row mb-3">
          <ControlledInput
            errorMessage={errors.poolName?.message}
            register={register("poolName", {
              required: "Required",
            })}
            title="Pool Name"
            readOnly={readOnly}
          />
          <ControlledInput
            errorMessage={errors.displayString?.message}
            register={register("displayString", {
              required: "Required",
            })}
            className="ms-3"
            readOnly={readOnly}
            title="Display String"
          />
        </div>
        <ControlledInput
          errorMessage={errors.poolImageURL?.message}
          register={register("poolImageURL", {
            required: "Required",
          })}
          title="Pool Image URL"
          className="mb-3"
          readOnly={readOnly}
        />

        {isEditMode && (
          <div className="flex-fill d-flex flex-column justify-content-center w-100 mb-3">
            <PieChart width={350} height={200}>
              <Pie
                data={attachedNodesChartData}
                cx="50%"
                cy="50%"
                labelLine={true}
                outerRadius={50}
                fill="#8884d8"
                dataKey="value"
                legendType="triangle"
              >
                {attachedNodesChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
            <PieChart width={350} height={200}>
              <Pie
                data={attachedNodesCapacityChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={50}
                dataKey="value"
              >
                {attachedNodesCapacityChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Label position="inside" value="1" />
              <Legend />
            </PieChart>
            <PieChart width={350} height={200}>
              <Pie
                data={currentCapcityChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={50}
                dataKey="value"
              >
                {currentCapcityChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
        )}

        <CheckboxTileGroup>
          <CheckboxTile
            name="enable"
            control={control}
            title="Enable"
            readOnly={enableCheckboxDisabled}
            errorMessage={errorMessage}
          />

          <CheckboxTile
            name="includePoolInFreeAutoRouting"
            readOnly={readOnly}
            control={control}
            title="Include Node In Free Auto Routing"
          />
          <CheckboxTile
            name="includePoolInPremiumAutoRouting"
            readOnly={readOnly}
            control={control}
            title="Include Node In Premium Auto Routing"
          />
          <CheckboxTile
            name="showPoolInProductionList"
            readOnly={readOnly}
            control={control}
            title="Show Pool in Production List"
          />
          <CheckboxTile
            name="showPoolInTestList"
            readOnly={readOnly}
            control={control}
            title="Show Pool in Test List"
          />
        </CheckboxTileGroup>
      </div>
    );

    if (isEditMode) {
      panes.push(
        <Fragment>
          <CreateEditModal
            model={selectedNode}
            idField="intPkNodeID"
            nameField="name"
            modelName="node"
            onHide={() => {
              setSelectedNode(undefined);
              refetchAttachedNodes();
              refetchNodes();
            }}
            open={!!selectedNode}
            renderForm={({ isEditMode, model, readOnly }) => {
              return <NodesForm readOnly={readOnly} model={model} isEditMode={isEditMode} />;
            }}
          />
          <div className={`${paneClassName} border-start`}>
            <h3>Attached Nodes</h3>
            <FilterPicker
              filter={attachedNodeFilters}
              onFilter={(filter) => {
                setAttachedNodeFilter(filter);
              }}
            >
              <NodesFilterForm />
            </FilterPicker>
            {isAttachedNodesLoading && (
              <div className="d-flex justify-content-center align-items-center m-3">
                <MoonLoader size={30} />
              </div>
            )}
            <DraggablePicker id={relationModelName}>
              {attachedNodes?.result?.rows.map(renderNodeListTile)}
            </DraggablePicker>
          </div>
        </Fragment>
      );

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <div className="d-flex justify-content-between align-items-start mb-2">
            <h3 className="m-0">Nodes</h3>
            <p className="m-0">
              <span className="text-success">
                {nodesAggregation?.result?.find((e) => e.name === "Enabled")?.value}
              </span>{" "}
              / {nodeRecords?.result?.count}
            </p>
          </div>
          <FilterPicker
            filter={nodesFilter}
            onFilter={(filter) => {
              setNodesFilter(filter);
            }}
          >
            <NodesFilterForm />
          </FilterPicker>

          {areNodesLoading ? (
            <div className="d-flex justify-content-center align-items-center my-3">
              <MoonLoader size={30} />
            </div>
          ) : (
            <DraggablePicker id={relationWithModelName}>
              {nodeRecords?.result?.rows?.map(renderNodeListTile)}
            </DraggablePicker>
          )}
        </div>
      );
    }

    return panes;
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <ReactQueryDevtools initialIsOpen={false}></ReactQueryDevtools>
      <div className="d-flex flex-column align-items-stretch flex-lg-row">{renderPanes()}</div>
    </DragDropContext>
  );
};

export const PoolsFilterForm = () => {
  const methods = useFormContext<any>();

  const mainValue = methods.watch("or.0.name");

  return (
    <div className="m-3">
      <Input register={methods.register("or.0.poolName")} title="Search" />
      <Input
        register={methods.register("or.1.displayString", { value: mainValue })}
        title=""
        type="hidden"
        readOnly
      />
    </div>
  );
};

export default PoolsPage;
