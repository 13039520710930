import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";

import CustomModal, { ModalProps } from "./Modals/Modal";
import { HotKeys } from "react-hotkeys";

export interface FilterFormProps {
  filter?: any;
  modalSize?: ModalProps["size"];
  onFilter?: (filter: any) => void;
}

export const removeNullishValuesExceptNull = (obj: any): any => {
  if (obj === undefined) return;
  if (obj === null) return null;

  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      const newArray = obj
        .map(removeNullishValuesExceptNull)
        .filter((e) => e !== undefined);

      if (newArray.length < 1) return;

      return newArray;
    }

    const entries = Array.from(Object.entries(obj));
    const newObj = entries.reduce(reduceObjEntries, {} as any);

    if (Object.keys(newObj).length > 0) return newObj;
  } else if (typeof obj === "string" && obj !== "") return obj;
  else if (typeof obj === "number" && !isNaN(obj)) return obj;
};

const replaceIntWithObject = (obj: any) => {
  if (!obj || typeof obj !== "object") return obj;

  return Object.fromEntries(
    Object.entries(obj).map((each) => {
      const [key, value] = each;

      return [key, typeof value == "number" ? { equals: value } : value];
    })
  );
};

const replaceObjectwithInt = (obj: any) => {
  return (
    obj &&
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        key,
        typeof value == "object" &&
        (value as any)?.equals &&
        typeof (value as any)?.equals === "number"
          ? (value as any).equals
          : value,
      ])
    )
  );
};

const reduceObjEntries = (overall: any, obj: any) => {
  const [key, value] = obj;
  const newValue = removeNullishValuesExceptNull(value);
  if (newValue !== undefined) {
    overall[key] = newValue;
  }
  return overall;
};

export const FilterPicker = ({
  filter: preFilter,
  children,
  onFilter,
  modalSize = "sm",
}: React.PropsWithChildren<FilterFormProps>) => {
  const filter = useMemo(() => replaceObjectwithInt(preFilter), [preFilter]);

  const methods = useForm<any>({
    defaultValues: filter,
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    methods.reset(filter);
  }, [filter]);

  const onPrimaryButtonClick = () => {
    const values = methods.getValues();
    const currentValues = replaceIntWithObject(removeNullishValuesExceptNull(values));
    console.log(currentValues);

    onFilter && onFilter(currentValues);
    setShow(false);
  };

  return (
    <HotKeys
      handlers={{ TEST: onPrimaryButtonClick }}
      keyMap={{ TEST: "enter" }}
    >
      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn w-100"
            onClick={() => setShow(true)}
          >
            Filter ({filter ? Object.keys(filter).length : "0"})
          </button>
        </div>
        {filter && Object.keys(filter).length > 0 && (
          <div className="col">
            <button
              type="button"
              className="btn btn-secondary w-100"
              onClick={() => onFilter && onFilter({})}
            >
              Clear Filter
            </button>
          </div>
        )}
      </div>
      <CustomModal
        onHide={() => {
          setShow(false);
          if (filter) {
            methods.reset(filter);
          } else {
            methods.reset({});
          }
        }}
        show={show}
        title="Select Filter"
        size={modalSize}
        primaryButtonText="Set Filter"
        onPrimaryButtonClick={onPrimaryButtonClick}
      >
        <FormProvider {...methods}>{children}</FormProvider>
      </CustomModal>
    </HotKeys>
  );
};
