import ControlledInput from "../../components/FormElements/ControlledInput";
import { Controller } from "react-hook-form";
import DatePickerCustom from "../../components/FormElements/DatePickerCustom";
import EditPage from "../../components/EditPage";
import { FunctionComponent } from "react";
import { IPInfoLogAttributes } from "common";

const IPInfoLogsPage: FunctionComponent = () => {
  return (
    <EditPage<IPInfoLogAttributes>
      modelName="ipInfoLog"
      title="IP Info Logs"
      showCreateButton={false}
      fields={[
        "intPkIpInfoLogsID",
        "service",
        "response",
        "ip",
        { key: "datetime", type: "date" },
      ]}
      idField="intPkIpInfoLogsID"
      nameField="ip"
      renderForm={({ register, control, errors, model }) => (
        <div className="m-3">
          <div className="d-flex flex-row mb-3">
            <ControlledInput
              register={register("service", { required: true })}
              errorMessage={errors.service ? "Required" : ""}
              title="Service"
              readOnly={true}
            />
            <div className="form-group ms-3">
              <label className="form-label">DateTime</label>
              <Controller
                name="datetime"
                control={control}
                render={({ field: { name, onBlur, onChange, value } }) => {
                  return (
                    <DatePickerCustom
                      {...{ name, onBlur, value, onChange }}
                      disabled={true}
                    />
                  );
                }}
              />
            </div>
          </div>
          <ControlledInput
            register={register("response", { required: true })}
            errorMessage={errors.response ? "Required" : ""}
            title="Response"
            readOnly={true}
            className="mb-3"
          />
          <ControlledInput
            register={register("ip", { required: true })}
            errorMessage={errors.ip ? "Required" : ""}
            title="IP"
            readOnly={true}
          />
        </div>
      )}
    />
  );
};

export default IPInfoLogsPage;
