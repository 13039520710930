import { DevicePlatformAttributes } from "common";
import EditPage from "../../components/EditPage";
import { FunctionComponent } from "react";
import Icon from "@mdi/react";
import Input from "../../components/FormElements/ControlledInput";
import { platformIconMap } from "../../constants";

const DevicePlaftormPage: FunctionComponent = () => {
  return (
    <EditPage<DevicePlatformAttributes>
      modelName="devicePlatform"
      fields={[
        "intPkDevicePlatformID",
        {
          name: "icon",
          value: (e) => <Icon path={platformIconMap[e.platform]} size={1} />,
        },
        "platform",
      ]}
      idField="intPkDevicePlatformID"
      nameField="platform"
      renderForm={({ register, readOnly }) => (
        <div className="m-3">
          <Input
            register={register("platform", { required: true })}
            readOnly={readOnly}
            title="Platform"
          />
        </div>
      )}
    />
  );
};

export default DevicePlaftormPage;
