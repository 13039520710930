import {
  GoogleServiceAccountAttributes,
} from "common";
import { useFormContext } from "react-hook-form";
import EditPage from "../../components/EditPage";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";

export const GoogleServiceAccountsPage: React.FunctionComponent = () => {
  return (
    <EditPage<GoogleServiceAccountAttributes, GoogleServiceAccountAttributes>
      modelName="googleServiceAccount"
      nameField="intPkGoogleServiceAccountID"
      idField="intPkGoogleServiceAccountID"
      title="Google Service Account"
      fields={["serviceAccountJSON"]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <GoogleServiceAccountsForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const GoogleServiceAccountsForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<GoogleServiceAccountAttributes>) => {
  const {
    register,
  } = useFormContext<GoogleServiceAccountAttributes>();

  return (
    <div className="m-3">
      <div className="form-group">
        <label className="form-label">Service Account</label>
      </div>
      <textarea
        className="form-control"
        {...register("serviceAccountJSON", {
          required: "Required",
        })}
      >
      </textarea>
    </div>
  );
};
