import {
  Control,
  FieldPath,
  useController,
  useFormContext,
} from "react-hook-form";
import { FunctionComponent, useMemo, useState } from "react";

import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import EditPage from "../../components/EditPage";
import Input from "../../components/FormElements/ControlledInput";
import { RadioSelectWithController } from "../../components/FormElements/RadioSelect";
import { SplitTunnelProfileAttributes } from "common";
import { useEffect } from "react";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";

const SplitTunnelProfilePage: FunctionComponent = () => {
  return (
    <EditPage<SplitTunnelProfileAttributes>
      modelName="splitTunnelProfile"
      idField="intPkSplitTunnelID"
      createEditModalSize="lg"
      defaultValues={{
        displaySplitTunnelButton: 1,
        allowToChangeSplitTunnelSettings: 1,
        enforceSplitTunnelPolicy: 0,
      }}
      nameField="configName"
      fields={["configName", "strategy"]}
      renderForm={({ readOnly, isEditMode, model }) => (
        <SplitTunnelProfileForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

interface SplitTunnelJSONInputProps {
  control: Control<SplitTunnelProfileAttributes>;
  name: FieldPath<SplitTunnelProfileAttributes>;
  readOnly: boolean;
  jsonKey: string;
}

const SplitTunnelJSONInput = ({
  control,
  jsonKey,
  name,
  readOnly,
}: SplitTunnelJSONInputProps) => {
  const {
    field: { onChange, value },
  } = useController({ control, name });

  const [json, setJson] = useState<Record<string, (string | boolean)[]>>();
  const [textValue, setTextValue] = useState("");
  const opposite = useMemo(
    () => (jsonKey === "ALLOW" ? "DENY" : "ALLOW"),
    [jsonKey]
  );

  useEffect(() => {
    if (!value) return;
    if (json !== undefined) return;

    setJson(JSON.parse(value));
  }, [value]);

  useEffect(() => {
    const copy = { ...json };

    if (!jsonKey || copy[jsonKey]?.length !== 1) return;

    const temp = copy[opposite] || [];
    copy[opposite] = copy[jsonKey] || [false];
    copy[jsonKey] = temp;

    setJson(copy);
    fireUpdate(copy);
  }, [jsonKey]);

  const fireUpdate = (newJsonVale: object) => {
    console.log("splitTunnelConfig", newJsonVale);
    onChange(JSON.stringify(newJsonVale));
  };

  const handleAddClick = () => {
    const copy = { ...json };

    if (textValue === "") return;

    if (!copy[jsonKey]) {
      copy[jsonKey] = [];
    }

    copy[jsonKey].push(textValue);
    setTextValue("");

    if (!copy[opposite]) {
      copy[opposite] = [false];
    }

    setJson(copy);
    fireUpdate(copy);
  };

  return (
    <div>
      <div>
        <table className="table">
          <thead>
            <tr>
              <th>Package IDs</th>
            </tr>
          </thead>
          <tbody>
            {json?.[jsonKey]?.map((value) => {
              return (
                <tr>
                  <td>{value}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex">
        <div className="flex-fill">
          <input
            type="text"
            className="form-control"
            onKeyDown={(e) => {
              if (e.code === "Enter" || e.code === "NumberpadEnter") {
                e.preventDefault();
                handleAddClick();
              }
            }}
            onChange={(e) => setTextValue(e.currentTarget.value)}
            value={textValue}
            placeholder="Package ID"
            disabled={readOnly}
          />
        </div>
        <div className="ms-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleAddClick}
            disabled={readOnly}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

const SplitTunnelProfileForm = ({
  readOnly,
}: CRUDFormProps<SplitTunnelProfileAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<SplitTunnelProfileAttributes>();

  const strategy = watch("strategy");

  return (
    <div className="m-3">
      <Input
        register={register("configName", { required: "Required" })}
        title="Config Name"
        errorMessage={errors.configName?.message}
        readOnly={readOnly}
        className="mb-3"
      />

      <RadioSelectWithController
        name="strategy"
        control={control}
        options={[
          {
            label: "ALLOW",
            value: "ALLOW",
          },
          {
            label: "DENY",
            value: "DENY",
          },
        ]}
        title="Strategy"
        readOnly={readOnly}
      />

      <SplitTunnelJSONInput
        control={control}
        name="configJSON"
        readOnly={readOnly}
        jsonKey={strategy}
      />

      <div className="mt-3">
        <CheckboxTileGroup>
          <CheckboxTile
            name="displaySplitTunnelButton"
            control={control}
            title="displaySplitTunnelButton"
          />
          <CheckboxTile
            name="allowToChangeSplitTunnelSettings"
            control={control}
            title="allowToChangeSplitTunnelSettings"
          />
          <CheckboxTile
            name="enforceSplitTunnelPolicy"
            control={control}
            title="enforceSplitTunnelPolicy"
          />
        </CheckboxTileGroup>
      </div>
    </div>
  );
};

export default SplitTunnelProfilePage;
