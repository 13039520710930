import {
  BrandAttributes,
  SplitTunnelProfileAttributes,
  VezgateProxyProfileAttributes,
  VezSockProfileAttributes,
  VezSsListAttributes,
} from "common";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import { BrandsStatusAttributes } from "common";
import { BrandsStatusModel } from "common";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import { CountryAttributes } from "common";
import EditPage from "../../components/EditPage";
import { FunctionComponent, useEffect, useState } from "react";
import { IspAttributes } from "common";
import { JsonConfigAttributes } from "common";
import { PoolProfileAttributes } from "common";
import { useFormContext } from "react-hook-form";
import RadioSelect, {
  RadioSelectWithController,
} from "../../components/FormElements/RadioSelect";
import useFetcher from "../../libs/hooks/useFetcher";
import { FilterPicker } from "../../components/FilterPicker";
import { useCreateEditModalContext } from "../../components/Modals/CreateEditModal";

const BrandStatusFilter = () => {
  const methods = useFormContext();

  return (
    <div className="m-3">
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Country</label>
        <AsyncSelectWithController<any, any>
          searchField="countryName"
          model="country"
          imageField="flagImageUrl"
          control={methods.control}
          valueFieldName="intPkCountryID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">ISP</label>
        <AsyncSelectWithController<any, any>
          searchField="ispName"
          model="isp"
          imageField="ispLogoUrl"
          control={methods.control}
          valueFieldName="intPkIspID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Brand</label>
        <AsyncSelectWithController<any, any>
          searchField="brandName"
          model="brand"
          imageField="iconUrl"
          control={methods.control}
          valueFieldName="intPkBrandID"
        />
      </div>
    </div>
  );
};

const ChangeUserSelection = ({ value, id }: any) => {
  const fetcher = useFetcher({ alerts: true, invalidateCache: true });

  return (
    <RadioSelect
      name={"userSelection-" + id}
      onOptionSelect={(value) => {
        fetcher({
          method: "put",
          url: `/api/v1/data/brandStatus/${id}/edit`,
          data: { userSelection: value },
          successMessage: "Updated",
        });
      }}
      value={value}
      options={[
        {
          label: "Auto",
          value: "AUTO",
        },
        {
          label: "Cisco",
          value: "CISCO",
        },
        {
          label: "Shadow Socks",
          value: "SS",
        },
      ]}
    />
  );
};

const BrandsStatusPage: FunctionComponent = () => {
  const [minimized, setMinimimzed] = useState(true);
  const [filter, setFilter] = useState({});

  return (
    <EditPage<BrandsStatusAttributes, BrandsStatusModel>
      modelName="brandStatus"
      showMinimalView={minimized}
      filter={filter}
      renderCardHeader={() => (
        <div className="align-self-end d-flex align-items-end border-start flex-row ps-2">
          <RadioSelect
            name="tset"
            onOptionSelect={(value) => {
              if (value !== undefined) setMinimimzed(value);
            }}
            value={minimized}
            options={[
              { label: "Full", value: false },
              { label: "Minimized", value: true },
            ]}
          />
          <FilterPicker filter={filter} onFilter={setFilter}>
            <BrandStatusFilter />
          </FilterPicker>
        </div>
      )}
      minimalViewFields={[
        {
          key: "intPkBrandsStatusID",
          type: "string",
          name: "ID",
        },
        {
          name: "brand",
          value: (model) => {
            return (
              <div className="d-flex flex-row align-items-center">
                {model.intPkBrand && (
                  <>
                    <img
                      className="me-2"
                      src={model.intPkBrand!.iconUrl}
                      alt={model.intPkBrand!.brandName}
                      height="30px"
                      onError={function (x) {
                        x.currentTarget.src = "/images/question.png";
                      }}
                    />
                    {model.intPkBrand!.brandName}
                  </>
                )}
              </div>
            );
          },
        },
        {
          key: "intPkCountry.countryCode",
          name: "C.C",
          type: "string",
        },
        {
          key: "intPkCountry.flagImageUrl",
          name: "C.flag",
          type: "image",
        },
        {
          name: "ISPLogo",
          key: "intPkIsp.ispLogoUrl",
          type: "image",
        },
        {
          name: "ISP",
          value: (model) => (
            <span>{model.intPkIsp?.ispDomain?.split(".")[0]}</span>
          ),
        },
        {
          name: "U.S",
          value: (model) => (
            <ChangeUserSelection
              value={model.userSelection}
              id={model.intPkBrandsStatusID}
            />
          ),
        },
        {
          name: "Profile",
          key: "intPkPoolProfile.poolProfileName",
          type: "string",
        },
        {
          name: "Config",
          key: "intPkConfig.configName",
          type: "string",
        },
        {
          name: "SS List",
          key: "intPkVezSSList.listName",
          type: "string",
        },
        {
          name: "Enable",
          key: "enable",
          type: "switch",
        },
      ]}
      fields={[
        "intPkBrandsStatusID",
        { type: "image", key: "intPkBrand.iconUrl", name: "brandIcon" },
        "intPkBrand.brandName",
        {
          type: "image",
          key: "intPkCountry.flagImageUrl",
          name: "countryFlag",
        },
        "intPkCountry.countryName",
        {
          type: "image",
          key: "intPkIsp.ispLogoUrl",
          name: "ISPLogo",
        },
        {
          name: "ISP",
          value: (model) => (
            <span>{model.intPkIsp?.ispDomain?.split(".")[0]}</span>
          ),
        },
        "intPkPoolProfile.poolProfileName",
        "intPkConfig.configName",
        {
          key: "intPkSplitTunnel.configName",
          type: "string",
          name: "splitTunnelProfileName",
        },
        {
          key: "intPkVezgateProxyProfile.profileName",
          type: "string",
          name: "vezgateProxyProfileName",
        },
        {
          key: "intPkVezSockProfile.profileName",
          type: "string",
          name: "vezSockProfileName",
        },
        {
          key: "intPkVezSSList.listName",
          type: "string",
          name: "VezSSListName",
        },
        {
          name: "userSelectionEdit",
          value: (z) => (
            <ChangeUserSelection
              value={z.userSelection}
              id={z.intPkBrandsStatusID}
            />
          ),
        },
        { key: "fakeDisplayInFreeList", type: "boolean" },
        { key: "fakeDisplayInPremiumList", type: "boolean" },
        { key: "enable", type: "boolean" },
      ]}
      defaultValues={{ enable: 1, userSelection: "AUTO" }}
      title="Brand Status"
      defaultLimit={100}
      buildGroupView={(data) => {
        const rows = data.result?.rows;

        if (!rows) return;

        const items: Array<Record<string, any>> = [];
        const groupedRows: Record<string, BrandsStatusModel[]> = {};

        for (const row of rows) {
          if (!row.intPkBrand?.brandName) continue;

          if (!groupedRows[row.intPkBrand?.brandName]) {
            groupedRows[row.intPkBrand.brandName] = [];
          }

          groupedRows[row.intPkBrand.brandName].push(row);
        }

        Object.entries(groupedRows).forEach(([key, value]) => {
          value.sort((a, b) => (a.isGeneric ? (b.isGeneric ? 0 : 1) : -1));
          const firstValue = value.pop();

          items.push({
            id: firstValue?.intPkBrandsStatusID,
            content: firstValue,
            hasChildren: value.length > 0,
            children: value.map((e) => ({
              id: e.intPkBrandsStatusID,
              content: e,
            })),
          });
        });

        return items;
      }}
      idField="intPkBrandsStatusID"
      nameField="intPkBrandsStatusID"
      isRecordDeletable={(e) => false}
      renderForm={({ model, isEditMode, readOnly }) => (
        <BrandsStatusForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

const BrandsStatusForm = ({
  isEditMode,
  readOnly,
  model,
}: CRUDFormProps<BrandsStatusAttributes>) => {
  const { watch, control } = useFormContext<BrandsStatusAttributes>();

  // There is a special case when a generic Brand status is readOnly.
  // The read only comming as paramenter is based on permission
  const isReadOnly = (isEditMode && model && !!model.isGeneric) || readOnly;
  const countryID = watch("intPkCountryID");
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const { setPrimaryButtonDisabled } = useCreateEditModalContext();

  useEffect(() => {
    const loadingCount = Object.keys(loading).filter(
      (e) => !!loading[e]
    ).length;
    setPrimaryButtonDisabled(loadingCount > 0);
  }, [loading]);

  return (
    <div className="m-3">
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Brand</label>
        <AsyncSelectWithController<BrandsStatusAttributes, BrandAttributes>
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkBrandID"] = state;
              return newLoading;
            });
          }}
          control={control}
          valueFieldName="intPkBrandID"
          searchField="brandName"
          readOnly={isReadOnly}
          rules={{ required: true }}
          model="brand"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Country</label>
        <AsyncSelectWithController<BrandsStatusAttributes, CountryAttributes>
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkCountryID"] = state;
              return newLoading;
            });
          }}
          control={control}
          valueFieldName="intPkCountryID"
          imageField="flagImageUrl"
          searchField="countryName"
          readOnly={isReadOnly}
          rules={{ required: !isReadOnly }}
          model="country"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">ISP</label>
        <AsyncSelectWithController<BrandsStatusAttributes, IspAttributes>
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkIspID"] = state;
              return newLoading;
            });
          }}
          control={control}
          valueFieldName="intPkIspID"
          searchField="ispName"
          imageField="ispLogoUrl"
          readOnly={isReadOnly}
          rules={{
            required: "Required",
          }}
          filter={{
            // @ts-ignore
            intPkCountryID: countryID && { equals: countryID },
          }}
          model="isp"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Pool Profile</label>
        <AsyncSelectWithController<
          BrandsStatusAttributes,
          PoolProfileAttributes
        >
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkPoolProfileID"] = state;
              return newLoading;
            });
          }}
          control={control}
          rules={{ required: "Required" }}
          valueFieldName="intPkPoolProfileID"
          readOnly={readOnly} // Only readOnly when permission is not allowed
          searchField="poolProfileName"
          model="poolProfile"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Config</label>
        <AsyncSelectWithController<BrandsStatusAttributes, JsonConfigAttributes>
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkConfigID"] = state;
              return newLoading;
            });
          }}
          control={control}
          rules={{ required: "Required" }}
          valueFieldName="intPkConfigID"
          readOnly={readOnly} // Only readOnly when permission is not allowed
          searchField="configName"
          model="jsonConfig"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Split Tunnel Policy</label>
        <AsyncSelectWithController<
          BrandsStatusAttributes,
          SplitTunnelProfileAttributes
        >
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkSplitTunnelID"] = state;
              return newLoading;
            });
          }}
          control={control}
          rules={{ required: "Required" }}
          valueFieldName="intPkSplitTunnelID"
          readOnly={readOnly} // Only readOnly when permission is not allowed
          searchField="configName"
          model="splitTunnelProfile"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Vezgate Proxy Profile</label>
        <AsyncSelectWithController<
          BrandsStatusAttributes,
          VezgateProxyProfileAttributes
        >
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkVezgateProxyProfileID"] = state;
              return newLoading;
            });
          }}
          control={control}
          rules={{ required: "Required" }}
          valueFieldName="intPkVezgateProxyProfileID"
          readOnly={readOnly} // Only readOnly when permission is not allowed
          searchField="profileName"
          model="vezgateProxyProfile"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">VezSock Profile</label>
        <AsyncSelectWithController<
          BrandsStatusAttributes,
          VezSockProfileAttributes
        >
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkVezSockProfileID"] = state;
              return newLoading;
            });
          }}
          control={control}
          rules={{ required: "Required" }}
          valueFieldName="intPkVezSockProfileID"
          readOnly={readOnly} // Only readOnly when permission is not allowed
          searchField="profileName"
          model="vezSockProfile"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">VezSs List Profile</label>
        <AsyncSelectWithController<BrandsStatusAttributes, VezSsListAttributes>
          onLoadingStateChange={(state) => {
            setLoading((loading) => {
              const newLoading = { ...loading };
              newLoading["intPkVezSSListID"] = state;
              return newLoading;
            });
          }}
          control={control}
          rules={{ required: "Required" }}
          valueFieldName="intPkVezSSListID"
          readOnly={readOnly} // Only readOnly when permission is not allowed
          searchField="listName"
          model="vezSsList"
        />
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">User Selection</label>
        <RadioSelectWithController<BrandsStatusAttributes, string>
          control={control}
          name="userSelection"
          options={[
            {
              label: "Auto",
              value: "AUTO",
            },
            {
              label: "Cisco",
              value: "CISCO",
            },
            {
              label: "Shadow Socks",
              value: "SS",
            },
          ]}
          readOnly={readOnly} // Only readOnly when permission is not allowed
        />
      </div>

      <CheckboxTileGroup>
        <CheckboxTile
          control={control}
          name="fakeDisplayInFreeList"
          title="Fake Display In FreeList"
          readOnly={readOnly}
        />

        <CheckboxTile
          control={control}
          name="fakeDisplayInPremiumList"
          title="Fake Display In Premium List"
          readOnly={readOnly}
        />
        <CheckboxTile
          control={control}
          name="enable"
          title="Enable"
          readOnly={readOnly}
        />
      </CheckboxTileGroup>
    </div>
  );
};

export default BrandsStatusPage;
