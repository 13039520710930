import classNames from "classnames";
import React, { useMemo } from "react";

interface TabPaneProps {
  title: string;
}

interface TabViewProps {
  className?: string;
  children: React.ReactNode;
}

export function TabPane(props: React.PropsWithChildren<TabPaneProps>) {
  return <>{props.children}</>;
}

export function TabView(props: TabViewProps) {
  const [activeTab, setActiveTab] = React.useState(0);

  const children = useMemo(
    () =>
      React.Children.map(props.children, (child: any) => {
        console.log(child);
        if (child.type === TabPane) {
          const titleProp = child.props.title;
          const children = child.props.children;

          return {
            title: titleProp,
            children: children,
          };
        } else {
          throw new Error("TabView only accepts TabPane as children");
        }
      }),
    [props.children]
  );

  return (
    <div className={classNames("card", props.className)}>
      <ul className="nav nav-tabs nav-fill">
        {children?.map((child, index) => (
          <li className="nav-item" onClick={() => setActiveTab(index)}>
            <a
              href="#"
              className={classNames(
                "nav-link",
                index === activeTab && "active"
              )}
            >
              {child.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="card-body p-0">
        <div className="tab-content">
          {children?.map((child, index) => (
            <div
              className={classNames(
                "tab-pane",
                index === activeTab && "active"
              )}
            >
              <div>{child.children}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
