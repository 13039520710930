import CheckboxTile, { CheckboxTileGroup } from "../../components/FormElements/CheckboxTile";
import { Fragment, FunctionComponent } from "react";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import ControlledInput from "../../components/FormElements/ControlledInput";
import { CountryAttributes } from "common";
import { DataCenterCreationAttributes } from "common";
import EditPage from "../../components/EditPage";
import { VendorAttributes } from "common";
import { useFormContext } from "react-hook-form";

type DataCenterAllAttributes = DataCenterCreationAttributes & {
  intPkVendor: VendorAttributes;
  intPkCountry: CountryAttributes;
};

const DataCentersPage: FunctionComponent = () => {
  return (
    <EditPage<DataCenterCreationAttributes, DataCenterAllAttributes>
      modelName="dataCenter"
      fields={[
        "intPkDataCenterID",
        "dataCenterName",
        "city",
        { type: "image", key: "intPkCountry.flagImageUrl" },
        "supportContact",
        "intPkVendor.vendorName",
        { key: "enable", type: "boolean" },
      ]}
      idField="intPkDataCenterID"
      nameField="dataCenterName"
      defaultValues={{ enable: 1 }}
      renderForm={({ isEditMode, readOnly, model }) => (
        <DataCenterForm readOnly={readOnly} isEditMode={isEditMode} model={model} />
      )}
    />
  );
};

const DataCenterForm = ({ readOnly, model }: CRUDFormProps<DataCenterCreationAttributes>) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<DataCenterCreationAttributes>();

  const enableState = watch("enable");

  return (
    <Fragment>
      <ConfirmModal
        title="Are you sure you want to delete this Datacenter?"
        description="Disabling this Datacenter will stop all the traffic on this Datacenter's servers"
        show={model !== undefined && enableState === 0 && model.enable === 1}
        onDenied={() => {
          setValue("enable", 1);
        }}
      />
      <div className="m-3">
        <div className="d-flex flex-row">
          <ControlledInput
            register={register("dataCenterName", { required: "Required" })}
            title="Data Center Name"
            readOnly={readOnly}
            errorMessage={errors.dataCenterName?.message}
          />
          <ControlledInput
            register={register("city", { required: "Required" })}
            errorMessage={errors.city?.message}
            readOnly={readOnly}
            className="mb-3 ms-3"
            title="City"
          />
        </div>
        <div className="form-group flex-fill mb-3">
          <label className="form-label">Country</label>
          <AsyncSelectWithController<DataCenterCreationAttributes, CountryAttributes>
            control={control}
            searchField="countryName"
            readOnly={readOnly}
            errorMessage={errors.intPkCountryID?.message}
            valueFieldName="intPkCountryID"
            imageField="flagImageUrl"
            rules={{ required: "Required" }}
            model="country"
          />
        </div>
        <ControlledInput
          register={register("supportContact", { required: "Required" })}
          errorMessage={errors.supportContact ? "required" : ""}
          readOnly={readOnly}
          title="Support Contact"
        />
        <div className="form-group my-3 flex-fill ">
          <label className="form-label">Vendor</label>
          <AsyncSelectWithController<DataCenterCreationAttributes, VendorAttributes>
            control={control}
            searchField="vendorName"
            readOnly={readOnly}
            rules={{ required: "Required" }}
            errorMessage={errors.intPkVendorID?.message}
            valueFieldName="intPkVendorID"
            model="vendor"
          />
        </div>
        <CheckboxTileGroup>
          <CheckboxTile name="enable" readOnly={readOnly} control={control} title="Enable" />
        </CheckboxTileGroup>
      </div>
    </Fragment>
  );
};

export default DataCentersPage;
