import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { useState } from "react";
import { MoonLoader } from "react-spinners";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";

export const LogsPage: React.FunctionComponent = () => {
  const { data, isLoading, isFetching } = useAuthorizedQuery<
    Record<string, any>
  >({
    model: "log",
    limit: 100,
  });

  const isFiltered = false;

  return (
    <div className="page-wrapper">
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">VIEW</div>
              <h2 className="page-title">Logs</h2>
            </div>
            <div className="card my-3">
              <div className="card-header flex-row justify-content-between align-items-center">
                <h3 className="card-title">
                  <div>Last Errors</div>
                </h3>
                {isFetching && !isLoading && (
                  <div className="align-self-center">
                    <div className="bg-white d-flex justify-content-center">
                      <MoonLoader loading={true} size={20} />
                    </div>
                  </div>
                )}
              </div>
              <div className="list-group list-group-flush list-group-hoverable">
                {isLoading && (
                  <div className="bg-white d-flex justify-content-center p-5">
                    <MoonLoader loading={true} />
                  </div>
                )}
                {data?.result?.rows.length === 0 && (
                  <div className="empty d-flex justify-content-center p-4 mt-2">
                    <div className="empty-img">
                      <img src="/svg/empty.svg" height="128" alt="" />
                    </div>
                    <p className="empty-title">
                      {isFiltered ? `No results found` : "Nothing's here"}
                    </p>
                    <p className="empty-subtitle text-muted">
                      {isFiltered
                        ? `Upon querying no data has found matching the filters."`
                        : `No records exist`}
                    </p>
                  </div>
                )}
                {data?.result?.rows.map((log) => (
                  <LogItem log={log} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function LogItem({ log }: { log: Record<string, any> }) {
  const [showAll, setShowAll] = useState(false);

  return (
    <div
      className="list-group-item cursor-pointer list-group-item-action"
      onClick={(e) => setShowAll(!showAll)}
    >
      <div className="row">
        <div className="col-auto">
          <span
            className="avatar"
            style={{
              backgroundColor: "var(--tblr-red)",
              color: "white",
            }}
          >
            E
          </span>
        </div>
        <div className="col">
          <a className="text-body d-block">
            {log.service}{" "}
            <pre className="d-inline p-0 m-0 border-0 bg-white font-monospace">
              (@{log.functionName})
            </pre>
          </a>
          <pre
            className={`p-0 m-0 border-0 bg-white d-block text-black-50 font-monospace ${
              showAll ? "" : "text-truncate"
            }`}
            style={{ maxWidth: "1000px" }}
          >
            {log.message}
          </pre>
          <span className="badge bg-secondary mt-1">
            {moment(log.createdAt).fromNow()}
          </span>
          <span className="badge bg-primary mt-1 ms-1">{log.machineIP}</span>
        </div>
        <div className="col-auto">
          <a className="list-group-item-actions text-decoration-none">
            <Icon path={!showAll ? mdiChevronDown : mdiChevronUp} size={1} />
          </a>
        </div>
      </div>
    </div>
  );
}
