import { useState, useEffect, Fragment } from "react";
import { DefaultValues, UnpackNestedValue, useForm } from "react-hook-form";
import { FieldRecord } from "../../interfaces/DataTypes";
import { CheckBoxWithController } from "../FormElements/OneStateInputs";
import CustomModal from "./Modal";

interface FieldSelectProps<T> {
  selectedFields: DefaultValues<FieldRecord<T, boolean>>;
  onFieldsSelectionChange: (
    e: UnpackNestedValue<FieldRecord<T, boolean>>
  ) => void;
}

const FieldSelector = <T extends object>({
  selectedFields,
  onFieldsSelectionChange,
}: FieldSelectProps<T>) => {
  const [show, setShow] = useState(false);
  const { control, getValues, reset } = useForm({
    defaultValues: selectedFields,
  });

  useEffect(() => {
    reset(selectedFields);
  }, [selectedFields]);

  return (
    <Fragment>
      <button
        className="btn"
        onClick={() => {
          setShow(true);
        }}
      >
        Fields
      </button>
      <CustomModal
        onHide={() => setShow(false)}
        show={show}
        onPrimaryButtonClick={() => {
          const selectedValues = getValues();
          const filterdValues: Record<string, any> = {};

          for (const [key, value] of Object.entries(selectedValues)) {
            if (typeof value !== "object" && !key.includes(".")) {
              filterdValues[key] = value;
            } else {
              const childKeys = Object.keys(value);
              for (const each of childKeys) {
                filterdValues[`${key}.${each}`] = value[each];
              }
            }
          }

          console.log({ filterdValues });

          onFieldsSelectionChange(filterdValues as any);
          setShow(false);
        }}
        size="sm"
        title="Select Fields to Display"
        primaryButtonText="Okay"
      >
        <div className="m-3">
          {Object.keys(selectedFields).map((e) => {
            return (
              <div className="mb-1">
                <span className="me-1">
                  <CheckBoxWithController
                    control={control}
                    name={e as any}
                    useCheckedStateAsValue
                  />
                </span>
                <label className="ms-1">{e}</label>
              </div>
            );
          })}
        </div>
      </CustomModal>
    </Fragment>
  );
};

export default FieldSelector;
