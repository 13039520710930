import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import React, { FunctionComponent } from "react";

import { AsnAttributes } from "common";
import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import ControlledInput from "../../components/FormElements/ControlledInput";
import { Controller } from "react-hook-form";
import { CountryAttributes } from "common";
import DatePickerCustom from "../../components/FormElements/DatePickerCustom";
import EditPage from "../../components/EditPage";
import { IPInfoAttributes } from "common";
import { IspAttributes } from "common";

type IPInfoFormAttributes = IPInfoAttributes & {
  intPkCountry: CountryAttributes;
  intPkAsn: AsnAttributes;
  intPkIsp: IspAttributes;
};

const IPInfoPage: FunctionComponent = () => {
  return (
    <EditPage<IPInfoFormAttributes>
      modelName="ipInfo"
      title="IP Info"
      showCreateButton={false}
      fields={[
        "intPkIpInfoID",
        "subnet",
        "intPkCountry.countryName",
        "intPkAsn.ASN",
        { key: "datetime", type: "date" },
        { key: "enable", type: "boolean" },
      ]}
      idField="intPkIpInfoID"
      nameField="subnet"
      renderForm={({ register, control, errors }) => (
        <div className="m-3">
          <div className="d-flex flex-row mb-3">
            <ControlledInput
              register={register("subnet", { required: true })}
              errorMessage={errors.subnet ? "Required" : ""}
              title="Subnet"
              readOnly={true}
            />
            <div className="form-group ms-3">
              <label className="form-label">DateTime</label>
              <Controller
                name="datetime"
                control={control}
                render={({ field: { name, onBlur, onChange, value } }) => {
                  return (
                    <DatePickerCustom
                      {...{ name, onBlur, value, onChange }}
                      disabled={true}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="form-group mb-3 flex-fill ">
            <label className="form-label">Country</label>
            <AsyncSelectWithController<IPInfoFormAttributes, CountryAttributes>
              control={control}
              valueFieldName="intPkCountryID"
              imageField="flagImageUrl"
              readOnly={true}
              searchField="countryName"
              model="country"
            />
          </div>
          <div className="form-group mb-3 flex-fill ">
            <label className="form-label">Isp</label>
            <AsyncSelectWithController<IPInfoFormAttributes, IspAttributes>
              control={control}
              valueFieldName="intPkIspID"
              readOnly={true}
              searchField="ispName"
              imageField="ispLogoUrl"
              model="country"
            />
          </div>
          <div className="form-group mb-3 flex-fill ">
            <label className="form-label">ASN</label>
            <AsyncSelectWithController<IPInfoFormAttributes, AsnAttributes>
              control={control}
              valueFieldName="intPkAsnID"
              searchField="ASN"
              readOnly={true}
              model="asn"
            />
          </div>
          <CheckboxTileGroup>
            <CheckboxTile name="enable" control={control} title="Enable" />
          </CheckboxTileGroup>
        </div>
      )}
    />
  );
};

export default IPInfoPage;
