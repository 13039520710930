import {
  DataCenterModel,
  VendorAttributes,
  VezSockNodeAttributes,
  VezSockNodeModel,
} from "common";
import { useFormContext } from "react-hook-form";
import EditPage from "../../components/EditPage";
import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import Input from "../../components/FormElements/ControlledInput";
import { RadioSelectWithController } from "../../components/FormElements/RadioSelect";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";

export const VezSockNodePage: React.FunctionComponent = () => {
  return (
    <EditPage<VezSockNodeAttributes, VezSockNodeModel>
      modelName="vezSockNode"
      nameField="vezSockNodeName"
      idField="intPkVezSockNodeID"
      title="VezSock Nodes"
      defaultValues={{
        enable: 1,
      }}
      fields={[
        "vezSockNodeName",
        "vezSockIP",
        "vezSockPort",
        "vezSockDomain",
        "userName",
        "intPkDataCenter.dataCenterName",
      ]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <VezSockNodeForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const VezSockNodeForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<VezSockNodeAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<VezSockNodeAttributes>();

  return (
    <div className="m-3">
      <Input
        register={register("vezSockNodeName", {
          required: "Required",
        })}
        readOnly={readOnly}
        className="mb-3"
        title="Node Name"
        errorMessage={errors.vezSockNodeName?.message}
      />
      <div className="flex-fill row gap-1">
        <div className="col">
          <Input
            register={register("vezSockIP", {
              required: "Required",
            })}
            readOnly={readOnly}
            className="mb-3"
            title="IP"
            errorMessage={errors.vezSockIP?.message}
          />
        </div>
        <div className="col">
          <Input
            register={register("vezSockPort", {
              required: "Required",
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Port"
            type="number"
            errorMessage={errors.vezSockPort?.message}
          />
        </div>
        <div className="col">
          <Input
            register={register("vezSockDomain", {
              required: "Required",
              validate: (value) => {
                if (!value.match(/^(\w+\.)+\w+$/)) {
                  return "Please write in format: domain.com";
                }
              },
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Domain"
            errorMessage={errors.vezSockDomain?.message}
          />
        </div>
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">DataCenter</label>
        <AsyncSelectWithController<VezSockNodeAttributes, DataCenterModel>
          control={control}
          valueFieldName="intPkDataCenterID"
          searchField="dataCenterName"
          readOnly={readOnly}
          imageField="intPkCountry.flagImageUrl"
          model="dataCenter"
        />
      </div>
      <div className="flex-fill row gap-1">
        <div className="col">
          <Input
            register={register("userName", {
              required: "Required",
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Username"
            errorMessage={errors.userName?.message}
          />
        </div>
        <div className="col">
          <Input
            register={register("password", {
              required: "Required",
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Password"
            errorMessage={errors.password?.message}
          />
        </div>
      </div>

      <CheckboxTileGroup>
        <CheckboxTile control={control} name="enable" title="Enable" />
      </CheckboxTileGroup>
    </div>
  );
};

export const VezSockNodeFilter = () => {
  const methods = useFormContext();
  const [mainValue, intPkVendorID, intPkCountryID] = methods.watch([
    "or._0.vezSockNodeName",
    "vendorFilter.intPkVendorID",
    "countryFilter.intPkCountryID",
  ]);

  return (
    <div className="m-3">
      <Input
        register={methods.register("or._0.vezSockNodeName")}
        title="Search by IP, Name, Domain"
        className="mb-3"
      />
      <Input
        register={methods.register("or._1.vezSockIP", { value: mainValue })}
        title=""
        type="hidden"
        readOnly
      />
      <Input
        register={methods.register("or._2.vezSockDomain", { value: mainValue })}
        title=""
        type="hidden"
        readOnly
      />
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Vendor</label>
        <AsyncSelectWithController<any, VendorAttributes>
          searchField="vendorName"
          model="vendor"
          control={methods.control}
          valueFieldName="vendorFilter.intPkVendorID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Country</label>
        <AsyncSelectWithController<any, any>
          searchField="countryName"
          model="country"
          imageField="flagImageUrl"
          filter={{ vendorFilter: { intPkVendorID } }}
          control={methods.control}
          valueFieldName="countryFilter.intPkCountryID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Data Center</label>
        <AsyncSelectWithController<any, DataCenterModel>
          searchField="dataCenterName"
          model="dataCenter"
          imageField="intPkCountry.flagImageUrl"
          filter={{
            intPkVendorID,
            intPkCountryID,
          }}
          control={methods.control}
          valueFieldName="intPkDataCenterID"
        />
      </div>
      <RadioSelectWithController
        control={methods.control}
        name="enable"
        title="Enable Status"
        options={[
          { label: "All", value: undefined },
          { label: "Enabled", value: 1 },
          { label: "Disabled", value: 0 },
        ]}
      />
    </div>
  );
};
