import "./CheckBoxTile.scss";

import {
  Control,
  FieldPath,
  RegisterOptions,
  useController,
} from "react-hook-form";
import React, { useState } from "react";

import { useEffect } from "react";

interface CheckboxTileProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
  title: string;
  readOnly?: boolean;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  defaultChecked?: boolean;
  errorMessage?: string;
  disableable?: boolean;
  defaultDisabled?: boolean;
}

/*
 * Don't add ref
 *
 *
 */
export default function CheckboxTile<T>({
  title,
  control,
  name,
  rules,
  errorMessage,
  defaultChecked = false,
  readOnly = false,
  disableable = false,
  defaultDisabled = false,
}: CheckboxTileProps<T>) {
  const [disabled, setDisabled] = useState(false);
  const [valueBeforeDisabled, setValueBeforeDisabled] = useState<number>();

  const {
    field: { value, onChange, onBlur },
  } = useController({
    control,
    rules,
    name,
  });

  useEffect(() => {
    if (disableable && value === undefined) {
      setDisabled(defaultDisabled);
    }
  }, [disableable]);

  useEffect(() => {
    if (disabled) {
      control.unregister(name);
      setValueBeforeDisabled(value as number);
      onChange(undefined);
    } else {
      control.register(name);
      if (valueBeforeDisabled !== undefined) {
        onChange(valueBeforeDisabled);
        setValueBeforeDisabled(undefined);
      }
    }
  }, [disabled]);

  useEffect(() => {
    if (value === undefined && !disabled) {
      onChange(defaultChecked ? 1 : 0);
    }
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <label className="row">
          {disableable && (
            <div className="col-1 ">
              <input
                className="form-check-input"
                onChange={(e) => setDisabled(!e.currentTarget.checked)}
                type="checkbox"
                checked={!disabled}
              />
            </div>
          )}
          <span className="col">{title}</span>
          <span className="col-auto">
            <label className="form-check form-check-single form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                onBlur={onBlur}
                name={name}
                onChange={(e) => onChange(e.currentTarget.checked ? 1 : 0)}
                disabled={readOnly || disabled}
                checked={!!value}
              />
            </label>
          </span>
        </label>
        <p className="checkbox-invalid-feedback">{errorMessage}</p>
      </div>
    </div>
  );
}

export function CheckboxTileGroup({ children }: React.PropsWithChildren<{}>) {
  return <div className="checkbox-tile-group">{children}</div>;
}
