import { AlertProviderProps, positions, transitions } from "react-alert";
import {
  mdiAccount,
  mdiAlertCircle,
  mdiAndroid,
  mdiAppleIos,
  mdiApplication,
  mdiAppsBox,
  mdiCellphone,
  mdiCheckCircle,
  mdiCloseCircle,
  mdiCodeJson,
  mdiCropPortrait,
  mdiDatabase,
  mdiDomain,
  mdiIpNetwork,
  mdiLinux,
  mdiMicrosoftWindows,
  mdiNetwork,
  mdiNotebook,
  mdiPipe,
  mdiPowerSocketFr,
  mdiRouterNetwork,
  mdiServer,
  mdiServerNetwork,
  mdiSpiderWeb,
  mdiSync,
  mdiViewDashboard,
  mdiWeb,
} from "@mdi/js";

import { AppsPage } from "./pages/Partials/Apps";
import AsnPage from "./pages/Partials/Asn";
import BrandsPage from "./pages/Partials/Brands";
import BrandsStatusPage from "./pages/Partials/BrandsStatus";
import CountriesPage from "./pages/Partials/CountryPage";
import DataCentersPage from "./pages/Partials/DataCenters";
import DevicePlaftormPage from "./pages/Partials/DevicePlatform";
import DevicesPage from "./pages/Partials/Devices";
import IPInfoLogsPage from "./pages/Partials/IPInfoLogs";
import IPInfoPage from "./pages/Partials/IPInfo";
import ISPPage from "./pages/Partials/ISP";
import JsonConfigPage from "./pages/Partials/JsonConfigPage";
import { NodeIPsPage } from "./pages/Partials/NodeIPs";
import NodesPage from "./pages/Partials/Nodes";
import PoolStatusPage from "./pages/Partials/PoolStatus";
import PoolsPage from "./pages/Partials/Pools";
import ProfilesPage from "./pages/Partials/Profiles";
import RolesPage from "./pages/Partials/Roles";
import SplitTunnelProfilePage from "./pages/Partials/SplitTunnelProfiles";
import UsersPage from "./pages/Partials/Users";
import VendorsPage from "./pages/Partials/Vendors";
import VezgateProxyProfilePage from "./pages/Partials/VezgateProxyProfile";
import React, { Fragment } from "react";
import CurrentConnectedSessionPage from "./pages/Partials/CurrentConnectedSession";
import NodeLegSessionPage from "./pages/Partials/NodeLegSession";
import { CurrentConnectedSessionModel, NodeLegSessionModel } from "common";
import { FieldRecord } from "./interfaces/DataTypes";
import Icon from "@mdi/react";
import { FieldPath } from "react-hook-form";
import { FieldInfo } from "./components/EditPage";
import { GoogleIAPProductsPage } from "./pages/Partials/GoogleIAPProducts";
import { GoogleIAPSubscriptionsPage } from "./pages/Partials/GoogleIAPSubscriptions";
import { GoogleServiceAccountsPage } from "./pages/Partials/GoogleServiceAccounts";
import { GoogleIAPSubscriptionsChildPage } from "./pages/Partials/GoogleIAPSubscriptionChildren";
import { ProxyNodesPage } from "./pages/Partials/ProxyNodes";
import { ProxyListPage } from "./pages/Partials/ProxyList";
import { VezSockNodePage } from "./pages/Partials/VezSockNode";
import { VezSockListPage } from "./pages/Partials/VezSockList";
import { VezSockProfilePage } from "./pages/Partials/VezSockProfile";
import { LogsPage } from "./pages/Partials/Logs";
import { VezSSNodePage } from "./pages/Partials/VezSSNodes";
import { VezSSListPage } from "./pages/Partials/VezSSList";
import { StatusPage } from "./pages/Partials/Status";
import { VezSSPasswordsPage } from "./pages/Partials/VezSSPassword";
import { VtbDashboard } from "./pages/Partials/VTBDashboard";
import { NodesDashboard } from "./pages/Partials/NodesDashboard";

export interface NavLinkCProps {
  title: string;
  icon?: string;
  url: string;
  secondaryTitle?: string;
  children?: NavLinkCProps[];
  pageComponent: React.FC;
  modelName: ModelName;
  forceHideForNoc?: boolean;
}

export const successColor = "var(--tblr-green)";
export const warningColor = "var(--tblr-yellow)";
export const errorColor = "var(--tblr-red)";

export const NodeStatusMap: Record<
  string,
  { color: string; text: string; icon: string }
> = {
  "service-active": {
    color: successColor,
    text: "Active",
    icon: mdiCheckCircle,
  }, // active
  "service-inactive": {
    color: errorColor,
    text: "Failed",
    icon: mdiCloseCircle,
  }, // failed
  authentication_failed: {
    color: warningColor,
    text: "Auth Failed",
    icon: mdiAlertCircle,
  }, // auth failed
  "service-not-found": {
    color: errorColor,
    text: "Not Installed",
    icon: mdiAlertCircle,
  },
  error: {
    color: errorColor,
    text: "Error",
    icon: mdiCloseCircle,
  },
  not_configured: {
    color: warningColor,
    text: "No Config",
    icon: mdiAlertCircle,
  },
  timed_out: {
    color: warningColor,
    text: "Timeout",
    icon: mdiAlertCircle,
  },
  "service-failed": {
    color: errorColor,
    text: "Failed",
    icon: mdiAlertCircle,
  },
  connection_failed: {
    color: errorColor,
    text: "Failed",
    icon: mdiCloseCircle,
  },
};

export const navLinks: NavLinkCProps[] = [
  {
    title: "Dashboard",
    modelName: "dashboard",
    icon: mdiViewDashboard,
    secondaryTitle: "VPN Nodes",
    url: "",
    pageComponent: NodesDashboard,
    children: [
      {
        title: "Proxies",
        modelName: "status",
        pageComponent: StatusPage,
        url: "/status",
      },
      {
        title: "VTB",
        modelName: "vtbDashboard",
        pageComponent: VtbDashboard,
        url: "/vtb",
      },
    ],
  },
  {
    title: "Vendors",
    icon: mdiDomain,
    url: "/vendors",
    modelName: "vendor",
    pageComponent: VendorsPage,
  },
  {
    title: "Data Centers",
    icon: mdiDatabase,
    modelName: "dataCenter",
    url: "/datacenters",
    pageComponent: DataCentersPage,
  },
  {
    title: "Pools",
    icon: mdiServerNetwork,
    url: "/pools",
    modelName: "pool",
    pageComponent: PoolsPage,
    children: [
      {
        title: "Pool Status",
        url: "/status",
        modelName: "poolStatus",
        pageComponent: PoolStatusPage,
      },
      {
        title: "Profiles",
        url: "/profiles",
        modelName: "poolProfile",
        pageComponent: ProfilesPage,
      },
    ],
  },
  {
    title: "Brands",
    icon: mdiApplication,
    url: "/brands",
    modelName: "brand",
    pageComponent: BrandsPage,
    children: [
      {
        title: "Brands Status",
        url: "/status",
        modelName: "brandStatus",
        pageComponent: BrandsStatusPage,
      },
    ],
  },
  {
    title: "Logs",
    icon: mdiNotebook,
    url: "/logs",
    modelName: "log",
    pageComponent: LogsPage,
  },

  {
    title: "Nodes",
    icon: mdiServer,
    url: "/nodes",
    modelName: "node",
    pageComponent: NodesPage,
    children: [
      {
        title: "IPs",
        url: "/ip",
        modelName: "nodeIP",
        pageComponent: NodeIPsPage,
      },
    ],
  },
  {
    title: "Users",
    icon: mdiAccount,
    url: "/users",
    modelName: "user",
    pageComponent: UsersPage,
    children: [
      {
        title: "Roles",
        url: "/roles",
        modelName: "role",
        pageComponent: RolesPage,
      },
    ],
  },
  {
    title: "Apps",
    icon: mdiAppsBox,
    url: "/apps",
    modelName: "app",
    pageComponent: AppsPage,
  },
  {
    title: "Devices",
    icon: mdiCellphone,
    url: "/devices",
    pageComponent: DevicesPage,
    modelName: "device",
    children: [
      {
        title: "Platforms",
        url: "/platform",
        modelName: "devicePlatform",
        pageComponent: DevicePlaftormPage,
      },
    ],
  },
  {
    title: "Split Tunnel Config",
    icon: mdiNetwork,
    modelName: "splitTunnelProfile",
    url: "/splitTunnels",
    pageComponent: SplitTunnelProfilePage,
  },
  {
    title: "Configs",
    icon: mdiCodeJson,
    url: "/configs",
    modelName: "jsonConfig",
    pageComponent: JsonConfigPage,
  },
  {
    title: "User Sessions",
    icon: mdiCropPortrait,
    modelName: "nodeLegSession",
    url: "/nodeLegSessions",
    secondaryTitle: "Node SDRs",
    pageComponent: NodeLegSessionPage,
    children: [
      {
        title: "Current Sessions",
        url: "/current",
        modelName: "currentConnectedSession",
        pageComponent: CurrentConnectedSessionPage,
      },
    ],
  },
  {
    title: "Subscriptions",
    icon: mdiSync,
    modelName: "googleIAPSubscription",
    secondaryTitle: "Google Subscriptions",
    url: "/subscriptions",
    pageComponent: GoogleIAPSubscriptionsPage,
    children: [
      {
        title: "Google Products",
        modelName: "googleIAPProduct",
        url: "/products",
        pageComponent: GoogleIAPProductsPage,
      },
      {
        title: "Google Service Accounts",
        modelName: "googleServiceAccount",
        url: "/serviceAccounts",
        pageComponent: GoogleServiceAccountsPage,
      },
      {
        title: "Google Receipts",
        modelName: "googleIAPSubscriptionChild",
        url: "/children",
        pageComponent: GoogleIAPSubscriptionsChildPage,
      },
    ],
  },
  {
    title: "Proxy Gates",
    icon: mdiRouterNetwork,
    url: "/proxygates",
    modelName: "vezgateProxyProfile",
    secondaryTitle: "Proxy Profiles",
    pageComponent: VezgateProxyProfilePage,
    children: [
      {
        title: "Proxy Nodes",
        modelName: "proxyNode",
        url: "/nodes",
        forceHideForNoc: true,
        pageComponent: ProxyNodesPage,
      },
      {
        title: "Proxy List",
        modelName: "proxyList",
        url: "/list",
        pageComponent: ProxyListPage,
      },
    ],
  },
  {
    title: "VezSock",
    icon: mdiPowerSocketFr,
    url: "/sock",
    modelName: "vezgateProxyProfile",
    secondaryTitle: "VezSock Profiles",
    pageComponent: VezSockProfilePage,
    children: [
      {
        title: "VezSock Nodes",
        modelName: "vezSockNode",
        url: "/nodes",
        pageComponent: VezSockNodePage,
      },
      {
        title: "VezSock List",
        modelName: "vezSockList",
        url: "/list",
        pageComponent: VezSockListPage,
      },
    ],
  },
  {
    title: "VezSS",
    icon: mdiPipe,
    url: "/ss",
    modelName: "vezSsList",
    secondaryTitle: "NodeList",
    pageComponent: VezSSListPage,
    children: [
      {
        title: "Nodes",
        modelName: "vezSsNode",
        url: "/nodes",
        pageComponent: VezSSNodePage,
      },
      {
        title: "Passwords",
        modelName: "vezSsPassword",
        url: "/password",
        pageComponent: VezSSPasswordsPage,
      },
    ],
  },
  {
    title: "IPInfo",
    icon: mdiIpNetwork,
    url: "/ipinfo",
    modelName: "ipInfo",
    pageComponent: IPInfoPage,
    children: [
      {
        title: "Logs",
        url: "/logs",
        modelName: "ipInfoLog",
        pageComponent: IPInfoLogsPage,
      },
    ],
  },
  {
    title: "Countries",
    icon: mdiWeb,
    modelName: "country",
    url: "/countries",
    pageComponent: CountriesPage,
  },
  {
    title: "ISPs",
    modelName: "isp",
    icon: mdiSpiderWeb,
    url: "/isps",
    pageComponent: ISPPage,
    children: [
      { title: "ASNs", url: "/asns", modelName: "asn", pageComponent: AsnPage },
    ],
  },
];

export const alertOptions: Partial<AlertProviderProps> = {
  timeout: 4000,
  offset: "20px",
  position: positions.BOTTOM_CENTER,
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 1070,
  },
};

export const optionsForStatus = [
  { label: "Free", value: 1 },
  { label: "Trial", value: 2 },
  { label: "Premium", value: 3 },
];

export const booleanOptions = [
  { label: "False", value: 0 },
  { label: "True", value: 1 },
];

export type ModelName =
  | "country"
  | "jsonConfig"
  | "brand"
  | "pool"
  | "poolProfile"
  | "asn"
  | "nodeEditHistory"
  | "node"
  | "poolStatus"
  | "vendor"
  | "device"
  | "dataCenter"
  | "user"
  | "brandStatus"
  | "ipInfo"
  | "ipInfoLog"
  | "devicePlatform"
  | "isp"
  | "role"
  | "rolePermission"
  | "crudTable"
  | "poolM2MNode"
  | "nodeM2MPlatform"
  | "nodeIP"
  | "app"
  | "appCategory"
  | "appM2MCategory"
  | "splitTunnelProfile"
  | "splitTunnelConfigValue"
  | "requestKey"
  | "appLegSession"
  | "jsonConfigValue"
  | "vezgateProxyProfile"
  | "nodeType"
  | "currentConnectedSession"
  | "nodeLegSession"
  | "userPreference"
  | "googleIAPProduct"
  | "googleIAPSubscription"
  | "googleServiceAccount"
  | "deviceM2MGoogleIAPSubsciption"
  | "googleIAPSubscriptionChild"
  | "proxyNode"
  | "proxyList"
  | "vezgateProxyM2MProxyList"
  | "proxyNodeM2MProxyList"
  | "vezSockListM2MVezSockNode"
  | "vezSockList"
  | "vezSockNode"
  | "vezSockProfileM2MVezSockList"
  | "vezSockProfile"
  | "log"
  | "vezSsList"
  | "vezSsNode"
  | "vezSsListM2MVezSsNode"
  | "status"
  | "vezSsPassword"
  | "vezSsNodeDeleted"
  | "actionStatus"
  | "dashboard"
  | "vtbDashboard";

export const connectSessionDefaultShownFields: FieldRecord<
  CurrentConnectedSessionModel,
  boolean
> = {
  Brand: true,
  intPkNodeLegSessionID: true,
  "Device Platform": true,
  deviceID: false,
  intPkDeviceID: false,
  intPkPoolProfileID: false,
  nodeIP: false,
  intPkNodeID: false,
  Vendor: true,
  "Data Center": true,
  username: false,
  sessionEpochMS: false,
  userRequestSourceIPv4: false,
  "Country Flag": true,
  Country: false,
  "intPkAsn.ASN": false,
  "ISP Logo": true,
  ISP: true,
  serverSessionID: true,
  sessionConnectTime: true,
  "JSON Config": true,
  "Split Tunnel Config": true,
  "Vezgate Proxy Profile": true,
  natInterface: false,
  natIP: false,
  "Node Type": true,
  "Brand Status ID": true,
  estimatedSessionConnectTime: false,
  sessionDisconnectTime: false,
  durationSeconds: false,
  totalBytes: false,
  isTestDevice: false,
};

export type FieldType =
  | "string"
  | "boolean"
  | "date"
  | "number"
  | "image"
  | "switch";

export const platformIconMap: Record<string, any> = {
  Android: mdiAndroid,
  iOS: mdiAppleIos,
  Linux: mdiLinux,
  Windows: mdiMicrosoftWindows,
};

export const connectSessionFields: (
  | FieldPath<CurrentConnectedSessionModel & NodeLegSessionModel>
  | FieldInfo<CurrentConnectedSessionModel & NodeLegSessionModel>
)[] = [
  { key: "intPkBrand.brandName", name: "Brand", type: "string" },
  "intPkNodeLegSessionID",
  {
    name: "Device Platform",
    value: (z) => (
      <Fragment>
        {z.intPkDevicePlatform?.platform ? (
          <span>
            <Icon
              path={platformIconMap[z.intPkDevicePlatform?.platform]}
              size={1.1}
              className="me-1"
            />
            {z.intPkDevicePlatform?.platform}
          </span>
        ) : null}
      </Fragment>
    ),
  },
  "deviceID",
  "intPkDeviceID",
  "intPkPoolProfileID",
  "nodeIP",
  "intPkNodeID",
  { key: "intPkVendor.vendorName", type: "string", name: "Vendor" },
  {
    key: "intPkDataCenter.dataCenterName",
    type: "string",
    name: "Data Center",
  },
  "username",
  "sessionEpochMS",
  "userRequestSourceIPv4",
  {
    key: "intPkCountry.flagImageUrl",
    type: "image",
    name: "Country Flag",
  },
  { key: "intPkCountry.countryName", name: "Country", type: "string" },
  "intPkAsn.ASN",
  "durationSeconds",
  "totalBytes",
  "isTestDevice",
  {
    key: "intPkIsp.ispLogoUrl",
    type: "image",
    name: "ISP Logo",
  },
  {
    key: "intPkIsp.ispName",
    type: "string",
    name: "ISP",
  },
  "serverSessionID",
  { key: "sessionConnectTime", type: "date" },
  { key: "estimatedSessionConnectTime", type: "date" },
  { key: "sessionDisconnectTime", type: "date" },
  { key: "intPkConfig.configName", type: "string", name: "JSON Config" },
  {
    key: "intPkSplitTunnel.configName",
    name: "Split Tunnel Config",
    type: "string",
  },
  {
    key: "intPkVezgateProxyProfile.profileName",
    name: "Vezgate Proxy Profile",
    type: "string",
  },
  "natInterface",
  "natIP",
  { key: "intPkNodeType.type", name: "Node Type", type: "string" },
  {
    key: "intPkBrandsStatus.intPkBrandsStatusID",
    name: "Brand Status ID",
    type: "string",
  },
];
