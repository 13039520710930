import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import React, { FunctionComponent } from "react";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import { CountryAttributes } from "common";
import EditPage from "../../components/EditPage";
import Input from "../../components/FormElements/ControlledInput";
import { IspAttributes } from "common";

type IspAllAttributes = IspAttributes & {
  intPkCountry: CountryAttributes;
};

const ISPPage: FunctionComponent = () => {
  return (
    <EditPage<IspAttributes, IspAllAttributes>
      modelName="isp"
      fields={[
        "intPkIspID",
        { type: "image", key: "ispLogoUrl" },
        "ispName",
        "intPkCountry.countryName",
        "ispDomain",
        { key: "enable", type: "boolean" },
      ]}
      title="ISP"
      idField="intPkIspID"
      defaultValues={{ enable: 1 }}
      nameField="ispName"
      renderForm={({
        register,
        errors,
        control,
        readOnly,
        model,
      }) => (
        <div className="m-3">
          <div className="d-flex flex-row mb-3">
            <Input
              register={register("ispName", { required: "Required" })}
              title="ISP Name"
              readOnly={readOnly}
              errorMessage={errors.ispName?.message}
            />
            <Input
              register={register("ispDomain", { required: "Required" })}
              readOnly={readOnly}
              title="ISP Domain"
              className="ms-3"
              errorMessage={errors.ispDomain?.message}
            />
            <Input
              register={register("ispLogoUrl", { required: "Required" })}
              readOnly={readOnly}
              title="ISP Logo URL"
              className="ms-3"
              errorMessage={errors.ispLogoUrl?.message}
            />
          </div>
          <div className="form-group flex-fill mb-3">
            <label className="form-label">Country</label>
            <AsyncSelectWithController<IspAttributes, CountryAttributes>
              control={control}
              readOnly={readOnly}
              searchField="countryName"
              valueFieldName="intPkCountryID"
              imageField="flagImageUrl"
              rules={{ required: "Required" }}
              model="country"
            />
          </div>
          <CheckboxTileGroup>
            <CheckboxTile
              name="enable"
              control={control}
              title="Enable"
              readOnly={readOnly}
            />
          </CheckboxTileGroup>
        </div>
      )}
    />
  );
};

export default ISPPage;
