import { UseQueryOptions, useQuery } from "react-query";
import axios, { AxiosError } from "axios";

import { ListResponse } from "../../interfaces/ListResponse";
import { ModelName } from "../../constants";
import { ServerResponse } from "common";
import useFetcher from "./useFetcher";
import { usePermissions } from "./usePermissions";

interface UseAuthorizedQueryProps<T> {
  model: ModelName;
  filter?: Partial<T>;
  order?: Record<keyof Partial<T> | any, "asc" | "desc">;
  limit?: number;
  offset?: number;
  queryOptions?: Omit<UseQueryOptions<ServerResponse<ListResponse<T>>, AxiosError>, "enabled">;
  enabled?: boolean;
  url?: string;
}

export default function useAuthorizedQuery<T>({
  model,
  limit = 10,
  enabled = true,
  offset = 0,
  filter,
  queryOptions,
  order,
  url,
}: UseAuthorizedQueryProps<T>) {
  const permissions = usePermissions(model);
  const queryFetcher = useFetcher({ invalidateCache: false, alerts: false });
  const stringOrder = JSON.stringify(order);

  const queryKey = [
    url ? url : `${model}data`,
    filter,
    offset,
    limit,
    stringOrder,
  ].filter((e) => e !== null);

  const methods = useQuery<ServerResponse<ListResponse<T>>, AxiosError>(
    queryKey,
    () => {
      // to the cancel the previous request.
      const source = axios.CancelToken.source();

      let params: Record<string, any> = {
        limit,
        offset,
      };

      if (filter) {
        params = { ...params, ...filter };
      }

      if (order) {
        params = { ...params, order: order ? stringOrder : undefined };
      }

      const promise = queryFetcher<ServerResponse<ListResponse<T>>>({
        method: "get",
        url: url ? url : `/api/v1/data/${model}/list`,
        options: {
          params,
          cancelToken: source.token,
        },
      });

      // @ts-ignore Cancel is invalid, but react-query uses it.
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };

      return promise;
    },
    {
      enabled: enabled && permissions.allowRead,
      ...queryOptions,
    }
  );

  return { ...methods, queryKey };
}
