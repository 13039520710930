import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import { FunctionComponent, useEffect, useMemo, useState } from "react";

import { BrandAttributes } from "common";
import ControlledInput from "../../components/FormElements/ControlledInput";
import { Controller, useFormContext } from "react-hook-form";
import DatePickerCustom from "../../components/FormElements/DatePickerCustom";
import { DeviceAttributes } from "common";
import { DevicePlatformAttributes } from "common";
import EditPage from "../../components/EditPage";
import Select from "react-select";
import { optionsForStatus } from "../../constants";
import Input from "../../components/FormElements/ControlledInput";
import { CheckBoxWithController } from "../../components/FormElements/OneStateInputs";
import { FilterPicker } from "../../components/FilterPicker";
import RadioSelect from "../../components/FormElements/RadioSelect";
// @ts-ignore
import isValidIP4 from "umartoolbox/isValidIP4";
import { useIsNoc } from "../../libs/hooks/useIsNoc";

type DeviceFormAttributes = DeviceAttributes & {
  intPkBrand: BrandAttributes;
  intPkDevicePlatform: DevicePlatformAttributes;
};

const DevicesPage: FunctionComponent = () => {
  const [filter, setFilter] = useState<any>();
  const isNoc = useIsNoc();

  return (
    <EditPage<DeviceFormAttributes>
      modelName="device"
      idField="intPkDeviceID"
      nameField="deviceID"
      fields={[
        "intPkDeviceID",
        "deviceID",
        "deviceType",
        "intPkBrand.brandName",
        "intPkDevicePlatform.platform",
        "deviceVersion",
        "appVersion",
        { key: "registrationDate", type: "date" },
        { key: "expiryDate", type: "date" },
        "serverIP",
        "serverPort",
        "isFreeOrTrialOrPremium",
        { key: "isDeviceConsumedTrial", type: "boolean" },
        { key: "isTestDevice", type: "boolean" },
        "testDeviceName",
        { key: "enable", type: "boolean" },
      ]}
      onEditHook={async (prev, data) => {
        if (data.serverIP && !data.serverPort) {
          data.serverPort = "443";
        }

        if (data.ssServerIP && !data.ssServerSecurity) {
          data.ssServerSecurity = "aes-256-gcm";
        }

        if (data.isSSEnforced && !prev.ssServerIP && !data.ssServerIP) {
          data.isSSEnforced = 0;
        }

        if (data.ssServerIP === "") {
          data.ssServerIP = null;
        }

        if (prev.ssServerPort !== null && data.ssServerPort === -1) {
          data.ssServerPort = null;
        }

        if (data.ssServerSecurity === "") {
          data.ssServerSecurity = null;
        }

        if (data.ssServerPassword === "") {
          data.ssServerPassword = null;
        }

        if (data.serverIP === "") {
          data.serverIP = null;
        }

        if (data.serverPort === "") {
          data.serverPort = null;
        }

        return true;
      }}
      filter={{ ...filter, isTestDevice: isNoc ? { equals: 1 } : filter?.isTestDevice }}
      renderCardHeader={() => (
        <FilterPicker onFilter={setFilter} filter={filter}>
          <DeviceFilter />
        </FilterPicker>
      )}
      renderForm={({ register, errors, control, watch, readOnly, model }) => (
        <DeviceForm readOnly={readOnly} model={model} />
      )}
    />
  );
};

const DeviceForm = ({ model, readOnly }: any) => {
  const {
    formState: { errors },
    control,
    watch,
    setValue,
    register,
  } = useFormContext<DeviceFormAttributes>();

  const routingType = useMemo(() => {
    const values = model;
    if (!values) return "none";
    if (values.ssServerIP && values.isSSEnforced) return "ss";
    if (values.serverIP) return "cisco";
    return "none";
  }, [model]);
  const [mounted, setMounted] = useState(false);
  const [routingState, setRoutingState] = useState<any>(routingType);

  useEffect(() => {
    if (mounted) {
      console.log("running");
      switch (routingState) {
        case "ss":
          setValue("isSSEnforced", 1);
          break;
        case "cisco":
          setValue("isSSEnforced", 0);
          break;
        case "none":
          setValue("isSSEnforced", 0);
          setValue("ssServerIP", "");
          setValue("ssServerPort", -1);
          setValue("ssServerSecurity", "");
          setValue("ssServerPassword", "");
          setValue("serverIP", "");
          setValue("serverPort", "");
          break;
      }
    }
  }, [routingState]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="m-3">
      <div>
        <b>Device ID: </b>
        {model?.intPkDeviceID}
      </div>
      <div>
        <b>Brand: </b>
        <img
          width={30}
          className="m-1"
          src={model?.intPkBrand?.iconUrl}
          alt={model?.intPkBrand?.brandName}
        />
        {model?.intPkBrand?.brandName}
      </div>
      <div className="mb-3">
        <b>Platform: </b>
        {model?.intPkDevicePlatform?.platform}
      </div>
      <RadioSelect
        name="wellknown"
        onOptionSelect={setRoutingState}
        value={routingState}
        options={[
          { label: "Common Routing", value: "none" },
          { label: "Cisco Routing", value: "cisco" },
          { label: "Shadowsocks Routing", value: "ss" },
        ]}
      />
      {routingState === "cisco" && (
        <div className="d-flex flex-row">
          <ControlledInput
            className="mb-3"
            register={register("serverIP", {
              setValueAs: (value) => value?.trim(),
              validate: (value) => {
                if (!value) return "Required";
                if (!isValidIP4(value)) return "Invalid IP";
                return undefined;
              },
            })}
            errorMessage={errors.serverIP?.message}
            readOnly={readOnly}
            title="Server IP"
          />
          <ControlledInput
            className="mb-3 ms-3"
            register={register("serverPort", {
              setValueAs: (value) => value?.trim(),
              validate: (value) => {
                if (!value) return "Required";
                if (
                  !Number(value) ||
                  Number(value) < 0 ||
                  Number(value) > 65536
                )
                  return "Invalid Port";
                return undefined;
              },
            })}
            errorMessage={errors.serverPort?.message}
            readOnly={readOnly}
            title="Server Port"
          />
        </div>
      )}
      {routingState === "ss" && (
        <>
          <div className="d-flex flex-row">
            <ControlledInput
              className="mb-3"
              register={register("ssServerIP", {
                setValueAs: (value) => value?.trim(),
                validate: (value) => {
                  if (!value) return "Required";
                  if (!isValidIP4(value)) return "Invalid IP";
                  return undefined;
                },
              })}
              errorMessage={errors.ssServerIP?.message}
              readOnly={readOnly}
              title="SS Server IP"
            />
            <ControlledInput
              className="mb-3 ms-3"
              register={register("ssServerPort", {
                validate: (value) => {
                  if (!value) return "Required";
                  if (
                    !Number(value) ||
                    Number(value) < 0 ||
                    Number(value) > 65536
                  )
                    return "Invalid Port";
                  return undefined;
                },
              })}
              errorMessage={errors.ssServerPort?.message}
              readOnly={readOnly}
              title="SS Server Port"
            />
          </div>
          <div className="d-flex flex-row">
            <ControlledInput
              className="mb-3"
              register={register("ssServerPassword", {
                setValueAs: (value) => value?.trim(),
              })}
              errorMessage={errors.ssServerPassword ? "Required" : ""}
              readOnly={readOnly}
              title="SS Server Password"
            />
            <ControlledInput
              className="mb-3 ms-3"
              register={register("ssServerSecurity", {
                setValueAs: (value) => value?.trim(),
              })}
              errorMessage={errors.ssServerSecurity ? "Required" : ""}
              readOnly={readOnly}
              title="SS Server Security "
            />
          </div>
        </>
      )}
      <div className="mb-3">
        <div className="form-group flex-fill">
          <label className="form-label">Expire Date</label>
          <Controller
            name="expiryDate"
            control={control}
            render={({ field: { name, onBlur, onChange, value } }) => (
              <DatePickerCustom
                {...{ name, onBlur, onChange, value }}
                disabled={readOnly}
              />
            )}
          />
        </div>
      </div>
      <div className="form-group flex-fill mb-3">
        <label className="form-label">isFreeOrTrialOrPremium</label>
        <Controller
          name="isFreeOrTrialOrPremium"
          control={control}
          rules={{ required: true }}
          render={({ field: { name, onBlur, onChange, value } }) => (
            <Select
              onChange={(e) => onChange(e?.value)}
              onBlur={onBlur}
              name={name}
              readOnly={readOnly}
              value={optionsForStatus.find((e) => e.value === value)}
              options={optionsForStatus}
            />
          )}
        />
      </div>
      <CheckboxTileGroup>
        <CheckboxTile
          control={control}
          readOnly={readOnly}
          name="isSSEnforced"
          title="is SS Enforced"
        />
        <CheckboxTile
          control={control}
          name="areAdsDisabled"
          readOnly={readOnly}
          title="Are Ads Disabled"
        />
        <CheckboxTile
          control={control}
          name="isDeviceConsumedTrial"
          readOnly={readOnly}
          title="Is Device Consumed True"
        />
        <CheckboxTile
          control={control}
          readOnly={readOnly}
          name="isPinBasedPremiumUser"
          title="Is Pin Based Premium User"
        />

        <CheckboxTile
          control={control}
          readOnly={readOnly}
          name="enable"
          title="Enable"
        />
        <CheckboxTile
          control={control}
          name="isTestDevice"
          readOnly={readOnly}
          title="is Test Device"
        />
      </CheckboxTileGroup>
      {watch("isTestDevice") === 1 && (
        <ControlledInput
          className="mt-3"
          readOnly={readOnly}
          title="Test Device Name"
          register={register("testDeviceName")}
        />
      )}
    </div>
  );
};

export const DeviceFilter = () => {
  const methods = useFormContext();
  const isNoc = useIsNoc();

  const [mainValue] = methods.watch(["or._0.deviceID"]);
  const [fieldsDisabled, setFieldDisabled] = useState(false);

  useEffect(() => {
    methods.setValue("or._1.intPkDeviceID", mainValue && Number(mainValue));
    methods.setValue("or._2.testDeviceName", mainValue);
    methods.setValue("or._3.serverIP", mainValue);
  }, [mainValue]);

  return (
    <div className="m-3">
      <Input
        register={methods.register("or._0.deviceID", {})}
        title="Search"
        className="mb-3"
        disabled={fieldsDisabled}
      />
      <Input
        register={methods.register("or._1.intPkDeviceID", {})}
        title=""
        type="hidden"
        disabled={fieldsDisabled}
        readOnly
      />
      <Input
        register={methods.register("or._2.testDeviceName", {})}
        title=""
        type="hidden"
        disabled={fieldsDisabled}
        readOnly
      />
      <Input
        register={methods.register("or._3.serverIP", {})}
        title=""
        type="hidden"
        disabled={fieldsDisabled}
        readOnly
      />
      {!isNoc && (
        <div className="form-group mb-2">
          <CheckBoxWithController<any, number>
            name="isTestDevice"
            control={methods.control}
            disabled={fieldsDisabled}
            value={1}
          />
          <label className="ms-1">Show Test Devices Only</label>
        </div>
      )}
      <div className="form-group mb-2">
        <CheckBoxWithController<any, string>
          name="or._4"
          control={methods.control}
          onSelected={(and) => {
            if (and !== undefined) {
              setFieldDisabled(true);
            } else {
              setFieldDisabled(false);
            }
          }}
          value={JSON.stringify({
            or: [
              {
                and: [
                  {
                    serverIP: {
                      not: null,
                    },
                  },

                  {
                    serverIP: {
                      not: "",
                    },
                  },
                ],
              },
              {
                and: [
                  {
                    serverPort: {
                      not: null,
                    },
                  },

                  {
                    serverPort: {
                      not: "",
                    },
                  },
                ],
              },
              {
                and: [
                  {
                    staticUsername: {
                      not: null,
                    },
                  },

                  {
                    staticUsername: {
                      not: "",
                    },
                  },
                ],
              },
              {
                and: [
                  {
                    staticPassword: {
                      not: null,
                    },
                  },

                  {
                    staticPassword: {
                      not: "",
                    },
                  },
                ],
              },
            ],
          })}
        />
        <label className="ms-1">Show Static Route Devices Only</label>
      </div>
    </div>
  );
};
export default DevicesPage;
