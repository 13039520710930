import {
  mdiAlertCircle,
  mdiCheckCircle,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCloseCircle,
} from "@mdi/js";
import Icon from "@mdi/react";
import classNames from "classnames";
import { ProxyNodeModel } from "common";
import moment from "moment";
import { Fragment, useState } from "react";
import { MoonLoader } from "react-spinners";
import { Status } from "../../interfaces/DataModels/Status";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { useIsNoc } from "../../libs/hooks/useIsNoc";
import { NodeControl } from "./Nodes";
import { EPieChart } from "./NodesDashboard";

export const StatusPage = () => {
  const isNoc = useIsNoc();
  const [offset] = useState(0);
  const [cur, setCur] = useState(0);
  const fetcher = useFetcher({
    alerts: true,
  });

  const { data, isLoading, error } = useAuthorizedQuery<Status>({
    model: "status",
    offset: offset,
  });

  const { data: proxyData, refetch: refetchProxyData } =
    useAuthorizedQuery<ProxyNodeModel>({ model: "proxyNode", limit: 1000 });

  const getOverallTime = (proxyTest: Status["proxyTests"][0]) => {
    if (
      proxyTest.getStandardPassTest?.latency &&
      proxyTest.initTest?.latency &&
      proxyTest.pingTest?.latency
    )
      return ~~(
        (proxyTest.getStandardPassTest?.latency +
          proxyTest.initTest?.latency +
          proxyTest.pingTest?.latency) /
        3
      );
  };

  const isActive = (proxyTest: Status["proxyTests"][0]) =>
    proxyTest.getStandardPassTest?.success &&
    proxyTest.initTest?.success &&
    proxyTest.pingTest?.success;
  const isDoubted = (proxyTest: Status["proxyTests"][0]) =>
    (!proxyTest.getStandardPassTest?.success || !proxyTest.initTest?.success) &&
    proxyTest.pingTest?.success;
  const isFailed = (proxyTest: Status["proxyTests"][0]) =>
    !proxyTest.pingTest?.success;

  return (
    <div className="page-wrapper">
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">VIEW</div>
              <h2 className="page-title">Status</h2>
            </div>
            <div className="page-body">
              <div className="card">
                <div className="card-header flex-row justify-content-between align-items-center">
                  <h3 className="card-title">
                    <div>Services Status</div>
                  </h3>
                  {data && (
                    <span>
                      <b>Checked: </b>
                      {moment(data.result?.rows[cur]?.createdAt).fromNow()}
                    </span>
                  )}
                </div>
                {isLoading && (
                  <div className="bg-white d-flex justify-content-center p-5">
                    <MoonLoader loading={true} />
                  </div>
                )}
                {error && error.message}
                {data && (
                  <Fragment>
                    <div className="d-flex">
                      <div className="card-table table-responsive flex-fill">
                        <table className="table card-table text-nowrap datatable table-vcenter">
                          <thead>
                            <tr>
                              <th>SERVICE</th>
                              <th>URL / IP</th>
                              <th>STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Vezcore</td>
                              <td>
                                {data.result?.rows[
                                  cur
                                ].vezCoreTest?.url?.replace(/https?:\/\//, "")}
                              </td>
                              <td>
                                <StatusIcon
                                  success={
                                    data.result?.rows[cur]?.vezCoreTest?.success
                                  }
                                  time={
                                    data.result?.rows[cur]?.vezCoreTest?.latency
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <EPieChart
                          pieChartData={[
                            {
                              name: "Active",
                              value:
                                data.result?.rows[cur].proxyTests.filter(
                                  isActive
                                ).length,
                            },
                            {
                              name: "Doubt",
                              value:
                                data.result?.rows[cur].proxyTests.filter(
                                  isDoubted
                                ).length,
                            },
                            {
                              name: "Failed",
                              value:
                                data.result?.rows[cur].proxyTests.filter(
                                  isFailed
                                ).length,
                            },
                          ]}
                          getColorForName={(name: string) => {
                            switch (name) {
                              case "Active":
                                return "var(--tblr-green)";
                              case "Doubt":
                                return "var(--tblr-yellow)";
                              case "Failed":
                                return "var(--tblr-red)";
                            }
                          }}
                        />
                      </div>
                      <div>
                        <EPieChart
                          pieChartData={[
                            {
                              name: "Enabled",
                              value: proxyData?.result?.rows.filter(
                                (row) => row.enable === 1
                              ).length,
                            },
                            {
                              name: "Disabled",
                              value:
                                (proxyData?.result?.rows.length || 0) -
                                (proxyData?.result?.rows.filter(
                                  (row) => row.enable === 1
                                ).length || 0),
                            },
                          ]}
                          getColorForName={(name: string) => {
                            if (name === "Enabled")
                              return "var(--tblr-success)";
                            if (name === "Disabled")
                              return "var(--tblr-danger)";
                          }}
                        />
                        <div className="text-center">
                          <b>Total: </b> {proxyData?.result?.rows.length}
                        </div>
                      </div>
                    </div>

                    <h3 className="px-3 mt-3 card-title">Vezgate Proxies</h3>
                    <div className="card-table table-responsive">
                      <table className="table card-table text-nowrap datatable table-vcenter">
                        <thead>
                          <tr>
                            <th>PROXY URL</th>
                            <th>PING TEST</th>
                            <th>INIT TEST</th>
                            <th>GETSTANDARDPASS TEST</th>
                            <th>RESULT</th>
                            <th>CONTROL</th>
                            <th>ENABLE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.result?.rows[cur]?.proxyTests.map(
                            (proxyTest) => {
                              const proxy = proxyData?.result?.rows.find(
                                (e) => e.intPkProxyNodeID === proxyTest.proxyId
                              );

                              return (
                                <tr
                                  className={classNames(
                                    (!proxyTest.getStandardPassTest?.success ||
                                      !proxyTest.initTest?.success ||
                                      !proxyTest.pingTest?.success) &&
                                      "bg-red-lt",
                                    proxyData?.result?.rows.find(
                                      (e) =>
                                        e.intPkProxyNodeID === proxyTest.proxyId
                                    )?.enable === 0 && "bg-gray-lt"
                                  )}
                                >
                                  <td>
                                    {proxyTest.pingTest?.url?.replace(
                                      /https?:\/\//,
                                      ""
                                    ) || "Unknown"}
                                  </td>
                                  <td>
                                    <StatusIcon
                                      time={proxyTest.pingTest?.latency}
                                      success={proxyTest.pingTest?.success}
                                      err={proxyTest.pingTest?.error?.message}
                                    />
                                  </td>
                                  <td>
                                    <StatusIcon
                                      time={proxyTest.initTest?.latency}
                                      err={proxyTest.initTest?.error?.message}
                                      success={proxyTest.initTest?.success}
                                    />
                                  </td>
                                  <td>
                                    <StatusIcon
                                      time={
                                        proxyTest.getStandardPassTest?.latency
                                      }
                                      err={
                                        proxyTest.getStandardPassTest?.error
                                          ?.message
                                      }
                                      success={
                                        proxyTest.getStandardPassTest?.success
                                      }
                                    />
                                  </td>
                                  <td>
                                    <StatusIcon
                                      time={getOverallTime(proxyTest)}
                                      err="One or more tests failed"
                                      success={
                                        proxyTest.getStandardPassTest
                                          ?.success &&
                                        proxyTest.initTest?.success &&
                                        proxyTest.pingTest?.success
                                      }
                                    />
                                  </td>
                                  {proxy && !isNoc && (
                                    <td>
                                      <NodeControl
                                        nodeType="proxyNode"
                                        id={proxy.intPkProxyNodeID}
                                        sshPassword={proxy.password}
                                        ip={proxy.proxyIP}
                                        sshUserName={proxy.userName}
                                        name={proxy.proxyNodeName}
                                      />
                                    </td>
                                  )}
                                  {proxy && (
                                    <td>
                                      <label className="form-check form-check-single form-switch px-0 ">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={(e) => {
                                            const newValue = e.target.checked;

                                            fetcher({
                                              url: `/api/v1/data/proxyNode/${proxyTest.proxyId}/edit`,
                                              method: "put",
                                              data: {
                                                enable: Number(newValue),
                                              },
                                              successMessage: "State Changed",
                                            }).then((e) => {
                                              refetchProxyData();
                                            });
                                          }}
                                          checked={!!proxy.enable}
                                        />
                                      </label>
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                )}
                <div className="card-footer d-flex flew-row justify-content-between">
                  <button
                    className="btn"
                    onClick={(_) => setCur(cur + 1)}
                    disabled={cur + 1 >= (data?.result?.rows?.length || 0)}
                  >
                    <Icon path={mdiChevronLeft} size={0.85} />
                    prev
                  </button>
                  <button className="btn" disabled={cur === 0} onClick={(_) => setCur(cur - 1)}>
                    next
                    <Icon path={mdiChevronRight} size={0.85} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type StatusIconProps = {
  success?: boolean;
  time?: number | null;
  err?: string;
};

function StatusIcon({ success, time, err }: StatusIconProps) {
  let color = "var(--tblr-green)";
  let symbol = mdiCheckCircle;

  if (time) {
    if (time > 400) {
      color = "var(--tblr-yellow)";
      symbol = mdiAlertCircle;
    }
  } else {
    color = "var(--tblr-orange)";
  }

  if (!success) {
    symbol = mdiCloseCircle;
    color = "var(--tblr-red)";
  }

  return (
    <div>
      <Icon path={symbol} size={0.84} color={color} />
      <span
        className="font-weight-normal ms-1"
        style={{
          color: color,
        }}
      >
        {time ? `${time}ms` : <abbr title={err}>Failed</abbr>}
      </span>
    </div>
  );
}
