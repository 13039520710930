import { DefaultValues, FormProvider, useForm } from "react-hook-form";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { CRUDModalProps } from "../../interfaces/CURDModalProps";
import CustomModal from "./Modal";
import { FormRenderFunctionProp } from "../../interfaces/FormRenderFunctionProp";
import { camelToSentenceCase } from "../../libs/util/camelToSentenceCase";
import useFetcher from "../../libs/hooks/useFetcher";
import { usePermissions } from "../../libs/hooks/usePermissions";

type CreateEditModalProps<T> = FormRenderFunctionProp<T> &
  React.PropsWithChildren<CRUDModalProps<T>>;

const CreateEditModalContext = React.createContext({
  primaryButtonDisabled: false,
  setPrimaryButtonDisabled: (state: boolean) => {},
});

export const useCreateEditModalContext = () => useContext(CreateEditModalContext);

export default function CreateEditModal<T extends Record<string, any>>({
  onHide: onCancel,
  model,
  open,
  idField,
  modelName,
  title,
  defaultValues,
  onEditHook,
  renderForm: createEditFormRender,
  onCreateHook,
  modalSize = "lg",
  warnOnUncommittedChanges = true,
  onSuccess,
}: CreateEditModalProps<T>) {
  const getDefaultValues = useCallback((): any => {
    if (model) return model;

    return defaultValues ? defaultValues : {};
  }, [model, defaultValues]);

  const methods = useForm({
    defaultValues: getDefaultValues() as DefaultValues<T>,
    mode: "all",
  });

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = methods;

  const isEditMode = !!model;
  const permissions = usePermissions(modelName);
  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(false);
  const [unsavedCommitsModalOpen, setConfirmUncommitedChangesModal] = useState(false);

  const getChangedData = useCallback(() => {
    const data: Record<string, any> = getValues();
    const newData: any = {};
    const modelData = model || ({} as Record<string, any>);

    for (const key of Object.keys(data)) {
      if (typeof data[key] === "object" && data[key] !== null && !(data[key] instanceof Date))
        continue;

      if (modelData[key] !== data[key]) {
        newData[key] = data[key];
      }
    }

    if (!newData || JSON.stringify(newData) === "{}") return;

    console.log("changes", newData);

    return newData;
  }, [model, getValues]);

  const areNonDefaultDirty = useCallback(() => {
    if (dirtyFields) {
      const dirtyKeys = Object.keys(dirtyFields);
      const keyWithDefaultValues = Object.keys(defaultValues || {});

      return dirtyKeys.some((e) => dirtyFields[e] && !keyWithDefaultValues.includes(e));
    }

    return false;
  }, [dirtyFields, defaultValues]);

  const alertToSaveUncommitedChanges = useCallback(() => {
    // Show alert if data has changed & not saved
    if (
      warnOnUncommittedChanges &&
      ((isEditMode && getChangedData()) || (!isEditMode && areNonDefaultDirty()))
    ) {
      setConfirmUncommitedChangesModal(true);
    } else {
      onCancel();
    }
  }, [
    getChangedData,
    open,
    onCancel,
    areNonDefaultDirty,
    isEditMode,
    warnOnUncommittedChanges,
    setConfirmUncommitedChangesModal,
  ]);

  const [busy, setBusy] = useState(false);

  const fetcher = useFetcher({
    invalidateCache: true,
    queryKey: `${modelName}data`,
  });

  const onEdited = useCallback(async () => {
    const newData = getChangedData();
    if (newData) {
      if (onEditHook) {
        let shouldGoFurther = onEditHook(model!, newData);

        if (shouldGoFurther instanceof Promise) {
          shouldGoFurther = await shouldGoFurther;
        }

        if (!shouldGoFurther) return false;
      }

      await fetcher<T>({
        url: `/api/v1/data/${modelName}/${model![idField]}/edit`,
        method: "put",
        data: newData,
        successMessage: "Successfully Edited",
      });

      onSuccess && onSuccess(getValues() as T);
    }

    return true;
  }, [model, getChangedData, getValues]);

  const onCreated = useCallback(async () => {
    const data = getValues();

    if (onCreateHook) {
      let shouldGoFurther = onCreateHook(data as T);

      if (shouldGoFurther instanceof Promise) {
        shouldGoFurther = await shouldGoFurther;
      }

      if (!shouldGoFurther) return false;
    }

    await fetcher<T>({
      url: `/api/v1/data/${modelName}/create`,
      method: "post",
      data,
      successMessage: "Successfully Created",
    });

    onSuccess && onSuccess(getValues() as T);
    return true;
  }, [model, getValues]);

  const onSubmit = useCallback(async () => {
    if (busy) return false;

    setBusy(true);

    try {
      // if modal is defined, it's editMode
      if (isEditMode) {
        await onEdited();
      } else {
        await onCreated();
      }

      reset(getDefaultValues());
      onCancel();
    } catch (e: any) {}

    setBusy(false);
  }, [model, busy, getDefaultValues]);

  // incase of defaultValues not being set, set them manually
  useEffect(() => {
    reset(getDefaultValues());
  }, [open]);

  return (
    <CreateEditModalContext.Provider
      value={{
        primaryButtonDisabled,
        setPrimaryButtonDisabled,
      }}
    >
      <CustomModal
        loading={busy}
        size={modalSize}
        primaryButtonDisabled={primaryButtonDisabled}
        show={open}
        onPrimaryButtonClick={handleSubmit(onSubmit)}
        primaryButtonText="Save"
        title={
          model
            ? `Edit ${title || camelToSentenceCase(modelName)}`
            : `Add ${title || camelToSentenceCase(modelName)}`
        }
        onHide={alertToSaveUncommitedChanges}
      >
        <form onSubmit={primaryButtonDisabled ? undefined : handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            {createEditFormRender({
              register,
              control,
              errors,
              isEditMode,
              model,
              getValues,
              setValue,
              watch,
              readOnly: !permissions.allowUpdate,
            })}
            <CustomModal
              onHide={() => setConfirmUncommitedChangesModal(false)}
              onPrimaryButtonClick={() => {
                setConfirmUncommitedChangesModal(false);
                onCancel();
              }}
              show={unsavedCommitsModalOpen}
              theme="danger"
              title="Uncommited changes"
              primaryButtonText="Confirm"
              size="sm"
            >
              <div className="m-3">
                <p>You will lose the changes you made, if you close this. Are you sure?</p>
              </div>
            </CustomModal>
          </FormProvider>
          <button
            type="submit"
            style={{ display: "none" }}
            disabled={primaryButtonDisabled}
          ></button>
        </form>
      </CustomModal>
    </CreateEditModalContext.Provider>
  );
}
