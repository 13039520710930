import "./Login.scss";

import { types, useAlert } from "react-alert";
import { useCallback, useContext, useEffect, useState } from "react";

import { AppContext, User } from "../libs/state";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

export default function Login() {
  const context = useContext(AppContext);
  const history = useHistory();
  const { register, handleSubmit, getValues } = useForm();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const login = useCallback(
    async (data: any) => {
      try {
        setIsLoading(true);
        const res = await context.fetcher
          .post("/api/v1/auth/login", data)
          .then((res) => res.data);
        if (res.status === 200) {
          context.authenticate(res.result as User);
        }
      } catch (error: any) {
        let message = error.response?.data?.message || error.message;
        alert.show(message, { type: types.ERROR });
      }

      setIsLoading(false);
    },

    [getValues]
  );

  useEffect(() => {
    context.isAuthenticated && history.replace("/dashboard");
  }, [history, context]);

  if (context.isAuthenticated) {
    return null;
  }

  return (
    <div className="login-container">
      <h1 className="mb-3">VEZMON</h1>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Login</h3>
        </div>
        <div className="card-body">
          <form
            onSubmit={handleSubmit(() => {
              if (!isLoading) login(getValues());
            })}
          >
            <div className="form-group  mb-3">
              <label htmlFor="userNameField" className="form-label">
                Username
              </label>
              <input
                {...register("userName", { required: true })}
                type="text"
                id="userNameField"
                readOnly={isLoading}
                className="form-control"
                placeholder="Enter your Username"
              />
            </div>
            <div className="form-group  mb-3">
              <label htmlFor="passwordField" className="form-label">
                Password
              </label>
              <input
                {...register("password", { required: true })}
                type="password"
                id="passwordField"
                className="form-control"
                readOnly={isLoading}
                placeholder="Enter your password"
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  {...register("rememberHim")}
                />
                <span className="form-check-label">Keep me logged in</span>
              </label>
            </div>
            <button
              type="submit"
              className={`btn btn-primary ${isLoading ? "btn-loading" : ""} `}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
