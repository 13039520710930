import {
  Control,
  Controller,
  FieldPath,
  RegisterOptions,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form";
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { mdiClose, mdiDelete, mdiFileEdit, mdiSwapHorizontal, mdiUndo } from "@mdi/js";

import { JsonConfigModel } from "common";
import ControlledInput from "../../components/FormElements/ControlledInput";
import EditPage from "../../components/EditPage";
import Icon from "@mdi/react";
import Input from "../../components/FormElements/ControlledInput";
import { JsonConfigAttributes } from "common";
import { ModalContext } from "../../components/Modals/Modal";
import Select from "react-select";
import { booleanOptions } from "../../constants";

interface JSONField {
  key: string;
  type: "number" | "string" | "boolean" | "object";
  value?: string | boolean | number;
}

interface JSONInputProps<T> {
  control: Control<T>;
  name: FieldPath<T>;
  readOnly?: boolean;
  rules?: Omit<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
}

export function JSONInput<T extends Record<string, any>>({
  control: upperControl,
  name,
  readOnly = false,
  rules,
}: React.PropsWithChildren<JSONInputProps<T>>) {
  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<JSONField>({
    defaultValues: { type: "string" },
  });

  const {
    formState: { errors: upperErrors },
  } = useFormContext<JsonConfigAttributes>();
  const curValue = watch();

  let [startingValues, setStartingValues] = useState<JSONField[]>();
  const [jsonValues, setJsonValue] = useState<JSONField[]>();
  const [isBeingEdited, setIsBegingEdited] = useState<Record<string, boolean>>({});
  const [areSetToDelete, setAreSetToDelete] = useState<string[]>([]);
  const { closed: modalClosed } = useContext(ModalContext);

  const {
    field: { value, onChange },
  } = useController({
    control: upperControl,
    name,
    rules,
  });

  const typeOptions = [
    { label: "Number", value: "number" },
    { label: "String", value: "string" },
    { label: "Boolean", value: "boolean" },
    { label: "Object", value: "object" },
  ];

  const parseIncomingJSON = useCallback(() => {
    if (value && !jsonValues) {
      let curJSONValue: JSONField[] = [];

      try {
        // @ts-ignore
        if (value.length > 2) {
          const parsed = JSON.parse(value as string);
          curJSONValue = Object.entries<string>(parsed).map<JSONField>(
            ([key, value]: [any, any], _) => {
              const type = typeof value as "boolean" | "string" | "number" | "object";

              if (type === "boolean") {
                value = value ? 1 : 0;
              }

              if (type === "object") {
                value = JSON.stringify(value);
              }

              return {
                key,
                value,
                type,
              };
            }
          );
        }
      } catch {
        curJSONValue = [];
      }

      const copyOfCurJSONValue = Array.from(curJSONValue).map((e) => ({
        ...e,
      }));
      setStartingValues(copyOfCurJSONValue);
      setJsonValue(curJSONValue);
    }
  }, [value, jsonValues, setJsonValue, setStartingValues]);

  const fireUpdate = useCallback(
    (values: JSONField[], deletedKeys?: string[]) => {
      const data: Record<string, any> = {};

      setJsonValue(values);

      if (deletedKeys) {
        setAreSetToDelete(deletedKeys);
      }

      const valuesAfterRemovingDeleted = values.filter(
        (e) => !(deletedKeys || areSetToDelete).includes(e.key)
      );

      console.log(errors);

      for (const value of valuesAfterRemovingDeleted) {
        if (value.key) {
          if (value.type === "number")
            data[value.key] =
              value?.value !== undefined ? parseInt(value.value as string) : undefined;
          else if (value.type === "boolean") {
            data[value.key] = value?.value === 1 ? true : false;
          } else if (value.type === "string") data[value.key] = value.value?.toString();
          else {
            data[value.key] = value?.value ? JSON.parse(value.value as string) : undefined;
          }
        }
      }
      console.log(data);
      onChange(JSON.stringify(data));
    },
    [onChange, setJsonValue, setAreSetToDelete, errors]
  );

  const addToConfig = useCallback(
    (value: JSONField) => {
      const values = [...(jsonValues || [])];

      // add the submitted value
      values.unshift(value);

      fireUpdate(values);
    },
    [jsonValues, fireUpdate]
  );

  useEffect(() => {
    parseIncomingJSON();
  }, [value]);

  useEffect(() => {
    if (modalClosed) {
      setJsonValue(undefined);
    }
  }, [modalClosed]);

  const validateKey = useCallback(
    (key: string) => {
      return jsonValues?.find((e) => e.key === key) ? "Already Exists" : undefined;
    },
    [jsonValues]
  );

  const handleManualSubmit = useCallback(async () => {
    await handleSubmit(addToConfig)();

    if (!Object.values(errors).some((value) => !!value)) {
      setValue("key", "");
      setValue("type", "string");
      setValue("value", "");
    }
  }, [setValue, handleSubmit, errors, addToConfig]);

  const handleKeyDownOnForm = useCallback(
    (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        e.preventDefault();
        handleManualSubmit();
      }
    },
    [handleManualSubmit]
  );

  const getInputElementForType = (type: string) => {
    return (
      <>
        {type !== "boolean" && (
          <ControlledInput
            register={register("value", { required: "Required" })}
            title="Value"
            onKeyDown={handleKeyDownOnForm}
            errorMessage={errors.value?.message}
            className="col"
            type={type === "number" ? "number" : "text"}
          />
        )}
        {type === "boolean" && (
          <div className="form-group flex-fill col">
            <label className="form-label">Value</label>
            <Controller
              name={"value"}
              control={control}
              rules={{ required: "Required" }}
              render={({ field: { name, onBlur, onChange, value } }) => (
                <Select
                  onChange={(e) => onChange(e?.value)}
                  onBlur={onBlur}
                  onKeyDown={handleKeyDownOnForm}
                  name={name}
                  value={booleanOptions.find((e) => e.value === value)}
                  options={booleanOptions}
                />
              )}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      {!readOnly && (
        <div className="row">
          <div className="col">
            <ControlledInput
              register={register("key", {
                required: "Required",
                validate: validateKey,
              })}
              title="Key"
              onKeyDown={handleKeyDownOnForm}
              errorMessage={errors.key?.message}
            />
          </div>
          <div className="col">
            <div className="form-group flex-fill">
              <label className="form-label">Type</label>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field: { name, onBlur, onChange, value } }) => (
                  <Select
                    onChange={(e) => {
                      if (e?.value === "boolean") {
                        setValue("value", 0);
                      }
                      return onChange(e?.value);
                    }}
                    onBlur={onBlur}
                    name={name}
                    onKeyDown={handleKeyDownOnForm}
                    value={typeOptions.find((e) => e.value === value)}
                    options={typeOptions}
                  />
                )}
              />
            </div>
          </div>
          <div className="col">{getInputElementForType(curValue.type)}</div>
          <div className="col-2">
            <div className="d-flex flex-column">
              <label className="form-label invisible">Add</label>
              <button className="btn btn-primary" type="button" onClick={handleManualSubmit}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="card mt-3">
        <div className="table-responsive card-table">
          <table className="table table-vcenter datatable card-table">
            <thead>
              <tr>
                <th>Key</th>
                <th>Type</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {jsonValues && jsonValues.length > 0 ? (
                jsonValues?.map((currentField, index) => {
                  let elements: React.ReactElement[] = [];

                  const isDeleted = areSetToDelete.includes(currentField.key);

                  const oldField = startingValues?.find((e) => e.key === currentField.key);

                  const convertIfBoolean = (field: JSONField) => {
                    if (field.type === "boolean") return !!field.value ? "True" : "False";

                    return field.value;
                  };

                  const toggleEditModeForCurrentField = () => {
                    const copy = { ...isBeingEdited };
                    copy[currentField.key] = !isBeingEdited[currentField.key];
                    setIsBegingEdited(copy);
                  };

                  const updateValue = (value: string | number | boolean, close: boolean = true) => {
                    const stateCopy = [...jsonValues];
                    const index = stateCopy.findIndex((e) => e.key === currentField.key);

                    if (index !== -1) {
                      stateCopy[index].value = value;
                      fireUpdate(stateCopy);

                      if (close) toggleEditModeForCurrentField();
                    } else {
                      console.error("index -1");
                    }
                  };

                  const handleOpenEditMode = () => {
                    if (currentField.type === "boolean") {
                      updateValue(currentField.value === 1 ? 0 : 1, false);
                    } else {
                      toggleEditModeForCurrentField();
                    }
                  };

                  elements.push(<td>{currentField.key}</td>);

                  if (oldField && oldField.type !== currentField.type) {
                    elements.push(
                      <td key={`td-type-${currentField.key}`}>
                        <span>
                          <span className="bg-danger text-white">{oldField.type}</span>
                          <span className="bg-success text-white">{currentField.type}</span>
                        </span>
                      </td>
                    );
                  } else {
                    elements.push(<td>{currentField.type}</td>);
                  }

                  if (isBeingEdited[currentField.key]) {
                    const editComponentProps: React.InputHTMLAttributes<HTMLInputElement> = {
                      className: "form-control",
                      type: currentField.type === "number" ? "number" : "text",
                      defaultValue: currentField.value as string,
                      onKeyDown: (e) => {
                        if (e.code === "Enter" || e.code === "NumpadEnter")
                          updateValue(e.currentTarget.value);
                      },
                    };

                    elements.push(
                      <td key={`td-value-${currentField.key}`}>
                        <input {...editComponentProps} />
                      </td>
                    );
                  } else {
                    if (oldField && oldField.value !== currentField.value) {
                      elements.push(
                        <td
                          key={`td-value-${currentField.key}`}
                          onDoubleClick={handleOpenEditMode}
                          className="cursor-pointer user-select-none"
                        >
                          <span>
                            <span className="p-1 rounded text-danger">
                              <del>{convertIfBoolean(oldField)}</del>
                            </span>
                            <span className="p-1 rounded text-success">
                              {convertIfBoolean(currentField)}
                            </span>
                          </span>
                        </td>
                      );
                    } else {
                      elements.push(
                        <td
                          key={`td-value-${currentField.key}`}
                          onDoubleClick={handleOpenEditMode}
                          className="cursor-pointer user-select-none"
                        >
                          {convertIfBoolean(currentField)}
                        </td>
                      );
                    }
                  }

                  elements.push(
                    <td>
                      <div className="d-flex flex-row">
                        <a
                          className={`p-1 d-inline-block cursor-pointer ${
                            isBeingEdited[currentField.key] ? "danger" : ""
                          }`}
                          onClick={handleOpenEditMode}
                        >
                          <Icon
                            path={
                              isBeingEdited[currentField.key]
                                ? mdiClose
                                : currentField.type === "boolean"
                                ? mdiSwapHorizontal
                                : mdiFileEdit
                            }
                            style={{
                              height: "1rem",
                              marginRight: ".25rem",
                            }}
                          />
                        </a>
                        <a
                          className={`p-1 d-inline-block ${
                            !isDeleted ? "danger" : ""
                          } cursor-pointer`}
                          onClick={(_) => {
                            if (startingValues?.find((e) => e.key === currentField.key)) {
                              let copy = [...areSetToDelete];
                              if (!isDeleted) {
                                copy.push(currentField.key);
                              } else {
                                copy = copy.filter((e) => e !== currentField.key);
                              }
                              fireUpdate(jsonValues, copy);
                            } else {
                              fireUpdate(jsonValues.filter((e) => e.key !== currentField.key));
                            }
                          }}
                        >
                          <Icon
                            path={isDeleted ? mdiUndo : mdiDelete}
                            style={{
                              height: "1rem",
                              marginRight: ".25rem",
                            }}
                          />
                        </a>
                      </div>
                    </td>
                  );

                  return (
                    <tr
                      className={`${!oldField ? `bg-green-lt` : isDeleted ? `bg-red-lt` : ``}`}
                      key={index}
                    >
                      {elements}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No Config
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <span className="text-danger mt-2 d-inline-block">{upperErrors.configJSON?.message}</span>
    </div>
  );
}

const JsonConfigPage: FunctionComponent = () => {
  return (
    <EditPage<JsonConfigAttributes, JsonConfigModel>
      modelName="jsonConfig"
      idField="intPkConfigID"
      createEditModalSize="xl"
      nameField="configName"
      fields={["intPkConfigID", "configName"]}
      renderForm={({ control, readOnly, register, errors }) => (
        <div className="m-3">
          <Input
            register={register("configName", { required: "Required" })}
            title="Config Name"
            errorMessage={errors.configName?.message}
            readOnly={readOnly}
            className="mb-3"
          />

          <JSONInput
            control={control}
            name="configJSON"
            readOnly={readOnly}
            rules={{ required: "At least one key is required" }}
          />
        </div>
      )}
    />
  );
};

export default JsonConfigPage;
