import "@tabler/core/dist/css/tabler.min.css";
import "./index.scss";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/Alert";
import App from "./components/App";
import React from "react";
import ReactDOM from "react-dom";
import { alertOptions } from "./constants";

export const queryClient = new QueryClient();

ReactDOM.render(
  <AlertProvider template={AlertTemplate} {...alertOptions}>
    <QueryClientProvider client={queryClient}>
      {(!process.env.NODE_ENV || process.env.NODE_ENV === "development") && (
        <ReactQueryDevtools />
      )}
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </QueryClientProvider>
  </AlertProvider>,
  document.getElementById("root")
);
