import { Controller, useFormContext } from "react-hook-form";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { FunctionComponent, useState } from "react";
import { PoolAttributes, PoolModel } from "common";
import { PoolsStatusAttributes, PoolsStatusModel } from "common";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import DraggablePicker from "../../components/DraggablePicker";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import ListTile from "../../components/ListTile";
import MoonLoader from "react-spinners/MoonLoader";
import { PoolProfileAttributes } from "common";
import { PoolsFilterForm, PoolsForm } from "./Pools";
import Select from "react-select";
import { ServerResponse } from "common";
import { optionsForStatus } from "../../constants";
import { resolveObjectFromPath } from "../../libs/util/resolveObjectFromPath";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { useQueryClient } from "react-query";
import { PieChart, Pie, Cell, Tooltip, Legend, Label } from "recharts";
import CreateEditModal from "../../components/Modals/CreateEditModal";
import useModelAggregationQuery from "../../libs/hooks/useModelAggregationQuery";

type PoolsStatusAllAttributes = PoolsStatusAttributes & {
  intPkPoolProfile: PoolProfileAttributes;
  intPkPool: PoolAttributes;
};

const PoolStatusPage: FunctionComponent = () => {
  return (
    <EditPage<PoolsStatusAttributes, PoolsStatusAllAttributes>
      modelName="poolStatus"
      fields={[
        "intPkPoolsStatusID",
        "intPkPoolProfile.poolProfileName",
        "intPkPool.poolName",
        "isFreeOrTrialOrPremium",
        "listPlaceNumber",
        { key: "enable", type: "boolean" },
      ]}
      idField="intPkPoolsStatusID"
      nameField="intPkPoolProfileID"
      onCreateHook={(_) => false}
      onEditHook={(_) => false}
      primaryButtonMessage="Manage Pool Status"
      isRecordDeletable={(_) => false}
      isRecordEditable={(_) => false}
      createEditModalSize="xl"
      defaultValues={{ enable: 1 }}
      warnOnUncommittedChanges={false}
      renderForm={({ readOnly, isEditMode, model }) => (
        <PoolStatusForm
          readOnly={readOnly}
          model={model}
          isEditMode={isEditMode}
        />
      )}
    />
  );
};

const PoolStatusForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<PoolsStatusAttributes>) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext<PoolsStatusAttributes>();

  const [busy, setBusy] = useState(false);
  const [selectedPool, setSelectedPool] = useState<PoolModel>();

  const updateListPlaceNumbers = async (data: any[]) => {
    if (data.length < 1) return;

    return fetcher({
      method: "post",
      url: `/api/v1/data/poolStatus/updateListPlace`,
      data,
      successMessage: "Updated List Place Numbers",
    });
  };

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    if (busy) return;

    setBusy(true);

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;
    const changed = [...poolStatusRecords!.result!.rows];

    if (
      result.destination.droppableId === result.source.droppableId &&
      result.source.droppableId === "poolStatus"
    ) {
      if (fromIndex === toIndex) return;

      const differences = [];

      if (toIndex < fromIndex) {
        changed[fromIndex].listPlaceNumber =
          toIndex - 1 > 0 ? changed[toIndex - 1].listPlaceNumber! + 1 : 1;

        for (let i = toIndex; i < fromIndex; i++) {
          changed[i].listPlaceNumber! += 1;

          differences.push({
            listPlaceNumber: changed[i].listPlaceNumber,
            poolStatusId: changed[i].intPkPoolsStatusID,
          });
        }
      } else {
        for (let i = toIndex; i > fromIndex; i--) {
          changed[i].listPlaceNumber! -= 1;

          differences.push({
            listPlaceNumber: changed[i].listPlaceNumber,
            poolStatusId: changed[i].intPkPoolsStatusID,
          });
        }

        changed[fromIndex].listPlaceNumber =
          changed[toIndex - 1].listPlaceNumber! + 1;
      }

      differences.push({
        listPlaceNumber: changed[fromIndex].listPlaceNumber,
        poolStatusId: changed[fromIndex].intPkPoolsStatusID,
      });

      const [removed] = changed.splice(fromIndex, 1);
      changed.splice(toIndex, 0, removed);

      queryClient.setQueryData(poolStatusQueryKey, (e: any) => {
        e.result.rows = changed;
        return e;
      });

      try {
        await updateListPlaceNumbers(differences);
        refetchAggregations();
      } catch (e: any) {
        queryClient.setQueryData(poolStatusQueryKey, poolStatusRecords);
      }
    } else if (
      result.destination.droppableId === "pool" &&
      result.source.droppableId === "poolStatus"
    ) {
      const poolStatusRecord = changed[fromIndex];
      const differences = [];

      for (let i = fromIndex + 1; i < changed.length; i++) {
        changed[i].listPlaceNumber! -= 1;

        differences.push({
          listPlaceNumber: changed[i].listPlaceNumber,
          poolStatusId: changed[i].intPkPoolsStatusID,
        });
      }

      const [removed] = changed.splice(fromIndex, 1);

      try {
        queryClient.setQueryData(poolStatusQueryKey, (old: any) => {
          old.result.rows = changed;
          return old;
        });

        await fetcher({
          method: "delete",
          url: `/api/v1/data/poolStatus/${poolStatusRecord.intPkPoolsStatusID}`,
          successMessage: "Removed Pool from Status",
          shouldInvalidateCache: differences.length < 1,
        });

        await updateListPlaceNumbers(differences);
        refetchPoolRecords();
        refetchAggregations();
      } catch (e: any) {
        queryClient.setQueryData(poolStatusQueryKey, (old: any) => {
          old.result.rows = [...changed, removed];
          return old;
        });
      }
    } else if (
      result.destination.droppableId === "poolStatus" &&
      result.source.droppableId === "pool"
    ) {
      const pool = poolRecords?.result?.rows[fromIndex]!;
      if (changed.some((e) => e.intPkPoolID === pool.intPkPoolID)) return;

      const differences = [];

      const formValues = getValues();
      const listPlaceNumber =
        toIndex < 1 ? 1 : (changed[toIndex - 1].listPlaceNumber || 0) + 1;
      const newPoolStatusForm = {
        listPlaceNumber,
        enable: 1,
        intPkPoolID: pool.intPkPoolID,
        intPkPoolProfileID: formValues.intPkPoolProfileID,
        isFreeOrTrialOrPremium: formValues.isFreeOrTrialOrPremium,
      };

      const newPoolStatus = {
        ...newPoolStatusForm,
        intPkPool: pool,
      };

      changed.splice(toIndex, 0, newPoolStatus as PoolsStatusModel);

      for (let i = toIndex + 1; i < changed.length; i++) {
        changed[i].listPlaceNumber! += 1;

        differences.push({
          listPlaceNumber: changed[i].listPlaceNumber,
          poolStatusId: changed[i].intPkPoolsStatusID,
        });
      }

      try {
        queryClient.setQueryData(poolStatusQueryKey, (old: any) => {
          old.result.rows = changed;
          return old;
        });

        // @ts-ignore
        delete newPoolStatus.intPkPool;

        const data = await fetcher<ServerResponse<PoolsStatusAttributes>>({
          method: "post",
          url: `/api/v1/data/poolStatus/create`,
          data: newPoolStatus,
          successMessage: "Added Pool",
          shouldInvalidateCache: differences.length < 1,
        });

        changed[toIndex] = data.result!;

        await updateListPlaceNumbers(differences);
        refetchPoolRecords();
        refetchAggregations();
      } catch (e: any) {
        queryClient.setQueryData(poolStatusQueryKey, poolStatusRecords);
      }
    }

    setBusy(false);
  };

  const [isLocked, setLocked] = useState(isEditMode);

  const { intPkPoolProfileID, isFreeOrTrialOrPremium } = getValues();

  const {
    data: poolStatusRecords,
    isLoading: arePoolStatusRecordsLoading,
    queryKey: poolStatusQueryKey,
    refetch: refetchPoolStatusRecords,
  } = useAuthorizedQuery<PoolsStatusModel>({
    model: "poolStatus",
    limit: 1000,
    order: {
      listPlaceNumber: "asc",
    },
    filter: {
      // @ts-ignore
      intPkPoolProfileID: {
        equals: intPkPoolProfileID,
      },
      // @ts-ignore
      isFreeOrTrialOrPremium: {
        equals: isFreeOrTrialOrPremium,
      },
    },
    enabled: isLocked,
  });

  const { data: poolStatusAggregations, refetch: refetchAggregations } =
    useModelAggregationQuery({
      model: "poolStatus",
      filter: {
        // @ts-ignore
        intPkPoolProfileID: {
          equals: intPkPoolProfileID,
        },
        // @ts-ignore
        isFreeOrTrialOrPremium: {
          equals: isFreeOrTrialOrPremium,
        },
      },
      enabled: isLocked,
    });

  const queryClient = useQueryClient();

  const [poolFilter, setPoolFilter] = useState<Partial<PoolAttributes>>({});

  const {
    data: poolRecords,
    isLoading: arePoolRecordsLoading,
    refetch: refetchPoolRecords,
  } = useAuthorizedQuery<PoolModel>({
    model: "pool",
    limit: 50,
    filter: {
      ...poolFilter,
      pools_statuses: {
        // @ts-ignore
        none: {
          // @ts-ignore
          intPkPoolProfileID: {
            equals: intPkPoolProfileID,
          },
          // @ts-ignore
          isFreeOrTrialOrPremium: {
            equals: isFreeOrTrialOrPremium,
          },
        },
      },
    },
    enabled: isLocked ,
  });

  const fetcher = useFetcher({ queryKey: poolStatusQueryKey });
  const togglePoolStatusEnable = async (
    poolStatusId: number,
    value: boolean
  ) => {
    try {
      const copy = { ...poolStatusRecords };
      if (copy.result) {
        copy.result.rows = copy.result?.rows.map((e) => {
          if (e.intPkPoolsStatusID === poolStatusId)
            return {
              ...e,
              enable: value ? 1 : 0,
            };
          return e;
        });

        queryClient.setQueryData(poolStatusQueryKey, copy);
      }

      await fetcher({
        url: `/api/v1/data/poolStatus/${poolStatusId}/edit`,
        method: "put",
        data: { enable: value ? 1 : 0 },
        successMessage: `Pool Status ${
          value ? "Enabled" : "Disabled"
        } Successfully`,
      });

      refetchAggregations();
    } catch (e: any) {
      queryClient.setQueryData(poolStatusQueryKey, poolStatusRecords);
    }
  };

  const paneClassName =
    "p-3 mw-33 d-flex flex-column align-items-stretch position-relative  mh-80vh overflow-auto";
  const isLockedClassName = !isLocked ? "blocked" : "";

  const enabledCount =
    poolStatusAggregations?.result?.find((e) => e.name === "Enabled")?.value ||
    0;
  const enabledCapacity =
    poolStatusAggregations?.result?.find((e) => e.name === "Enabled Capacity")
      ?.value || 0;

  const totalCapacity =
    poolStatusAggregations?.result?.find((e) => e.name === "Total Capacity")
      ?.value || 0;

  const attachedPoolsChartData = [
    {
      name: "Enabled Pools",
      value: enabledCount,
    },
    {
      name: "Attached Pools",
      value: poolStatusRecords?.result?.count
        ? poolStatusRecords?.result?.count - enabledCount
        : 0,
    },
  ];

  const attachedPoolsCapacityChartData = [
    {
      name: "Enabled Cap.",
      value: enabledCapacity,
    },
    {
      name: "Attached Cap.",
      value: totalCapacity - enabledCapacity,
    },
  ];

  const currentCapcityChartData = [
    {
      name: "Curr Cap.",
      value: enabledCapacity - 10,
    },
    {
      name: "Attached Cap.",
      value: enabledCapacity - (enabledCapacity - 10),
    },
  ];

  const colors = ["var(--tblr-success)", "var(--tblr-primary)"];

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <CreateEditModal
        model={selectedPool}
        idField="intPkPoolID"
        nameField="poolName"
        modelName="pool"
        modalSize="xl"
        onHide={() => {
          setSelectedPool(undefined);
          refetchPoolRecords();
          refetchPoolStatusRecords();
        }}
        open={!!selectedPool}
        renderForm={({ isEditMode, model, readOnly }) => {
          return (
            <PoolsForm
              readOnly={readOnly}
              model={model}
              isEditMode={isEditMode}
            />
          );
        }}
      />
      <div className="d-flex flex-row align-items-stretch">
        <div className={paneClassName} style={{ minHeight: "500px" }}>
          <div className="form-group  mb-3">
            <label className="form-label">Status (Free/Trial/Premium)</label>
            <Controller
              name="isFreeOrTrialOrPremium"
              control={control}
              render={({ field: { name, onBlur, onChange, value } }) => (
                <Select
                  onChange={(e) => onChange(e?.value)}
                  onBlur={onBlur}
                  isDisabled={readOnly || isLocked}
                  name={name}
                  value={optionsForStatus.find((e) => e.value === value)}
                  options={optionsForStatus}
                />
              )}
            />
          </div>
          <div className="form-group mb-3  ">
            <label className="form-label">Pool Profile</label>
            <AsyncSelectWithController<
              PoolsStatusAttributes,
              PoolProfileAttributes
            >
              control={control}
              valueFieldName="intPkPoolProfileID"
              readOnly={readOnly || isLocked}
              searchField="poolProfileName"
              errorMessage={errors.intPkPoolProfileID?.message}
              rules={{ required: "Required" }}
              model="poolProfile"
            />
          </div>
          {!isLocked ? (
            <div className="flex-fill"></div>
          ) : (
            <div className="flex-fill d-flex flex-column justify-content-center w-100 mb-3">
              <PieChart width={350} height={200}>
                <Pie
                  data={attachedPoolsChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={true}
                  outerRadius={50}
                  fill="#8884d8"
                  dataKey="value"
                  legendType="triangle"
                >
                  {attachedPoolsChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
              <PieChart width={350} height={200}>
                <Pie
                  data={attachedPoolsCapacityChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={50}
                  dataKey="value"
                >
                  {attachedPoolsCapacityChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Label position="inside" value="1" />
                <Legend />
              </PieChart>
              <PieChart width={350} height={200}>
                <Pie
                  data={currentCapcityChartData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={50}
                  dataKey="value"
                >
                  {currentCapcityChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>
          )}
          {!isEditMode && (
            <button
              type="button"
              onClick={() => {
                if (!isLocked) {
                  const values = getValues();
                  if (
                    values.isFreeOrTrialOrPremium &&
                    values.intPkPoolProfileID
                  ) {
                    setLocked(true);
                  }
                } else {
                  setLocked(false);
                }
              }}
              className={`btn ${!isLocked && "btn-primary"} w-100`}
            >
              {isLocked ? "Back" : "Next"}
            </button>
          )}
        </div>
        <div className={`${paneClassName} border-start ${isLockedClassName}`}>
          <h3>Pools Statues</h3>
          {arePoolStatusRecordsLoading && (
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <MoonLoader size={30} />
            </div>
          )}
          <DraggablePicker id="poolStatus">
            {poolStatusRecords?.result?.rows.map(
              (poolStatusRecord, poolStatusIdx) => (
                <ListTile
                  key={poolStatusIdx}
                  primary={resolveObjectFromPath(
                    poolStatusRecord,
                    "intPkPool.poolName"
                  )}
                  secondary={`List Place Number: ${resolveObjectFromPath(
                    poolStatusRecord,
                    "listPlaceNumber"
                  )}`}
                  image={
                    <img
                      src={resolveObjectFromPath(
                        poolStatusRecord,
                        "intPkPool.poolImageURL"
                      )}
                      alt="Pool"
                    />
                  }
                  onDblClick={() => {
                    setSelectedPool(poolStatusRecord.intPkPool);
                  }}
                >
                  <div className="col-2 ">
                    <label className="form-check form-check-single form-switch px-0 ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) =>
                          togglePoolStatusEnable(
                            poolStatusRecord.intPkPoolsStatusID,
                            e.currentTarget.checked
                          )
                        }
                        checked={
                          resolveObjectFromPath(poolStatusRecord, "enable") ===
                          1
                        }
                      />
                    </label>
                  </div>
                </ListTile>
              )
            )}
          </DraggablePicker>
        </div>
        <div className={`${paneClassName} border-start ${isLockedClassName}`}>
          <h3>Pools</h3>
          <FilterPicker
            filter={poolFilter}
            onFilter={(filter) => {
              setPoolFilter(filter);
            }}
          >
            <PoolsFilterForm />
          </FilterPicker>
          {arePoolRecordsLoading && (
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <MoonLoader size={30} />
            </div>
          )}
          <DraggablePicker id="pool">
            {poolRecords?.result?.rows.map((poolRecord, poolIdx) => (
              <ListTile
                key={poolIdx}
                primary={resolveObjectFromPath(poolRecord, "poolName")}
                secondary={`Display String: ${resolveObjectFromPath(
                  poolRecord,
                  "displayString"
                )}`}
                onDblClick={() => {
                  setSelectedPool(poolRecord);
                }}
                image={
                  <img
                    src={resolveObjectFromPath(poolRecord, "poolImageURL")}
                    alt="Pool"
                  />
                }
              ></ListTile>
            ))}
          </DraggablePicker>
        </div>
      </div>
    </DragDropContext>
  );
};

export default PoolStatusPage;
