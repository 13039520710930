import {
  NodeIpsAttributes,
  NodeIpsModel,
} from "common";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import EditPage from "../../components/EditPage";
import Input from "../../components/FormElements/ControlledInput";
import { NodeAttributes } from "common";
import React from "react";
import { useFormContext } from "react-hook-form";

export const NodeIPsPage: React.FunctionComponent = () => {
  return (
    <EditPage<NodeIpsAttributes, NodeIpsModel>
      modelName="nodeIP"
      nameField="ipAddress"
      idField="intPkNodeIPID"
      title="Node IP"
      fields={[
        "intPkNodeIPID",
        "ipAddress",
        { value: (e) => <>{e.intPkNode?.name || "null"}</>, name: "nodeName" },
      ]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <NodeIPsForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const NodeIPsForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<NodeIpsAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<NodeIpsAttributes>();

  return (
    <div className="m-3">
      <Input
        register={register("ipAddress", {
          required: "Required",
        })}
        readOnly={readOnly}
        className="mb-3"
        title="IP Address"
        errorMessage={errors.ipAddress?.message}
      />
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Node</label>
        <AsyncSelectWithController<NodeIpsAttributes, NodeAttributes>
          control={control}
          valueFieldName="intPkNodeID"
          searchField="name"
          readOnly={readOnly}
          model="node"
        />
      </div>
    </div>
  );
};
