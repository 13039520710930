interface SwitchProps {
  onChange(newValue: boolean): void;
  value?: boolean;
  disabled?: boolean;
}

export function Switch(props: SwitchProps) {
  return (
    <label className="form-check form-check-single form-switch px-0 ">
      <input
        className="form-check-input"
        type="checkbox"
        disabled={props.disabled}
        onChange={(event) => props.onChange(event.currentTarget.checked)}
        checked={props.value}
      />
    </label>
  );
}
