import { AppAttributes, AppCategoryAttributes } from "common";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import EditPage from "../../components/EditPage";
import Input from "../../components/FormElements/ControlledInput";
import { useFormContext } from "react-hook-form";

type AppModel = AppAttributes & {
  intPkAppCategory: AppCategoryAttributes;
};

export const AppsPage: React.FC = () => {
  return (
    <EditPage<AppAttributes, AppModel>
      modelName="app"
      fields={[
        { name: "Icon", type: "image", key: "appImageIconUrl" },
        "appName",
        "androidPackageID",
        "iOSPackageID",
        "intPkAppCategory.categoryName",
      ]}
      idField="intPkAppID"
      nameField="appName"
      title="App"
      renderForm={({ isEditMode, model, readOnly }) => (
        <AppsForm readOnly={readOnly} isEditMode={isEditMode} model={model} />
      )}
    />
  );
};

const AppsForm = ({ readOnly }: CRUDFormProps<AppAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<AppAttributes>();

  return (
    <div className="m-3">
      <div className="d-flex flex-row mb-3">
        <Input
          register={register("appName", { required: "Required" })}
          errorMessage={errors.appName?.message}
          title="App Name"
          readOnly={readOnly}
          />
        <Input
          register={register("androidPackageID", { required: "Required" })}
          errorMessage={errors.androidPackageID?.message}
          title="Android Package ID"
          readOnly={readOnly}
          className="mx-3"
        />
        <Input
          register={register("iOSPackageID", { required: "Required" })}
          errorMessage={errors.iOSPackageID?.message}
          title="iOS Package ID"
          readOnly={readOnly}
        />
      </div>
      <Input
        register={register("appImageIconUrl", { required: "Required" })}
        errorMessage={errors.appName?.message}
        title="App Icon URL"
        readOnly={readOnly}
      />
      <div className="form-group mt-3 flex-fill ">
        <label className="form-label">Category</label>
        <AsyncSelectWithController<AppAttributes, AppCategoryAttributes>
          control={control}
          valueFieldName="intPkAppCategoryID"
          searchField="categoryName"
          imageField="categoryIconUrl"
          readOnly={readOnly}
          model="appCategory"
        />
      </div>
    </div>
  );
};
