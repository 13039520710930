import "./Dashboard.scss";

import React, { useContext, useEffect, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { navLinks } from "../constants";

import { AppContext } from "../libs/state";
import Page from "../components/Page";

export default function Dashboard() {
  const history = useHistory();
  const context = useContext(AppContext);
  const allowedTableNames = context.allowedTables.map(
    (e) => e.intPkTable?.tableName
  );

  let { path } = useRouteMatch();

  const firstLink = useMemo(
    () =>
      navLinks.filter(
        (e) => "/dashboard" + allowedTableNames.includes(e.modelName)
      )?.[0]?.url,
    [navLinks, allowedTableNames]
  );

  useEffect(() => {
    !context.isAuthenticated && history.replace("/login");
  }, [history, context]);

  if (!context.isAuthenticated) {
    return null;
  }

  return (
    <Page>
      <Switch>
        {navLinks.flatMap((navLink) => {
          const routes: React.ReactElement[] = [];

          if (allowedTableNames.includes(navLink.modelName)) {
            const Component = navLink.pageComponent;
            routes.push(
              <Route path={`${path}${navLink.url}`} exact>
                <Component />
              </Route>
            );
          }

          if (navLink.children) {
            routes.push(
              ...navLink.children
                .filter((e) => allowedTableNames.includes(e.modelName))
                .map((child) => {
                  const Component = child.pageComponent;
                  return (
                    <Route path={`${path}${navLink.url}${child.url}`} exact>
                      <Component />
                    </Route>
                  );
                })
            );
          }

          return routes;
        })}
        {firstLink && (
          <Route path="/dashboard" exact>
            <Redirect to={firstLink} />
          </Route>
        )}
        <Route path="*">
          <div className="page-wrapper">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "90vh" }}
            >
              <h1>Not found, are you sure you have access?</h1>
            </div>
          </div>
        </Route>
      </Switch>
    </Page>
  );
}
