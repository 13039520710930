import React, { memo, useMemo } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { NodeStatusMap } from "../../constants";
import useModelAggregationQuery from "../../libs/hooks/useModelAggregationQuery";
import { usePermissions } from "../../libs/hooks/usePermissions";

const findWithName = (arr: any[] | null | undefined, name: string) =>
  arr?.find((item: any) => item.name === name);

export function NodesDashboard() {
  const nodePermission = usePermissions("node");
  const ssPermission = usePermissions("vezSsNode");

  return (
    <div className="page-wrapper">
      <div className="container-xl">
        {nodePermission.allowRead && (
          <>
            <h2>Cisco Nodes</h2>
            <NodeCharts />
          </>
        )}
        {ssPermission.allowRead && (
          <>
            <h2 className="mt-3">Shadowsocks Nodes</h2>
            <SsCharts />
          </>
        )}
      </div>
    </div>
  );
}

const SsCharts = () => {
  const { data } = useModelAggregationQuery({
    model: "vezSsNode",
  });

  const enabledDisable = useMemo(
    () => [
      ...(data?.result?.filter((e) => ["Enabled"].includes(e.name)) || []),
      {
        name: "Disabled",
        value:
          (findWithName(data?.result, "Total")?.value || 0) -
          (findWithName(data?.result, "Enabled")?.value || 0),
      },
    ],
    [data]
  );

  const nodeStatus = useMemo(
    () =>
      findWithName(data?.result, "Service Status")?.value?.map((each: any) => ({
        name: each._id,
        fname: NodeStatusMap[each._id]?.text || each._id,
        value: each.count,
      })) || [],
    [data]
  );

  return (
    <div className="mt-3 d-flex gap-3">
      <div className="card">
        <div className="card-header">
          <h3>Routing</h3>
        </div>
        <div className="card-body">
          <EPieChart
            width={275}
            pieChartData={enabledDisable}
            getColorForName={(name: string) =>
              name === "Enabled" ? "var(--tblr-green)" : "var(--tblr-red)"
            }
          />
        </div>
      </div>
      <div className="card flex-fill">
        <div className="card-header">
          <h3>Concurrent Users</h3>
        </div>
        <div className="card-body d-flex flex-wrap">
          <div className="p-3">
            <h1 className="mb-0">
              {findWithName(data?.result, "Consumed Capacity")?.value}
            </h1>
            Users
          </div>
          <div className="p-3">
            <h1 className="mb-0">
              {findWithName(data?.result, "Total")?.value}
            </h1>
            Total
          </div>
          <div className="p-3">
            <h1 className="mb-0">
              {findWithName(data?.result, "Enabled")?.value}
            </h1>
            Enabled
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3>Service Status </h3>
        </div>
        <div className="card-body">
          <EPieChart
            width={275}
            nameKey="fname"
            pieChartData={nodeStatus}
            getColorForName={(id: string) => NodeStatusMap[id]?.color}
          />
        </div>
      </div>
    </div>
  );
};

const NodeCharts = () => {
  const { data } = useModelAggregationQuery({
    model: "node",
  });

  const enabledDisable = useMemo(
    () => [
      ...(data?.result?.filter((e) => ["Enabled"].includes(e.name)) || []),
      {
        name: "Disabled",
        value:
          (findWithName(data?.result, "Total")?.value || 0) -
          (findWithName(data?.result, "Enabled")?.value || 0),
      },
    ],
    [data]
  );

  const enabledCapcity = useMemo(
    () => [
      {
        name: "Enabled",
        value: findWithName(data?.result, "Enabled Capacity")?.value || 0,
      },
      {
        name: "Disabled",
        value:
          (findWithName(data?.result, "Total Capacity")?.value || 0) -
          (findWithName(data?.result, "Enabled Capacity")?.value || 0),
      },
    ],
    [data]
  );

  const consumedCapcity = useMemo(
    () => [
      {
        name: "Consumed",
        value: findWithName(data?.result, "Consumed Capacity")?.value || 0,
      },
      {
        name: "Available",
        value:
          (findWithName(data?.result, "Enabled Capacity")?.value || 0) -
          (findWithName(data?.result, "Consumed Capacity")?.value || 0),
      },
    ],
    [data]
  );

  const nodeStatus = useMemo(
    () =>
      findWithName(data?.result, "Service Status")?.value?.map((each: any) => ({
        fname: NodeStatusMap[each._id]?.text || each._id,
        name: each._id,
        value: each.count,
      })) || [],
    [data]
  );

  return (
    <div className="d-flex flex-row gap-3">
      <div className="card">
        <div className="card-header">
          <h3>Routing</h3>
        </div>
        <div className="card-body">
          <EPieChart
            width={275}
            pieChartData={enabledDisable}
            getColorForName={(name: string) =>
              name === "Enabled" ? "var(--tblr-green)" : "var(--tblr-red)"
            }
          />
          <div className="text-center">
            <b>Total</b>:{" "}
            <span>{findWithName(data?.result, "Total")?.value}</span>
          </div>
        </div>
      </div>
      <div className="card flex-fill">
        <div className="card-header">
          <h3>Capacity</h3>
        </div>
        <div className="card-body">
          <div className="d-flex gap-3">
            <div>
              <EPieChart
                width={275}
                pieChartData={enabledCapcity}
                getColorForName={(name: string) =>
                  name === "Enabled" ? "var(--tblr-green)" : "var(--tblr-red)"
                }
              />
              <div className="text-center">
                <b>Total </b>:{" "}
                <span>
                  {findWithName(data?.result, "Total Capacity")?.value}
                </span>
              </div>
            </div>
            <div>
              <EPieChart
                width={275}
                pieChartData={consumedCapcity}
                getColorForName={(name: string) => {
                  if (name === "Consumed") {
                    return "var(--tblr-blue)";
                  } else {
                    return "var(--tblr-green)";
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3>Service Status </h3>
        </div>
        <div className="card-body">
          <EPieChart
            width={275}
            pieChartData={nodeStatus}
            nameKey="fname"
            getColorForName={(id: string) => NodeStatusMap[id]?.color}
          />
        </div>
      </div>
    </div>
  );
};

export const EPieChart = memo(
  ({ pieChartData, width = 225, getColorForName, nameKey = "name" }: any) => {
    return (
      <div>
        <div className="d-flex">
          <PieChart width={width} height={250}>
            <Pie
              data={pieChartData}
              cx="50%"
              cy="39%"
              nameKey={nameKey}
              outerRadius={60}
              dataKey="value"
              legendType="triangle"
            >
              {pieChartData.map((datapoint: any, index: number) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={getColorForName?.(datapoint.name)}
                  />
                );
              })}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>
      </div>
    );
  }
);
