import {
  BrandAttributes,
  GoogleIapProductAttributes,
  GoogleIapProductModel,
} from "common";
import { useFormContext } from "react-hook-form";
import EditPage from "../../components/EditPage";
import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import Input from "../../components/FormElements/ControlledInput";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";

export const GoogleIAPProductsPage: React.FunctionComponent = () => {
  return (
    <EditPage<GoogleIapProductAttributes, GoogleIapProductModel>
      modelName="googleIAPProduct"
      nameField="intPkGoogleIAPProductID"
      idField="intPkGoogleIAPProductID"
      title="Google IAP Products"
      fields={[
        "intPkGoogleIAPProductID",
        "productID",
        "intPkBrand.brandName",
        "months",
      ]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <GoogleIAPProductForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const GoogleIAPProductForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<GoogleIapProductAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<GoogleIapProductAttributes>();

  return (
    <div className="m-3">
      <Input
        register={register("productID", {
          required: "Required",
        })}
        readOnly={readOnly}
        className="mb-3"
        title="Product ID"
        errorMessage={errors.productID?.message}
      />
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">Brand</label>
        <AsyncSelectWithController<GoogleIapProductAttributes, BrandAttributes>
          control={control}
          valueFieldName="intPkBrandID"
          searchField="brandName"
          readOnly={readOnly}
          model="brand"
        />
      </div>
      <Input
        register={register("months", {
          required: "Required",
        })}
        readOnly={readOnly}
        className="mb-3"
        title="Months"
        errorMessage={errors.months?.message}
      />
    </div>
  );
};
