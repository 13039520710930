import {
  GoogleIapSubscriptionsChildModel,
  GoogleIapSubscriptionsChildAttributes,
} from "common";
import EditPage from "../../components/EditPage";
import { NullComponent } from "../../components/NullComponent";

export const GoogleIAPSubscriptionsChildPage: React.FunctionComponent = () => {
  return (
    <EditPage<
    GoogleIapSubscriptionsChildAttributes,
      GoogleIapSubscriptionsChildModel
    >
      modelName="googleIAPSubscriptionChild"
      nameField="intPkGoogleIAPSubscriptionChildID"
      idField="intPkGoogleIAPSubscriptionChildID"
      title="Google IAP Subscriptions Children"
      fields={[
        "orderId",
        "countryCode",
        {
          name: "Expiry Date",
          type: "date",
          key: "intPkGoogleIAPSubscription.expiryDate",
        },
      ]}
      renderForm={(_) => <NullComponent />}
      isRecordDeletable={() => false}
      isRecordEditable={() => false}
      showCreateButton={false}
    />
  );
};
