import { ProxyListAttributes, ProxyNodeModel } from "common";
import { useState } from "react";
import { useCallback } from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import DraggablePicker from "../../components/DraggablePicker";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import Input from "../../components/FormElements/ControlledInput";
import { RadioSelectWithController } from "../../components/FormElements/RadioSelect";
import ListTile from "../../components/ListTile";
import CreateEditModal from "../../components/Modals/CreateEditModal";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { ProxyNodeFilter, ProxyNodesForm } from "./ProxyNodes";

export const ProxyListPage: React.FunctionComponent = () => {
  return (
    <EditPage<ProxyListAttributes, ProxyListAttributes>
      modelName="proxyList"
      nameField="proxyListName"
      idField="intPkProxyListID"
      title="Proxy List"
      createEditModalSize="xl"
      fields={[
        "intPkProxyListID",
        "proxyListName",
        { key: "enable", type: "switch" },
      ]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <ProxyListForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const ProxyListForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<ProxyListAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ProxyListAttributes>();

  const [busy, setBusy] = useState(false);
  const [attachedProxyNodeFilter, setAttachedProxyNodeFilter] = useState({});
  const [proxyNodeFilter, setProxyNodeFilter] = useState({});
  const [selectedProxyNode, setSelectedProxyNode] = useState<ProxyNodeModel>();

  const { data: attachedProxyNodes, refetch: refetchAttachedProxyNodes } =
    useAuthorizedQuery<ProxyNodeModel>({
      model: "proxyNode",
      limit: 24,
      filter: {
        ...attachedProxyNodeFilter,
        // @ts-ignore
        proxy_node_m2m_proxy_lists: {
          some: {
            intPkProxyListID: model?.intPkProxyListID,
          },
        },
      },
      enabled: isEditMode,
    });
  const { data: proxyNodes, refetch: refetchProxyNodes } =
    useAuthorizedQuery<ProxyNodeModel>({
      model: "proxyNode",
      filter: {
        ...proxyNodeFilter,
        proxy_node_m2m_proxy_lists: {
          // @ts-ignore
          none: {
            intPkProxyListID: model?.intPkProxyListID,
          },
        },
      },
      enabled: isEditMode,
    });

  const fetcher = useFetcher({});

  const renderProxyNodeTile = (proxyNode: ProxyNodeModel) => {
    return (
      <ListTile
        primary={proxyNode.proxyNodeName}
        secondary={`${proxyNode.proxyIP}:${proxyNode.proxyPort} / ${proxyNode.proxyDomain}`}
        image={
          <img
            src={proxyNode.intPkDataCenter?.intPkCountry?.flagImageUrl}
            alt="countryFlag"
          />
        }
        onDblClick={() => setSelectedProxyNode(proxyNode)}
      >
        <div className="col-2 align-items-center d-flex justify-content-end">
          <span
            className={`badge align-left ${
              !!proxyNode.enable ? "bg-success" : "bg-danger"
            }`}
          ></span>
        </div>
      </ListTile>
    );
  };

  const addRelation = async (proxyNode: ProxyNodeModel) => {
    setBusy(true);
    try {
      await fetcher({
        method: "post",
        url: "/api/v1/data/proxyNodeM2MProxyList/create",
        data: {
          intPkProxyListID: model?.intPkProxyListID,
          intPkProxyNodeID: proxyNode.intPkProxyNodeID,
        },
        successMessage: "Successfully attached to proxy list",
      });
      refetchAttachedProxyNodes();
      refetchProxyNodes();
    } catch (e: any) {}
    setBusy(false);
  };

  const removeRelation = async (relationID: number) => {
    setBusy(true);

    try {
      await fetcher({
        method: "delete",
        successMessage: "Successfully removed from proxy list",
        url: `/api/v1/data/proxyNodeM2MProxyList/${relationID}`,
      });
      refetchAttachedProxyNodes();
      refetchProxyNodes();
    } catch (e: any) {}
    setBusy(false);
  };

  const handleDragEnd = useCallback(
    async (result: DropResult, provided: ResponderProvided) => {
      if (!result.destination || busy) return;

      if (
        result.destination.droppableId === "connectedProxyNodes" &&
        result.source.droppableId === "proxyNodes"
      ) {
        const proxyNodesRecords = proxyNodes?.result?.rows;
        const selectedProxyNode = proxyNodesRecords?.[result.source.index];

        if (selectedProxyNode) {
          await addRelation(selectedProxyNode);
        }
      } else if (
        result.source.droppableId === "connectedProxyNodes" &&
        result.destination.droppableId === "proxyNodes"
      ) {
        const attachedProxyNodesRecords = attachedProxyNodes?.result?.rows;
        const selectedProxyNode =
          attachedProxyNodesRecords?.[result.source.index];

        const relationID = selectedProxyNode?.proxy_node_m2m_proxy_lists?.find(
          (e) =>
            e.intPkProxyListID === model?.intPkProxyListID &&
            model.intPkProxyListID
        )?.intPkProxyNodeM2MProxyListID;

        if (relationID) {
          await removeRelation(relationID);
        }
      }
    },
    [attachedProxyNodes, proxyNodes]
  );

  const renderPanes = () => {
    const panes: React.ReactElement[] = [];
    let paneClassName =
      "p-3 mw-33 flex-fill d-flex flex-column align-items-stretch mh-80vh overflow-auto";

    panes.push(
      <div className={paneClassName}>
        <Input
          register={register("proxyListName", {
            required: "Required",
            validate: (value) => {
              if (!value?.match(/^[A-Za-z][A-Za-z0-9_]*$/s)) {
                return "Please correct the format, only '_', numbers and alphabets are allowed. First is always alphabet";
              }
            },
          })}
          readOnly={readOnly}
          title="Proxy List Name"
          className="mb-3"
          errorMessage={errors.proxyListName?.message}
        />
        <CheckboxTileGroup>
          <CheckboxTile control={control} name="enable" title="Enable" />
        </CheckboxTileGroup>
      </div>
    );

    if (isEditMode) {
      paneClassName += " minh-80vh";

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Attached Proxy Nodes</h3>{" "}
          <FilterPicker
            filter={attachedProxyNodeFilter}
            onFilter={(filter) => setAttachedProxyNodeFilter(filter)}
          >
            <ProxyNodeFilter />
          </FilterPicker>
          <DraggablePicker id={"connectedProxyNodes"}>
            {attachedProxyNodes?.result?.rows.map(renderProxyNodeTile)}
          </DraggablePicker>
        </div>
      );

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Available Proxy Nodes</h3>
          <FilterPicker
            filter={proxyNodeFilter}
            onFilter={(filter) => setProxyNodeFilter(filter)}
          >
            <ProxyNodeFilter />
          </FilterPicker>
          <DraggablePicker id={"proxyNodes"}>
            {proxyNodes?.result?.rows.map(renderProxyNodeTile)}
          </DraggablePicker>
        </div>
      );
    }

    return panes;
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <CreateEditModal
        model={selectedProxyNode}
        idField="intPkProxyNodeID"
        nameField="proxyNodeName"
        modelName="proxyNode"
        onHide={() => {
          setSelectedProxyNode(undefined);
          refetchAttachedProxyNodes();
          refetchProxyNodes();
        }}
        open={!!selectedProxyNode}
        renderForm={({ isEditMode, model, readOnly }) => {
          return (
            <ProxyNodesForm
              readOnly={readOnly}
              model={model}
              isEditMode={isEditMode}
            />
          );
        }}
      />
      <div className="d-flex flex-column align-items-stretch flex-lg-row">
        {renderPanes()}
      </div>
    </DragDropContext>
  );
};

export const ProxyListFilter = () => {
  const methods = useFormContext();

  return (
    <div className="m-3">
      <Input
        register={methods.register("or._0.proxyListName")}
        title="Search by Name"
        className="mb-3"
      />
      <RadioSelectWithController
        control={methods.control}
        name="enable"
        title="Enable Status"
        options={[
          { label: "All", value: undefined },
          { label: "Enabled", value: 1 },
          { label: "Disabled", value: 0 },
        ]}
      />
    </div>
  );
};
