import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../libs/state";

export default function Splash() {
  const history = useHistory();
  const context = useContext(AppContext);

  if (context.isAuthenticated) {
    history.push("/dashboard");
  } else {
    history.push("/login");
  }

  return <div></div>;
}
