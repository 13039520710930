import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface InputProps {
  register: UseFormRegisterReturn;
  errorMessage?: string;
  className?: string;
  type?: string;
  title: string;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: string | number;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
}

export default function Input({
  register,
  title,
  type,
  errorMessage,
  defaultValue,
  className,
  readOnly = false,
  disabled = false,
  onKeyDown,
}: React.PropsWithRef<InputProps>) {

  return (
    <div className={`form-group flex-fill ${className || ""}`}>
      <label className="form-label">{title}</label>
      <input
        type={type || "text"}
        className={`form-control ${errorMessage ? "is-invalid" : ""}`}
        placeholder={`Enter ${title}`}
        {...register}
        defaultValue={defaultValue}
        readOnly={readOnly}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
      {errorMessage && <span className="invalid-feedback">{errorMessage}</span>}
    </div>
  );
}
