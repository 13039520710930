import CustomModal, { ModalProps } from "./Modal";
import { useEffect, useState } from "react";

export interface ConfirmModalProps {
  onConfirmed?: () => void;
  onDenied?: () => void;
  title?: string;
  description?: string;
  show?: boolean;
  theme?: ModalProps["theme"];
}

export default function ConfirmModal({
  onConfirmed,
  onDenied,
  description = "",
  title = "Are you sure?",
  theme = "danger",
  show = false,
}: React.PropsWithChildren<ConfirmModalProps>) {
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<"INITIAL" | "ALLOWED" | "DENIED">(
    "INITIAL"
  );

  useEffect(() => {
    if (show && confirmed === "INITIAL") {
      setShowWarningModal(true);
    }
  }, [show, setShowWarningModal, confirmed]);

  useEffect(() => {
    if (confirmed === "DENIED") {
      onDenied && onDenied();
      setConfirmed("INITIAL");
    } else if (confirmed === "ALLOWED") {
      onConfirmed && onConfirmed();
    }
  }, [setConfirmed, confirmed, onDenied, onConfirmed]);

  return (
    <CustomModal
      onHide={() => {
        setConfirmed("DENIED");
        setShowWarningModal(false);
      }}
      show={showWarningModal}
      size="sm"
      onPrimaryButtonClick={() => {
        setConfirmed("ALLOWED");
        setShowWarningModal(false);
      }}
      primaryButtonText="Confirm"
      title="WARNING!"
      theme={theme}
    >
      <div className="m-3">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </CustomModal>
  );
}
