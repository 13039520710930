import { VezSsPasswordAttributes } from "common";
import { useFormContext } from "react-hook-form";
import EditPage from "../../components/EditPage";
import Input from "../../components/FormElements/ControlledInput";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";

export const VezSSPasswordsPage: React.FunctionComponent = () => {
  return (
    <EditPage<VezSsPasswordAttributes, VezSsPasswordAttributes>
      modelName="vezSsPassword"
      nameField="port"
      idField="intPkVezSSPasswordID"
      title="VezSS Passwords"
      fields={["port", "password"]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <VezSSPasswordForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const VezSSPasswordForm = ({
  readOnly,
}: CRUDFormProps<VezSsPasswordAttributes>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<VezSsPasswordAttributes>();

  return (
    <div className="m-3">
      <div className="flex-fill row gap-1">
        <div className="col">
          <Input
            register={register("port", {
              required: "Required",
              min: { value: 1, message: "Should be greater than 0" },
              max: { value: 65536, message: "Should be less than 65536" },
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Port"
            type="number"
            errorMessage={errors.port?.message}
          />
        </div>
        <div className="col">
          <Input
            register={register("password", {
              required: "Required",
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Password"
            errorMessage={errors.password?.message}
          />
        </div>
      </div>
    </div>
  );
};
