import { FieldPath, useController } from "react-hook-form";
import {
  GenericFormInputProps,
  GenerticControlledInputProps,
  InputValueType,
} from "../../interfaces/FormTypes";

interface BaseOneStateInputProps<T> {
  checked?: boolean;
  defaultChecked?: boolean;
  type?: "radio" | "checkbox";
  useCheckedStateAsValue?: boolean;

  onSelected?: (val: T | undefined | boolean) => void;
}

type RadioProps<T> = Omit<BaseOneStateInputProps<T>, "type"> &
  GenericFormInputProps<T>;

type CheckBoxProps<T> = Omit<BaseOneStateInputProps<T>, "type"> &
  GenericFormInputProps<T>;

type CheckBoxWithControllerProps<K, T> = CheckBoxProps<T> &
  GenericFormInputProps<T, FieldPath<K>> &
  GenerticControlledInputProps<K>;

export function Radio<T>(props: RadioProps<T>) {
  return <BaseOneStateInput {...props} type="radio" />;
}

export function CheckBox<T>(props: CheckBoxProps<T>) {
  return <BaseOneStateInput {...props} type="checkbox" />;
}

export function CheckBoxWithController<K, T extends InputValueType>({
  control,
  name,
  defaultValue,
  useCheckedStateAsValue = false,
  onSelected,
  rules,
  value,
  disabled,
  readOnly,
}: CheckBoxWithControllerProps<K, T>) {
  const {
    field: { value: currentOption, onChange },
  } = useController({
    name,
    control,
    defaultValue: defaultValue as any,
    rules,
  });

  return (
    <CheckBox<T>
      name={name}
      value={value}
      useCheckedStateAsValue={useCheckedStateAsValue}
      onSelected={(value) => {
        onChange(value);
        onSelected && onSelected(value);
      }}
      readOnly={readOnly}
      disabled={disabled}
      checked={!!currentOption}
    />
  );
}

export function BaseOneStateInput<T>({
  name,
  checked,
  defaultChecked,
  readOnly,
  onSelected,
  disabled,
  useCheckedStateAsValue = false,
  type = "checkbox",
  value,
}: BaseOneStateInputProps<T> & GenericFormInputProps<T>) {
  return (
    <input
      className="form-check-input"
      name={name}
      type={type}
      checked={checked}
      readOnly={readOnly}
      defaultChecked={defaultChecked}
      disabled={disabled}
      onChange={(event) => {
        const isChecked = event.currentTarget.checked;

        if (onSelected) {
          if (useCheckedStateAsValue) {
            onSelected(isChecked);
          } else {
            if (isChecked) {
              onSelected(value);
            } else {
              onSelected(undefined);
            }
          }
        }
      }}
    />
  );
}
