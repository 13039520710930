import { FunctionComponent } from "react";
import { AlertComponentProps } from "react-alert";

const AlertTemplate: FunctionComponent<AlertComponentProps> = ({
  message,
  options,
  close,
}) => {
  const type = options.type === "error" ? "danger" : options.type;

  return (
    <div
      className={`alert alert-important alert-${type} alert-dismissible`}
      role="alert"
    >
      <div className="d-flex">
        <div>{message}</div>
      </div>
      <a
        className="btn-close btn-close-white"
        aria-label="close"
        onClick={(_) => {
          close();
        }}
      ></a>
    </div>
  );
};

export default AlertTemplate;
