import { FieldPath } from "react-hook-form";

export const resolveObjectFromPath = <T extends Record<string, any>>(
  obj: T,
  path: FieldPath<T>
) => {
  if (path.includes(".")) {
    const parts = path.split(".");
    let value: any = obj;

    for (const part of parts) {
      if (value === undefined || value === null) break;
      value = value[part];
    }
    return value;
  } else {
    return obj[path];
  }
};
