import { Draggable, Droppable } from "react-beautiful-dnd";

import React from "react";

export interface DraggablePickerProps {
  id: string;
  isDropDisabled?: boolean;
  isDragDisabled?: boolean;
}

const DraggablePicker = ({
  id,
  children,
  isDropDisabled = false,
  isDragDisabled = false,
}: React.PropsWithChildren<DraggablePickerProps>) => {
  return (
    <Droppable droppableId={id} isDropDisabled={isDropDisabled}>
      {(provided, _) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className="flex-fill"
        >
          {React.Children.map(children, (child, index) => {
            return (
              <Draggable
                index={index}
                draggableId={`${id}-${index}`}
                isDragDisabled={isDragDisabled}
              >
                {(provided, _) => (
                  <div
                    className="my-2"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {child}
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DraggablePicker;
