interface FormCheckContainerProps {
  label?: string;
  inline?: boolean;
}

export function FormCheckContainer({
  children,
  label,
  inline = true,
}: React.PropsWithChildren<FormCheckContainerProps>) {
  let className = `form-check flex-fill`;

  if (inline) {
    className += ` form-check-inline`;
  }

  return (
    <label className={className}>
      {children}
      {label && <span className="form-check-label">{label}</span>}
    </label>
  );
}
