import { AxiosRequestConfig, Method } from "axios";
import { types, useAlert } from "react-alert";

import { AppContext } from "../state";
import { QueryKey } from "react-query";
import { queryClient } from "../..";
import { useContext } from "react";

export default function useFetcher({
  alerts = true,
  invalidateCache = true,
  queryKey,
}: {
  alerts?: boolean;
  invalidateCache?: boolean;
  queryKey?: QueryKey;
}) {
  const context = useContext(AppContext);
  const alertManager = useAlert();

  return async <T>({
    method,
    url,
    data,
    successMessage,
    options = {},
    shouldInvalidateCache = invalidateCache,
  }: {
    url: string;
    method: Method;
    data?: object;
    successMessage?: string;
    shouldInvalidateCache?: boolean;
    options?: AxiosRequestConfig;
  }): Promise<T> => {
    let loadingAlert;
    try {
      if (alerts) {
        const loadingMessage = "Loading...";
        loadingAlert = alertManager.alerts.find(
          (e) => e.message === loadingMessage
        );
        if (!loadingAlert)
          loadingAlert = alertManager.show(loadingMessage, { timeout: 0 });
      }
      const response = await context.fetcher.request({
        url,
        method,
        data,
        ...options,
      });

      // clear cache, so mutation on database can be shown
      if (shouldInvalidateCache) queryClient.invalidateQueries(queryKey);

      if (alerts) {
        if (loadingAlert) {
          loadingAlert.close();
        }

        successMessage &&
          alertManager.show(successMessage, {
            type: types.SUCCESS,
          });
      }

      return response.data as T;
    } catch (err: any) {
      let message = err.response?.data.message;
      let is401 = err.response?.status === 401;
      if (is401) {
        message = "Your session has expired, logging out..";
        context.logout();
      }

      if (!message && err instanceof Error) {
        message = err.message;
      }

      if (alerts || is401) {
        if (loadingAlert) {
          loadingAlert.close();
        }
        alertManager.show(message, {
          type: types.ERROR,
        });
      }

      throw err;
    }
  };
}
