import React from "react";

interface ListTileProps {
  primary: string | React.ReactElement;
  secondary?: string | React.ReactElement;
  image?: React.ReactElement;
  onDblClick?: React.MouseEventHandler<HTMLDivElement>;
}

export default function ListTile({
  primary,
  children,
  secondary,
  image,
  onDblClick,
}: React.PropsWithChildren<ListTileProps>) {
  return (
    <div className="card my-2" onDoubleClick={onDblClick}>
      <div className="card-body">
        <div className="row align-items-center justify-content-space-between">
          {image && <div className="col-2">{image}</div>}
          <div className="col">
            <div className="row">
              <div className="col">{primary}</div>
            </div>
            {secondary && (
              <div className="row">
                <div className="col">
                  <span className="text-muted text-sm">{secondary}</span>
                </div>
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
