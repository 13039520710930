import React, { useState } from "react";

import { CRUDModalProps } from "../../interfaces/CURDModalProps";
import CustomModal from "./Modal";
import useFetcher from "../../libs/hooks/useFetcher";

export default function DeleteModal<T extends Record<string, any>>({
  model: modal,
  open,
  onHide: onCancel,
  modelName,
  idField,
  modalSize = "sm",
  nameField,
}: React.PropsWithChildren<CRUDModalProps<T>>) {
  const [busy, setBusy] = useState(false);
  const fetcher = useFetcher({ queryKey: `${modelName}data` });

  const deleteModal = async () => {
    setBusy(true);
    try {
      modal &&
        (await fetcher({
          url: `/api/v1/data/${modelName}/${modal[idField]}`,
          method: "delete",
          successMessage: "Successfully deleted",
        }));
      onCancel();
    } catch (e: any) {}
    setBusy(false);
  };

  return (
    <CustomModal
      primaryButtonText="Delete"
      onHide={onCancel}
      loading={busy}
      title={`Delete "${modal ? modal[nameField] : ""}"`}
      size={modalSize}
      show={open}
      theme="danger"
      onPrimaryButtonClick={deleteModal}
    >
      <div className="m-3">
        <p>Are you sure you want to delete?</p>
      </div>
    </CustomModal>
  );
}
