import {
  BaseField,
  GenericFormInputProps,
  GenerticControlledInputProps,
} from "../../interfaces/FormTypes";
import { FieldPath, useController } from "react-hook-form";

import { FormCheckContainer } from "./FormCheckContainer";
import { Radio } from "./OneStateInputs";
import React from "react";

interface BaseRadioSelectProps<T> {
  options: BaseField<T>[];
  title?: string;
  onOptionSelect?: (val: T | undefined | boolean) => void;
}

interface RadioSelectProps<T>
  extends GenericFormInputProps<T>,
    BaseRadioSelectProps<T> {}

type RadioSelectWithControllerProps<
  K,
  T
> = GenericFormInputProps<T, FieldPath<K>> &
  BaseRadioSelectProps<T> &
  GenerticControlledInputProps<K>;

export default function RadioSelect<T>({
  value,
  options,
  title,
  readOnly,
  name,
  onOptionSelect,
  disabled,
}: React.PropsWithChildren<RadioSelectProps<T>>) {
  const renderOption = (option: BaseField<T>) => {
    return (
      <FormCheckContainer label={option.label} inline>
        <Radio
          name={name}
          value={option.value}
          checked={option.value === value}
          readOnly={readOnly}
          disabled={disabled}
          onSelected={onOptionSelect}
        />
      </FormCheckContainer>
    );
  };

  return (
    <div>
      {title && <div className="form-label">{title}</div>}
      <div className="d-flex flex-row justify-comtent-space-between flex-wrap">
        {options.map(renderOption)}
      </div>
    </div>
  );
}

export function RadioSelectWithController<K, T>({
  control,
  name,
  rules,
  options,
  disabled,
  onOptionSelect,
  defaultValue,
  readOnly,
  title,
}: RadioSelectWithControllerProps<K, T>) {
  const {
    field: { value, onChange, name: nameByController },
  } = useController({
    name,
    rules,
    control,
    defaultValue: defaultValue as any,
  });

  return (
    <RadioSelect<T>
      name={nameByController}
      options={options}
      disabled={disabled}
      readOnly={readOnly}
      title={title}
      value={value as T}
      onOptionSelect={(value) => {
        onChange(value);
        onOptionSelect && onOptionSelect(value);
      }}
    />
  );
}
