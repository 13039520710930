import EditPage from "../../components/EditPage";
import { FunctionComponent, useCallback, useState } from "react";
import Input from "../../components/FormElements/ControlledInput";
import { ProxyListModel, VezgateProxyProfileAttributes } from "common";
import { useFormContext } from "react-hook-form";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import CreateEditModal from "../../components/Modals/CreateEditModal";
import { ProxyListFilter, ProxyListForm } from "./ProxyList";
import ListTile from "../../components/ListTile";
import useFetcher from "../../libs/hooks/useFetcher";
import DraggablePicker from "../../components/DraggablePicker";
import { FilterPicker } from "../../components/FilterPicker";

const VezgateProxyProfilePage: FunctionComponent = () => {
  return (
    <EditPage<VezgateProxyProfileAttributes, VezgateProxyProfileAttributes>
      modelName="vezgateProxyProfile"
      idField="intPkVezgateProxyProfileID"
      createEditModalSize="xl"
      nameField="profileName"
      fields={["intPkVezgateProxyProfileID", "profileName"]}
      renderForm={({ readOnly, isEditMode, model }) => (
        <VezgateProxyProfileForm
          readOnly={readOnly}
          model={model}
          isEditMode={isEditMode}
        />
      )}
    />
  );
};

export const VezgateProxyProfileForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<VezgateProxyProfileAttributes>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<VezgateProxyProfileAttributes>();

  const [busy, setBusy] = useState(false);
  const [attachedProxyListFilter, setAttachedProxyListFilter] = useState({});
  const [proxyListFilter, setProxyListFilter] = useState({});
  const [selectedProxyList, setSelectedProxyList] = useState<ProxyListModel>();

  const { data: attachedProxyLists, refetch: refetchAttachedProxyLists } =
    useAuthorizedQuery<ProxyListModel>({
      model: "proxyList",
      limit: 24,
      filter: {
        ...attachedProxyListFilter,
        // @ts-ignore
        vezgate_proxy_profile_m2m_proxy_lists: {
          some: {
            intPkVezgateProxyProfileID: model?.intPkVezgateProxyProfileID,
          },
        },
      },
      enabled: isEditMode,
    });
  const { data: proxyLists, refetch: refetchProxyLists } =
    useAuthorizedQuery<ProxyListModel>({
      model: "proxyList",
      filter: {
        ...proxyListFilter,
        vezgate_proxy_profile_m2m_proxy_lists: {
          // @ts-ignore
          none: {
            intPkVezgateProxyProfileID: model?.intPkVezgateProxyProfileID,
          },
        },
      },
      enabled: isEditMode,
    });

  const fetcher = useFetcher({});

  const renderProxyNodeTile = (proxyList: ProxyListModel) => {
    return (
      <ListTile
        primary={proxyList.proxyListName}
        onDblClick={() => setSelectedProxyList(proxyList)}
      >
        <div className="col-2 align-items-center d-flex justify-content-end">
          <span
            className={`badge align-left ${
              !!proxyList.enable ? "bg-success" : "bg-danger"
            }`}
          ></span>
        </div>
      </ListTile>
    );
  };

  const addRelation = async (proxyList: ProxyListModel) => {
    setBusy(true);
    try {
      await fetcher({
        method: "post",
        url: "/api/v1/data/vezgateProxyProfileM2MProxyList/create",
        data: {
          intPkProxyListID: proxyList.intPkProxyListID,
          intPkVezgateProxyProfileID: model?.intPkVezgateProxyProfileID,
        },
        successMessage: "Successfully attached to proxy profile",
      });
      refetchAttachedProxyLists();
      refetchProxyLists();
    } catch (e: any) {}
    setBusy(false);
  };

  const removeRelation = async (relationID: number) => {
    setBusy(true);

    try {
      await fetcher({
        method: "delete",
        successMessage: "Successfully removed from proxy profile",
        url: `/api/v1/data/vezgateProxyProfileM2MProxyList/${relationID}`,
      });
      refetchAttachedProxyLists();
      refetchProxyLists();
    } catch (e: any) {}
    setBusy(false);
  };

  const handleDragEnd = useCallback(
    async (result: DropResult, provided: ResponderProvided) => {
      if (!result.destination || busy) return;

      if (
        result.destination.droppableId === "connectedProxyLists" &&
        result.source.droppableId === "proxyLists"
      ) {
        const proxyListsRecords = proxyLists?.result?.rows;
        const selectedProxyNode = proxyListsRecords?.[result.source.index];

        if (selectedProxyNode) {
          await addRelation(selectedProxyNode);
        }
      } else if (
        result.source.droppableId === "connectedProxyLists" &&
        result.destination.droppableId === "proxyLists"
      ) {
        const attachedProxyListsRecords = attachedProxyLists?.result?.rows;
        const selectedProxyList =
          attachedProxyListsRecords?.[result.source.index];

        const relationID =
          selectedProxyList?.vezgate_proxy_profile_m2m_proxy_lists?.find(
            (e) =>
              e.intPkVezgateProxyProfileID ===
                model?.intPkVezgateProxyProfileID &&
              model?.intPkVezgateProxyProfileID
          )?.intPkVezgateProxyProfileM2MProxyListID;

        if (relationID) {
          await removeRelation(relationID);
        }
      }
    },
    [attachedProxyLists, proxyLists]
  );

  const renderPanes = () => {
    const panes: React.ReactElement[] = [];
    let paneClassName =
      "p-3 mw-33 flex-fill d-flex flex-column align-items-stretch mh-80vh overflow-auto";

    panes.push(
      <div className={paneClassName}>
        <Input
          register={register("profileName", {
            required: "Required",
          })}
          readOnly={readOnly}
          title="Profile Name"
          className="mb-3"
          errorMessage={errors.profileName?.message}
        />
      </div>
    );

    if (isEditMode) {
      paneClassName += " minh-80vh";

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Attached Proxy Nodes</h3>{" "}
          <FilterPicker
            filter={attachedProxyListFilter}
            onFilter={(filter) => setAttachedProxyListFilter(filter)}
          >
            <ProxyListFilter />
          </FilterPicker>
          <DraggablePicker id={"connectedProxyLists"}>
            {attachedProxyLists?.result?.rows.map(renderProxyNodeTile)}
          </DraggablePicker>
        </div>
      );

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Available Proxy Lists</h3>
          <FilterPicker
            filter={proxyListFilter}
            onFilter={(filter) => setProxyListFilter(filter)}
          >
            <ProxyListFilter />
          </FilterPicker>
          <DraggablePicker id={"proxyLists"}>
            {proxyLists?.result?.rows.map(renderProxyNodeTile)}
          </DraggablePicker>
        </div>
      );
    }

    return panes;
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <CreateEditModal
        model={selectedProxyList}
        idField="intPkProxyListID"
        nameField="proxyListName"
        modelName="proxyList"
        modalSize="xl"
        onHide={() => {
          setSelectedProxyList(undefined);
          refetchAttachedProxyLists();
          refetchProxyLists();
        }}
        open={!!selectedProxyList}
        renderForm={({ isEditMode, model, readOnly }) => {
          return (
            <ProxyListForm
              readOnly={readOnly}
              model={model}
              isEditMode={isEditMode}
            />
          );
        }}
      />
      <div className="d-flex flex-column align-items-stretch flex-lg-row">
        {renderPanes()}
      </div>
    </DragDropContext>
  );
};

export default VezgateProxyProfilePage;
