import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";

import { CountryAttributes } from "common";
import EditPage from "../../components/EditPage";
import { FunctionComponent } from "react";
import Input from "../../components/FormElements/ControlledInput";

const CountriesPage: FunctionComponent = () => {
  return (
    <EditPage<CountryAttributes>
      modelName="country"
      fields={[
        "intPkCountryID",
        { key: "flagImageUrl", type: "image" },
        "countryName",
        "countryCode",
            { key: "enable", type: "boolean" },
      ]}
      idField="intPkCountryID"
      defaultValues={{ enable: 1 }}
      nameField="countryName"
      renderForm={({ register, errors, control, readOnly }) => (
        <div className="m-3">
          <div className="d-flex flex-row mb-3">
            <Input
              register={register("countryCode", {
                required: "Required",
                maxLength: { message: "Max length is 2", value: 2 },
              })}
              title="Country Code"
              readOnly={readOnly}
              errorMessage={errors.countryCode?.message}
            />
            <Input
              register={register("countryName", { required: "Required" })}
              title="Country Name"
              className="ms-3"
              readOnly={readOnly}
              errorMessage={errors.countryName?.message}
            />
          </div>
          <Input
            title="Flag Image Url"
            className="mb-3"
            readOnly={readOnly}
            register={register("flagImageUrl", { required: "Required" })}
            errorMessage={errors.flagImageUrl?.message}
          />

          <CheckboxTileGroup>
            <CheckboxTile
              control={control}
              name="enable"
              title="Enable"
              readOnly={readOnly}
            />
          </CheckboxTileGroup>
        </div>
      )}
    />
  );
};

export default CountriesPage;
