import { VezSockListModel, VezSockProfileAttributes } from "common";
import { useState } from "react";
import { useCallback } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import DraggablePicker from "../../components/DraggablePicker";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import Input from "../../components/FormElements/ControlledInput";
import { RadioSelectWithController } from "../../components/FormElements/RadioSelect";
import ListTile from "../../components/ListTile";
import CreateEditModal from "../../components/Modals/CreateEditModal";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { VezSockListFilter, VezSockListForm } from "./VezSockList";

export const VezSockProfilePage: React.FunctionComponent = () => {
  return (
    <EditPage<VezSockProfileAttributes, VezSockProfileAttributes>
      modelName="vezSockProfile"
      nameField="profileName"
      idField="intPkVezSockProfileID"
      title="VezSock Profile"
      createEditModalSize="xl"
      fields={["intPkVezSockProfileID", "profileName"]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <VezSockProfileForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const VezSockProfileForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<VezSockProfileAttributes>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<VezSockProfileAttributes>();

  const [busy, setBusy] = useState(false);
  const [attachedVezSockListFilter, setAttachedVezSockNodeFilter] = useState(
    {}
  );
  const [vezSockFilter, setVezSockNodeFilter] = useState({});
  const [selectedVezSockList, setSelectedVezSockList] =
    useState<VezSockListModel>();

  const { data: attached, refetch: refetchAttachedSockLists } =
    useAuthorizedQuery<VezSockListModel>({
      model: "vezSockList",
      limit: 1000,
      filter: {
        ...attachedVezSockListFilter,
        // @ts-ignore
        vezSockProfileFilter: {
          intPkVezSockProfileID: model?.intPkVezSockProfileID,
        },
      },
      enabled: isEditMode,
    });
  const { data: notAttached, refetch: refetchVezSockLists } =
    useAuthorizedQuery<VezSockListModel>({
      model: "vezSockList",
      url: `/api/v1/data/vezSockList/notAttached/${model?.intPkVezSockProfileID}`,
      filter: {
        ...vezSockFilter,
      },
      enabled: isEditMode,
    });

  const fetcher = useFetcher({});

  const renderVezSockListTile = (vezSockList: VezSockListModel) => {
    return (
      <ListTile
        primary={vezSockList.sockListName}
        onDblClick={() => setSelectedVezSockList(vezSockList)}
      >
        <div className="col-2 align-items-center d-flex justify-content-end">
          <span
            className={`badge align-left ${
              !!vezSockList.enable ? "bg-success" : "bg-danger"
            }`}
          ></span>
        </div>
      </ListTile>
    );
  };

  const addRelation = async (vezSockList: VezSockListModel) => {
    setBusy(true);
    try {
      await fetcher({
        method: "post",
        url: "/api/v1/data/vezSockProfileM2MVezSockList/create",
        data: {
          intPkVezSockProfileID: model?.intPkVezSockProfileID,
          intPkVezSockListID: vezSockList.intPkVezSockListID,
        },
        successMessage: "Successfully attached to Profile",
      });
      refetchAttachedSockLists();
      refetchVezSockLists();
    } catch (e: any) {}
    setBusy(false);
  };

  const removeRelation = async (relationID: number) => {
    setBusy(true);

    try {
      await fetcher({
        method: "delete",
        successMessage: "Successfully removed from Profile",
        url: `/api/v1/data/vezSockProfileM2MVezSockList/${relationID}`,
      });
      refetchAttachedSockLists();
      refetchVezSockLists();
    } catch (e: any) {}
    setBusy(false);
  };

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination || busy) return;

      if (
        result.destination.droppableId === "connectedVezSockLists" &&
        result.source.droppableId === "vezSockLists"
      ) {
        const records = notAttached?.result?.rows;
        const selected = records?.[result.source.index];

        if (selected) {
          await addRelation(selected);
        }
      } else if (
        result.source.droppableId === "connectedVezSockLists" &&
        result.destination.droppableId === "vezSockLists"
      ) {
        const records = attached?.result?.rows;
        const selected = records?.[result.source.index];

        const relationID = selected?.vez_sock_profile_m2m_vez_sock_lists?.find(
          (e) =>
            e.intPkVezSockProfileID === model?.intPkVezSockProfileID &&
            model.intPkVezSockProfileID
        )?.intPkVezSockProfileM2MVezSockListID;

        if (relationID) {
          await removeRelation(relationID);
        }
      }
    },
    [attached, notAttached]
  );

  const renderPanes = () => {
    const panes: React.ReactElement[] = [];
    let paneClassName =
      "p-3 mw-33 flex-fill d-flex flex-column align-items-stretch mh-80vh overflow-auto";

    panes.push(
      <div className={paneClassName}>
        <Input
          register={register("profileName", {
            required: "Required",
          })}
          readOnly={readOnly}
          title="Profile Name"
          className="mb-3"
          errorMessage={errors.profileName?.message}
        />
      </div>
    );

    if (isEditMode) {
      paneClassName += " minh-80vh";

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Attached VezSock Lists</h3>{" "}
          <FilterPicker
            filter={attachedVezSockListFilter}
            onFilter={(filter) => setAttachedVezSockNodeFilter(filter)}
          >
            {<VezSockListFilter />}
          </FilterPicker>
          <DraggablePicker id={"connectedVezSockLists"}>
            {attached?.result?.rows.map(renderVezSockListTile)}
          </DraggablePicker>
        </div>
      );

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Available VezSock Lists</h3>
          <FilterPicker
            filter={vezSockFilter}
            onFilter={(filter) => setVezSockNodeFilter(filter)}
          >
            {<VezSockListFilter />}
          </FilterPicker>
          <DraggablePicker id={"vezSockLists"}>
            {notAttached?.result?.rows.map(renderVezSockListTile)}
          </DraggablePicker>
        </div>
      );
    }

    return panes;
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <CreateEditModal
        model={selectedVezSockList}
        idField="intPkVezSockListID"
        nameField="sockListName"
        modelName="vezSockList"
        modalSize="xl"
        onHide={() => {
          setSelectedVezSockList(undefined);
          refetchAttachedSockLists();
          refetchVezSockLists();
        }}
        open={!!selectedVezSockList}
        renderForm={({ isEditMode, model, readOnly }) => {
          return (
            <VezSockListForm
              readOnly={readOnly}
              model={model}
              isEditMode={isEditMode}
            />
          );
        }}
      />
      <div className="d-flex flex-column align-items-stretch flex-lg-row">
        {renderPanes()}
      </div>
    </DragDropContext>
  );
};

export const VezSockProfileFilter = () => {
  const methods = useFormContext();

  return (
    <div className="m-3">
      <Input
        register={methods.register("or._0.vezSockListName")}
        title="Search by Name"
        className="mb-3"
      />
      <RadioSelectWithController
        control={methods.control}
        name="enable"
        title="Enable Status"
        options={[
          { label: "All", value: undefined },
          { label: "Enabled", value: 1 },
          { label: "Disabled", value: 0 },
        ]}
      />
    </div>
  );
};
