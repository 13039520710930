import {
  BrandAttributes,
  DataCenterAttributes,
  DataCenterModel,
  ProxyNodeAttributes,
  ProxyNodeModel,
  VendorAttributes,
} from "common";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import Input from "../../components/FormElements/ControlledInput";
import DatePickerCustom from "../../components/FormElements/DatePickerCustom";
import { RadioSelectWithController } from "../../components/FormElements/RadioSelect";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import { NodeControl } from "./Nodes";

export const ProxyNodesPage: React.FunctionComponent = () => {
  const [filter, setFilter] = useState({});

  return (
    <EditPage<ProxyNodeAttributes, ProxyNodeModel>
      modelName="proxyNode"
      nameField="proxyNodeName"
      filter={filter}
      renderCardHeader={(data) => {
        return (
          <FilterPicker filter={filter} onFilter={setFilter}>
            <ProxyNodeFilter />
          </FilterPicker>
        );
      }}
      idField="intPkProxyNodeID"
      title="ProxyNodes"
      fields={[
        "proxyNodeName",
        "proxyIP",
        "proxyPort",
        "proxyDomain",
        "userName",
        {
          key: "sslCertificateExpiry",
          type: "date",
        },
        "intPkDataCenter.dataCenterName",
        { key: "enable", type: "switch" },
      ]}
      renderExtraCardControls={(z) => z && (
        <NodeControl
          nodeType="proxyNode"
          id={z.intPkProxyNodeID}
          sshPassword={z.password}
          ip={z.proxyIP}
          sshUserName={z.userName}
          name={z.proxyNodeName}
        />
      )}
      renderForm={({ isEditMode, model, readOnly }) => (
        <ProxyNodesForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const ProxyNodesForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<ProxyNodeAttributes>) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext<ProxyNodeAttributes>();

  const proxyDomain = watch("proxyDomain");

  useEffect(() => {
    if (proxyDomain?.match(/^(\w+\.)+\w{2,10}$/s)) {
      setValue(`proxyDomain`, `https://${proxyDomain}`);
    }
    trigger("proxyDomain");
  }, [proxyDomain]);

  return (
    <div className="m-3">
      <Input
        register={register("proxyNodeName", {
          required: "Required",
        })}
        readOnly={readOnly}
        className="mb-3"
        title="Proxy Node Name"
        errorMessage={errors.proxyNodeName?.message}
      />
      <div className="flex-fill row gap-1">
        <div className="col">
          <Input
            register={register("proxyIP", {
              required: "Required",
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Proxy IP"
            errorMessage={errors.proxyIP?.message}
          />
        </div>{" "}
        <div className="col">
          <Input
            register={register("proxyPort", {
              required: "Required",
              valueAsNumber: true,
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Port"
            type="number"
            errorMessage={errors.proxyPort?.message}
          />
        </div>
        <div className="col">
          <Input
            register={register("proxyDomain", {
              required: "Required",
              validate: (value) => {
                if (!value.match(/^(https:\/\/)(\w+\.)+\w+$/)) {
                  return "Please write in format: domain.com or https://domain.com";
                }
              },
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Domain"
            errorMessage={errors.proxyDomain?.message}
          />
        </div>
      </div>
      <div className="form-group mb-3 flex-fill ">
        <label className="form-label">DataCenter</label>
        <AsyncSelectWithController<ProxyNodeAttributes, DataCenterAttributes>
          control={control}
          valueFieldName="intPkDataCenterID"
          searchField="dataCenterName"
          readOnly={readOnly}
          model="dataCenter"
        />
      </div>
      <div className="flex-fill row gap-1">
        <div className="col">
          <Input
            register={register("userName", {
              required: "Required",
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Username"
            errorMessage={errors.userName?.message}
          />
        </div>
        <div className="col">
          <Input
            register={register("password", {
              required: "Required",
            })}
            readOnly={readOnly}
            className="mb-3"
            title="Password"
            errorMessage={errors.password?.message}
          />
        </div>
      </div>
      <div className="col">
        <div className="form-group mb-3">
          <label className="form-label">SSL Certificatie Expiry</label>
          <Controller
            name="sslCertificateExpiry"
            control={control}
            render={({ field: { name, onBlur, onChange, value } }) => {
              return (
                <DatePickerCustom
                  {...{ name, onBlur, value, onChange }}
                  disabled={readOnly}
                />
              );
            }}
          />
        </div>
      </div>

      <CheckboxTileGroup>
        <CheckboxTile control={control} name="enable" title="Enable" />
      </CheckboxTileGroup>
    </div>
  );
};

export const ProxyNodeFilter = () => {
  const methods = useFormContext();
  const [mainValue, intPkVendorID, intPkCountryID] = methods.watch([
    "or._0.proxyNodeName",
    "intPkDataCenter.intPkVendorID",
    "intPkDataCenter.intPkCountryID",
  ]);

  return (
    <div className="m-3">
      <Input
        register={methods.register("or._0.proxyNodeName")}
        title="Search by IP, Name, Domain"
        className="mb-3"
      />
      <Input
        register={methods.register("or._1.proxyIP", { value: mainValue })}
        title=""
        type="hidden"
        readOnly
      />
      <Input
        register={methods.register("or._2.proxyDomain", { value: mainValue })}
        title=""
        type="hidden"
        readOnly
      />
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Brand</label>
        <AsyncSelectWithController<any, BrandAttributes>
          searchField="brandName"
          model="brand"
          imageField="iconUrl"
          control={methods.control}
          valueFieldName="proxy_node_m2m_proxy_lists.some.intPkProxyList.vezgate_proxy_profile_m2m_proxy_lists.some.intPkVezgateProxyProfile.brands_statuses.some.intPkBrand.intPkBrandID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Vendor</label>
        <AsyncSelectWithController<any, VendorAttributes>
          searchField="vendorName"
          model="vendor"
          control={methods.control}
          valueFieldName="intPkDataCenter.intPkVendorID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Country</label>
        <AsyncSelectWithController<any, any>
          searchField="countryName"
          model="country"
          imageField="flagImageUrl"
          filter={{ datacenters: { some: { intPkVendorID } } }}
          control={methods.control}
          valueFieldName="intPkDataCenter.intPkCountryID"
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Data Center</label>
        <AsyncSelectWithController<any, DataCenterModel>
          searchField="dataCenterName"
          model="dataCenter"
          imageField="intPkCountry.flagImageUrl"
          filter={{
            // @ts-ignore
            intPkVendorID: { equals: intPkVendorID },
            // @ts-ignore
            intPkCountryID: { equals: intPkCountryID },
          }}
          control={methods.control}
          valueFieldName="intPkDataCenterID"
        />
      </div>
      <RadioSelectWithController
        control={methods.control}
        name="enable"
        title="Enable Status"
        options={[
          { label: "All", value: undefined },
          { label: "Enabled", value: 1 },
          { label: "Disabled", value: 0 },
        ]}
      />
    </div>
  );
};
