import { VezSsNodeModel, VezSsListAttributes } from "common";
import { useState } from "react";
import { useCallback } from "react";
import {
  DragDropContext,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import DraggablePicker from "../../components/DraggablePicker";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import Input from "../../components/FormElements/ControlledInput";
import { RadioSelectWithController } from "../../components/FormElements/RadioSelect";
import ListTile from "../../components/ListTile";
import CreateEditModal from "../../components/Modals/CreateEditModal";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { VezSsNodeFilter, VezSSNodeForm } from "./VezSSNodes";
import MoonLoader from "react-spinners/MoonLoader";

export const VezSSListPage: React.FunctionComponent = () => {
  return (
    <EditPage<VezSsListAttributes, VezSsListAttributes>
      modelName="vezSsList"
      nameField="listName"
      idField="intPkVezSSListID"
      title="VezSS List"
      createEditModalSize="xl"
      defaultValues={{
        enable: 1,
      }}
      fields={["intPkVezSSListID", "listName"]}
      renderForm={({ isEditMode, model, readOnly }) => (
        <VezSSListForm
          readOnly={readOnly}
          isEditMode={isEditMode}
          model={model}
        />
      )}
    />
  );
};

export const VezSSListForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<VezSsListAttributes>) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<VezSsListAttributes>();

  const [busy, setBusy] = useState(false);
  const [attachedVezSSNodeFilter, setAttachedVezSSNodeFilter] = useState({});
  const [vezSsNodeFilter, setVezSsNodeFilter] = useState({});
  const [selectedVezSockNode, setSelectedVezSockNode] =
    useState<VezSsNodeModel>();

  const {
    data: attachedVezSsNodes,
    refetch: refetchAttachedSsNodes,
    isLoading: areAttachedVezSSNodesLoading,
  } = useAuthorizedQuery<VezSsNodeModel>({
    model: "vezSsNode",
    limit: 10,
    filter: {
      ...attachedVezSSNodeFilter,
      // @ts-ignore
      vezSsListFilter: {
        some: {
          intPkVezSSListID: model?.intPkVezSSListID,
        },
      },
    },
    enabled: isEditMode,
  });
  const {
    data: vezSsNodes,
    refetch: refetchVezSsNodes,
    isLoading: areVezSSNodesLoading,
  } = useAuthorizedQuery<VezSsNodeModel>({
    model: "vezSsNode",
    filter: {
      ...vezSsNodeFilter,
      // @ts-ignore
      vezSsListFilter: {
        none: {
          intPkVezSSListID: model?.intPkVezSSListID,
        },
      },
    },
    enabled: isEditMode,
  });

  const fetcher = useFetcher({});

  const renderVezSSNodeTile = (vezSsNode: VezSsNodeModel) => {
    return (
      <ListTile
        primary={vezSsNode.vezSSNodeName}
        secondary={`${vezSsNode.nodeIP}:${vezSsNode.ssPort}`}
        image={
          <img
            src={vezSsNode.intPkDataCenter?.intPkCountry?.flagImageUrl}
            alt="countryFlag"
          />
        }
        onDblClick={() => setSelectedVezSockNode(vezSsNode)}
      >
        <div className="col-2 align-items-center d-flex justify-content-end">
          <span
            className={`badge align-left ${
              !!vezSsNode.enable ? "bg-success" : "bg-danger"
            }`}
          ></span>
        </div>
      </ListTile>
    );
  };

  const addRelation = async (vezSsNode: VezSsNodeModel) => {
    setBusy(true);
    try {
      await fetcher({
        method: "post",
        url: "/api/v1/data/vezSsListM2MVezSsNode/create",
        data: {
          intPkVezSSListID: model?.intPkVezSSListID,
          intPkVezSSNodeID: vezSsNode.intPkVezSSNodeID,
        },
        successMessage: "Successfully attached to list",
      });
      refetchAttachedSsNodes();
      refetchVezSsNodes();
    } catch (e: any) {}
    setBusy(false);
  };

  const removeRelation = async (relationID: number) => {
    setBusy(true);

    try {
      await fetcher({
        method: "delete",
        successMessage: "Successfully removed from list",
        url: `/api/v1/data/vezSsListM2MVezSsNode/${relationID}`,
      });
      refetchAttachedSsNodes();
      refetchVezSsNodes();
    } catch (e: any) {}
    setBusy(false);
  };

  const handleDragEnd = useCallback(
    async (result: DropResult, provided: ResponderProvided) => {
      if (!result.destination || busy) return;

      if (
        result.destination.droppableId === "connectedVezSSNodes" &&
        result.source.droppableId === "vezSSNodes"
      ) {
        const records = vezSsNodes?.result?.rows;
        const selected = records?.[result.source.index];

        if (selected) {
          await addRelation(selected);
        }
      } else if (
        result.source.droppableId === "connectedVezSSNodes" &&
        result.destination.droppableId === "vezSSNodes"
      ) {
        const records = attachedVezSsNodes?.result?.rows;
        const selected = records?.[result.source.index];

        const relationID = selected?.vez_ss_list_m2m_vez_ss_nodes?.find(
          (e) =>
            e.intPkVezSSListID === model?.intPkVezSSListID &&
            model.intPkVezSSListID
        )?.intPkVezSSListM2MVezSSNodeID;

        if (relationID) {
          await removeRelation(relationID);
        }
      }
    },
    [attachedVezSsNodes, vezSsNodes]
  );

  const renderPanes = () => {
    const panes: React.ReactElement[] = [];
    let paneClassName =
      "p-3 mw-33 flex-fill d-flex flex-column align-items-stretch mh-80vh overflow-auto";

    panes.push(
      <div className={paneClassName}>
        <Input
          register={register("listName", {
            required: "Required",
            validate: (value) => {
              if (!value?.match(/^[A-Za-z][A-Za-z0-9_]*$/s)) {
                return "Please correct the format, only '_', numbers and alphabets are allowed. First is always alphabet";
              }
            },
          })}
          readOnly={readOnly}
          title="List Name"
          className="mb-3"
          errorMessage={errors.listName?.message}
        />
        <CheckboxTileGroup>
          <CheckboxTile control={control} name="enable" title="Enable" />
        </CheckboxTileGroup>
      </div>
    );

    if (isEditMode) {
      paneClassName += " minh-80vh";

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Attached SS Nodes</h3>{" "}
          <FilterPicker
            filter={attachedVezSSNodeFilter}
            onFilter={(filter) => setAttachedVezSSNodeFilter(filter)}
          >
            <VezSsNodeFilter />
          </FilterPicker>
          {areAttachedVezSSNodesLoading && (
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <MoonLoader size={30} />
            </div>
          )}
          <DraggablePicker id={"connectedVezSSNodes"}>
            {attachedVezSsNodes?.result?.rows.map(renderVezSSNodeTile)}
          </DraggablePicker>
        </div>
      );

      panes.push(
        <div className={`${paneClassName} border-start`}>
          <h3>Available SS Nodes</h3>
          <FilterPicker
            filter={vezSsNodeFilter}
            onFilter={(filter) => setVezSsNodeFilter(filter)}
          >
            <VezSsNodeFilter />
          </FilterPicker>
          {areVezSSNodesLoading && (
            <div className="d-flex flex-fill justify-content-center align-items-center">
              <MoonLoader size={30} />
            </div>
          )}
          <DraggablePicker id={"vezSSNodes"}>
            {vezSsNodes?.result?.rows.map(renderVezSSNodeTile)}
          </DraggablePicker>
        </div>
      );
    }

    return panes;
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <CreateEditModal
        model={selectedVezSockNode}
        idField="intPkVezSSNodeID"
        nameField="vezSSNodeName"
        modelName="vezSsNode"
        onHide={() => {
          setSelectedVezSockNode(undefined);
          refetchAttachedSsNodes();
          refetchVezSsNodes();
        }}
        open={!!selectedVezSockNode}
        renderForm={({ isEditMode, model, readOnly }) => {
          return (
            <VezSSNodeForm
              readOnly={readOnly}
              model={model}
              isEditMode={isEditMode}
            />
          );
        }}
      />
      <div className="d-flex flex-column align-items-stretch flex-lg-row">
        {renderPanes()}
      </div>
    </DragDropContext>
  );
};

export const VezSSListFilter = () => {
  const methods = useFormContext();

  return (
    <div className="m-3">
      <Input
        register={methods.register("listName")}
        title="Search by Name"
        className="mb-3"
      />
      <RadioSelectWithController
        control={methods.control}
        name="enable"
        title="Enable Status"
        options={[
          { label: "All", value: undefined },
          { label: "Enabled", value: 1 },
          { label: "Disabled", value: 0 },
        ]}
      />
    </div>
  );
};
