import { AppState, StateProvider, User } from "../libs/state";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIsFetching } from "react-query";

import BarLoader from "react-spinners/BarLoader";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import { RolePermissionModel } from "common";
import Splash from "./Splash";
import { config } from "../libs/config";
import { queryClient } from "..";
import { useAlert } from "react-alert";
import { useLocalStorage } from "../libs/hooks/useLocalStorage";
import "./App.scss";

export default function App() {
  const fetcher = useRef(
    axios.create({
      baseURL: config.apiBaseUrl,
    })
  ).current;
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage<boolean>(
    "isAuthenticated",
    false
  );
  const [user, setUser] = useLocalStorage<User>("user", null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allowedTables, setAllowedTables] = useState<RolePermissionModel[]>([]);
  const alert = useAlert();
  const isLoadingSomthing = useIsFetching();

  if (user) {
    fetcher.defaults.headers["authorization"] = `Bearer ${user.token}`;
  }

  const state: AppState = {
    fetcher: fetcher,
    isAuthenticated: isAuthenticated != null && isAuthenticated,
    user: user,
    authenticate: useCallback(
      (user) => {
        fetcher.defaults.headers["authorization"] = `Bearer ${user.token}`;
        setIsAuthenticated(true);
        setUser(user);
      },
      [setIsAuthenticated, setUser]
    ),
    logout: useCallback(() => {
      queryClient.clear();
      fetcher.defaults.headers["authorization"] = undefined;
      setIsAuthenticated(false);
      setUser(null);
    }, [setIsAuthenticated, setUser, queryClient]),
    allowedTables,
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      fetcher
        .get("api/v1/allowedTables")
        .then((e) => {
          setAllowedTables(e.data.result);
        })
        .catch((e: AxiosError) => {
          if (e.isAxiosError) {
            if (e.response?.status === 401) {
              return state.logout();
            }
          }
          alert.error(e.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isAuthenticated]);

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center min-vh-100">
      <BarLoader loading={isLoading} />
    </div>
  ) : (
    <>
      <div
        className="progress-bar"
        style={{
          animationPlayState: isLoadingSomthing ? "running" : "revert",
          visibility: isLoadingSomthing ? "visible" : "hidden",
        }}
      >
        &nbsp;
      </div>
      <StateProvider value={state}>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Splash} exact></Route>
            <Route path="/dashboard" component={Dashboard}></Route>
            <Route path="/login" component={Login}></Route>
          </Switch>
        </BrowserRouter>
      </StateProvider>
    </>
  );
}
