import { DefaultValues, FieldPath } from "react-hook-form";
import { FieldType, ModelName } from "../constants";
import React, { Fragment, useMemo, useState } from "react";
import {
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiDelete,
  mdiFileEdit,
  mdiOpenInNew,
  mdiPlus,
} from "@mdi/js";

import { CRUDModalProps } from "../interfaces/CURDModalProps";
import CreateEditModal from "./Modals/CreateEditModal";
import DeleteModal from "./Modals/DeleteModal";
import { FormRenderFunctionProp } from "../interfaces/FormRenderFunctionProp";
import Icon from "@mdi/react";
import CustomModal, { ModalProps } from "./Modals/Modal";
import Moment from "react-moment";
import { camelToSentenceCase } from "../libs/util/camelToSentenceCase";
import range from "../libs/util/range";
import { resolveObjectFromPath } from "../libs/util/resolveObjectFromPath";
import useAuthorizedQuery from "../libs/hooks/useAuthorizedQuery";
import { useEffect } from "react";
import { usePermissions } from "../libs/hooks/usePermissions";
import { ListResponse } from "../interfaces/ListResponse";
import { ServerResponse } from "common";
import Tree, { mutateTree, TreeData } from "@atlaskit/tree";
import RadioSelect from "./FormElements/RadioSelect";
import { MoonLoader } from "react-spinners";
import useModelAggregationQuery from "../libs/hooks/useModelAggregationQuery";
import TableTree, {
  Headers,
  Header,
  Row,
  Cell,
  Rows,
  // @ts-ignore
} from "@atlaskit/table-tree";
import useFetcher from "../libs/hooks/useFetcher";

export interface CustomCreateEditModelProps<T> {
  show: boolean;
  onHide: () => void;
  model?: T;
  setModel?: (z?: T) => void;
}

export interface FieldInfo<T> {
  key?: FieldPath<T>;
  type?: FieldType;
  name?: string;
  value?: (z: T) => React.ReactElement | undefined;
}

interface EditPageProps<T, K> {
  modelName: ModelName;
  fields: (FieldPath<K> | FieldInfo<K>)[];
  minimalViewFields?: (FieldPath<K> | FieldInfo<K>)[];
  showMinimalView?: boolean;
  nameField: FieldPath<T>;
  idField: FieldPath<T>;

  showCreateButton?: boolean;
  showAggregations?: boolean;
  title?: string;
  filter?: Partial<K>;
  defaultValues?: any;
  createEditModalSize?: ModalProps["size"];
  onEditHook?: CRUDModalProps<T>["onEditHook"];
  onCreateHook?: CRUDModalProps<T>["onCreateHook"];
  warnOnUncommittedChanges?: boolean;
  defaultLimit?: number;
  customCreateEditModel?: (
    props: CustomCreateEditModelProps<T>
  ) => React.ReactElement;

  buildTreeView?: (
    data: ServerResponse<ListResponse<K>>
  ) => TreeData | undefined;
  buildGroupView?: (
    data: ServerResponse<ListResponse<K>>
  ) => Array<Record<string, any>> | undefined;
  renderCardHeader?: (
    data?: ServerResponse<ListResponse<K>>
  ) => React.ReactElement;
  renderExtraCardControls?: (data?: K) => React.ReactElement | undefined;
  isRecordDeletable?: (z: T) => boolean;
  isRecordEditable?: (z: T) => boolean;
  recordStyles?: (z: K) => any;
  primaryButtonMessage?: string;
  onNewData?: (data: ServerResponse<ListResponse<K>>) => void;
}

type EditPagePropsWithRenderFunction<T, K> = EditPageProps<T, K> &
  FormRenderFunctionProp<T>;

export function getFieldKey<K extends object>(
  field: FieldPath<K> | FieldInfo<K>,
  omitBeforeDot: boolean = true
) {
  let key: string;

  if (typeof field === "object") {
    if (field.name) {
      key = field.name;
    } else if (field.key && field.key.includes(".") && omitBeforeDot) {
      const parts = field.key.split(".");
      key = parts[parts.length - 1];
    } else {
      key = field.key as string;
    }
  } else {
    if (field.includes(".") && omitBeforeDot) {
      const parts = field.split(".");
      key = parts[parts.length - 1];
    } else {
      key = field;
    }
  }

  return key;
}

const colors = ["var(--tblr-green)"];

const EditPage = <T extends Record<string, any>, K extends T = T>({
  modelName,
  fields: fullFields,
  idField,
  renderForm,
  nameField,
  onCreateHook,
  buildGroupView,
  filter,
  showMinimalView,
  minimalViewFields,
  warnOnUncommittedChanges = true,
  title,
  onEditHook,
  isRecordDeletable = (_) => true,
  isRecordEditable = (_) => true,
  primaryButtonMessage,
  createEditModalSize = "lg",
  showAggregations = false,
  defaultLimit = 10,
  renderCardHeader,
  customCreateEditModel,
  defaultValues,
  showCreateButton = true,
  recordStyles,
  onNewData,
  renderExtraCardControls,
  buildTreeView,
}: React.PropsWithChildren<EditPagePropsWithRenderFunction<T, K>>) => {
  // Update the title, as it will be camel cased;
  title = title || camelToSentenceCase(modelName);

  // Get permissions from context
  const permissions = usePermissions(modelName);

  // Creating & Editing Modal, State & Modal Hook
  const [modalData, setModalData] = useState<T>();
  const [createEditModalShown, setCreateEditModalShown] = useState(false);
  const [deleteModalShown, setDeleteModalShown] = useState(false);

  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState<number>(0);
  const [viewMode, setViewMode] = useState<"tree" | "table" | "group">("table");
  const [tree, setTree] = useState<TreeData>();
  const [group, setGroup] = useState<Array<Record<string, any>>>();
  const [order, setOrder] = useState<
    Record<keyof Partial<K>, "asc" | "desc"> | undefined
  >(undefined);

  const fetcher = useFetcher({ alerts: true });
  const fields = useMemo(
    () => (showMinimalView ? minimalViewFields! : fullFields),
    [showMinimalView, minimalViewFields, fullFields]
  );

  const { isLoading, data, error, refetch, isFetching } = useAuthorizedQuery<K>(
    {
      model: modelName,
      filter,
      limit,
      offset,
      order,
      queryOptions: {
        onSettled: (data, _) => {
          if (data) {
            // after deleting, current page may not exist, move to previous page
            const dataCount = data.result?.count;
            if (dataCount && dataCount <= offset) {
              setOffset((Math.ceil(dataCount / 10) - 1) * 10);
            }

            onNewData && onNewData(data);
          }
        },
        retry: (failCount, error) => {
          if (error.response?.status === 403 || error.response?.status === 401)
            return false;
          return failCount <= 3;
        },
      },
    }
  );

  useEffect(() => {
    if (buildTreeView && data) {
      setTree(buildTreeView(data));
    }
  }, [buildTreeView, data]);

  useEffect(() => {
    if (buildGroupView && data) {
      setGroup(buildGroupView(data));
    }
  }, [buildGroupView, data]);

  const buildResults = () => {
    const result = data?.result;

    if (result && result.rows.length > 0) {
      return (
        <tbody>
          {Object.entries(result.rows).map(([_, row], index) => (
            <tr key={index} style={(recordStyles && recordStyles(row)) || {}}>
              {Object.entries(fields).map(([_, field], index) => {
                let value: string | React.ReactElement | undefined,
                  key: string,
                  type: FieldType | undefined;

                let isReactElement = false;

                if (typeof field === "string") {
                  key = field;
                  value = resolveObjectFromPath(row, field);
                  type = "string";
                } else {
                  if (field.key) {
                    key = field.key;
                    value = resolveObjectFromPath(row, field.key);
                  } else {
                    key = `generatedValue${index}`;
                    value = field.value!(row);
                    isReactElement = true;
                  }
                  type = field.type;
                }

                return (
                  <td key={key}>
                    {!isReactElement ? (
                      <>
                        {type === "boolean" && (
                          <span
                            className={`badge align-middle ${
                              !!value ? "bg-success" : "bg-danger"
                            } me-1`}
                          ></span>
                        )}
                        {type === "date" &&
                          (!value ? (
                            "-"
                          ) : (
                            <Moment format="Do MMM YYYY / hh:mm a">
                              {value as string}
                            </Moment>
                          ))}
                        {type === "string" && (!!value ? String(value) : "-")}
                        {type === "image" && (
                          <div className="px-2 text-center">
                            {value && (
                              <img
                                src={value as string}
                                alt={key}
                                className="text-center"
                                height="30px"
                                onError={function (x) {
                                  x.currentTarget.src = "/images/question.png";
                                }}
                              />
                            )}
                          </div>
                        )}
                        {type === "switch" && (
                          <label className="form-check form-check-single form-switch px-0 ">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              style={
                                !!value
                                  ? {
                                      backgroundColor:
                                        colors[index % colors.length],
                                    }
                                  : {}
                              }
                              onChange={(e) => {
                                const newValue = e.target.checked;

                                fetcher({
                                  url: `/api/v1/data/${modelName}/${
                                    row![idField]
                                  }/edit`,
                                  method: "put",
                                  data: {
                                    [key]: Number(newValue),
                                  },
                                  successMessage: "State Changed",
                                }).then((e) => {
                                  refetch();
                                });
                              }}
                              checked={!!value}
                            />
                          </label>
                        )}
                        {type === undefined && "TYPE UNDEFINED"}
                      </>
                    ) : (
                      <>{value}</>
                    )}
                  </td>
                );
              })}
              <td>
                <div className="flex-row">
                  {renderExtraCardControls && renderExtraCardControls(row)}
                  {isRecordEditable(row) && (
                    <button
                      className="btn btn-link p-1"
                      onClick={(_) => {
                        setModalData(row);
                        setCreateEditModalShown(true);
                      }}
                    >
                      <Icon
                        path={
                          permissions.allowUpdate ? mdiFileEdit : mdiOpenInNew
                        }
                        style={{
                          height: "1rem",
                        }}
                      />
                    </button>
                  )}

                  {isRecordDeletable(row) && permissions.allowDelete && (
                    <button
                      className="btn btn-link p-1"
                      onClick={(_) => {
                        setModalData(row);
                        setDeleteModalShown(true);
                      }}
                      disabled={isFetching}
                    >
                      <Icon
                        path={mdiDelete}
                        color={"var(--tblr-red)"}
                        style={{
                          height: "1rem",
                        }}
                      />
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      );
    } else if (result && result.rows.length === 0) {
      const isFiltered = filter && Object.keys(filter).length > 0;

      return (
        <tbody>
          <tr>
            <td colSpan={fields.length}>
              <div className="empty d-flex justify-content-center p-4 mt-2">
                <div className="empty-img">
                  <img src="/svg/empty.svg" height="128" alt="" />
                </div>
                <p className="empty-title">
                  {isFiltered ? `No results found` : "Nothing's here"}
                </p>
                <p className="empty-subtitle text-muted">
                  {isFiltered
                    ? `Upon querying no data has found matching the filters."`
                    : `No records exist${
                        showCreateButton && permissions.allowCreate
                          ? ', create using the "Add" button'
                          : ""
                      }`}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    const errMessage =
      error?.response?.data.message || error?.message || "Something went wrong";

    return (
      <tbody>
        <tr>
          <td colSpan={fields.length}>
            <div className="empty d-flex justify-content-center p-4 mt-2">
              <div className="empty-img">
                <img src="/svg/error.svg" height="128" alt="" />
              </div>
              <p className="empty-title">{errMessage}</p>
              <p className="empty-subtitle text-muted">
                An error occurred while fetching data from the server.
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    );
  };

  const buildLoading = () => (
    <tbody>
      {range(0, 5).map((j) => (
        <tr key={`skeleton-line${j}`}>
          {/* just unique keys  */}
          {Object.keys(fields).map((_, i) => (
            <td key={`skeleton-line${j}-${i}`}>
              {/* just unique keys  */}
              <div className="skeleton-line"></div>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  useEffect(() => {
    console.log("Setting offset to zero");
    setOffset(0);
  }, [filter]);

  return (
    <div className="page-wrapper">
      <div className="container-xl">
        <div className="page-header d-print-none">
          <div className="row align-items-center">
            <div className="col">
              <div className="page-pretitle">MANAGE</div>
              <h2 className="page-title">{title}</h2>
            </div>
            {showCreateButton && permissions.allowCreate && (
              <div className="col-auto ms-auto d-print-none">
                <div className="btn-list">
                  <button
                    className="btn btn-primary d-inline-block"
                    onClick={(_) => setCreateEditModalShown(true)}
                  >
                    <Icon path={mdiPlus} className="icon m-0 me-lg-2" />
                    <span className="d-lg-inline d-none">
                      {primaryButtonMessage
                        ? primaryButtonMessage
                        : `Add ${title}`}
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {customCreateEditModel ? (
          createEditModalShown &&
          customCreateEditModel({
            onHide: () => setCreateEditModalShown(false),
            show: true,
            model: modalData,
            setModel: setModalData,
          })
        ) : (
          <CreateEditModal
            open={createEditModalShown}
            model={modalData}
            defaultValues={defaultValues}
            idField={idField}
            onEditHook={onEditHook}
            onCreateHook={onCreateHook}
            warnOnUncommittedChanges={warnOnUncommittedChanges}
            nameField={nameField}
            title={title}
            modalSize={createEditModalSize}
            modelName={modelName}
            renderForm={renderForm}
            onHide={() => {
              setModalData(undefined);
              setCreateEditModalShown(false);
            }}
          />
        )}

        <DeleteModal
          open={deleteModalShown}
          idField={idField}
          nameField={nameField}
          modalSize="sm"
          modelName={modelName}
          model={modalData}
          onHide={() => {
            setModalData(undefined);
            setDeleteModalShown(false);
          }}
        />
        <div className="page-body">
          <div className="card">
            <div className="card-header">
              <div className="d-flex flex-row align-items-center me-3">
                Show
                <input
                  type="number"
                  style={{ width: "50px" }}
                  value={limit}
                  className="form-control mx-1"
                  onChange={(e) => {
                    if (e.currentTarget.valueAsNumber > 0) {
                      setLimit(e.currentTarget.valueAsNumber);
                    }
                  }}
                />
                Entries
              </div>

              <div className="d-flex gap-3 justify-content-end w-100">
                {(buildTreeView || buildGroupView) && (
                  <div className="align-self-center mt-2 d-flex">
                    <RadioSelect<"table" | "tree" | "group">
                      options={[
                        { label: "Table", value: "table" },
                        buildTreeView
                          ? { label: "Tree", value: "tree" }
                          : { label: "Group", value: "group" },
                      ]}
                      name="doesntmatter"
                      value={viewMode}
                      onOptionSelect={(e) =>
                        e && typeof e === "string" && setViewMode(e)
                      }
                    />
                  </div>
                )}
                {showAggregations && (
                  <ExtraInformationBox model={modelName} filter={filter} />
                )}
                {renderCardHeader && renderCardHeader(data)}
              </div>
            </div>
            {viewMode === "table" ? (
              <div className="card-table table-responsive">
                <table className="table card-table text-nowrap datatable table-vcenter">
                  <thead>
                    <tr>
                      {Object.entries(fields).map(([_, field], index) => {
                        let key = getFieldKey(field);
                        // const curOrder = order.find((e) => e[0] === key);

                        return (
                          <th
                            key={`${key}${index}`}
                            className="cursor-pointer"
                            // onClick={(e) => {
                            //   setOrder((prevOrder) => {
                            //     const prevIndex = prevOrder.findIndex(
                            //       (e) => e[0] === key
                            //     );
                            //     const newOrder = [...prevOrder];
                            //     if (prevIndex !== -1) {
                            //       if (newOrder[prevIndex][1] === "desc") {
                            //         return newOrder.filter(
                            //           (val, idx) => idx !== prevIndex
                            //         );
                            //       }

                            //       newOrder[prevIndex] = [
                            //         key,
                            //         newOrder[prevIndex][1] === "asc"
                            //           ? "desc"
                            //           : "asc",
                            //       ];
                            //     } else {
                            //       newOrder.push([key, "asc"]);
                            //     }

                            //     return newOrder;
                            //   });
                            // }}
                          >
                            {key}
                            {/* {curOrder && (
                              <Icon
                                path={
                                  curOrder[1] === "desc"
                                    ? mdiChevronDown
                                    : mdiChevronUp
                                }
                                size={0.5}
                              />
                            )} */}
                          </th>
                        );
                      })}
                      <th></th>
                    </tr>
                  </thead>
                  {isLoading ? buildLoading() : buildResults()}
                </table>
              </div>
            ) : isLoading ? (
              buildLoading()
            ) : viewMode === "group" ? (
              <div className="table-responsive">
                <TableTree>
                  <Headers>
                    {fields.map((e) => {
                      return (
                        <Header style={{ minWidth: "150px" }}>
                          {getFieldKey(e)}
                        </Header>
                      );
                    })}
                  </Headers>
                  <Rows
                    items={group}
                    render={({ hasChildren, children, ...items }: any) => {
                      return (
                        <Row
                          expandLabel="Expand"
                          collapseLabel="Collapse"
                          itemId={items.id}
                          items={children}
                          hasChildren={hasChildren}
                        >
                          {Object.entries(fields).map(([_, field], index) => {
                            let value: string | React.ReactElement | undefined,
                              key: string,
                              type: FieldType | undefined;

                            let isReactElement = false;

                            if (typeof field === "string") {
                              key = field;
                              value = resolveObjectFromPath(
                                items.content,
                                field
                              );
                              type = "string";
                            } else {
                              if (field.key) {
                                key = field.key;
                                value = resolveObjectFromPath(
                                  items.content,
                                  field.key
                                );
                              } else {
                                key = `generatedValue${index}`;
                                value = field.value!(items.content);
                                isReactElement = true;
                              }
                              type = field.type;
                            }

                            return (
                              <Cell style={{ minWidth: "150px" }}>
                                {!isReactElement ? (
                                  <>
                                    {type === "boolean" && (
                                      <span
                                        className={`badge align-middle ${
                                          !!value ? "bg-success" : "bg-danger"
                                        } me-1`}
                                      ></span>
                                    )}
                                    {type === "date" &&
                                      (!value ? (
                                        "-"
                                      ) : (
                                        <Moment format="Do MMM YYYY / hh:mm a">
                                          {value as string}
                                        </Moment>
                                      ))}
                                    {type === "string" && String(value)}
                                    {type === "image" && (
                                      <div className="px-2 text-center">
                                        {value && (
                                          <img
                                            src={value as string}
                                            alt={key}
                                            className="text-center"
                                            height="30px"
                                            onError={function (x) {
                                              x.currentTarget.src =
                                                "/images/question.png";
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                    {/* {type === "switch" && (
                                      <label className="form-check form-check-single form-switch px-0 ">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={(e) => {}}
                                          checked={!!value}
                                        />
                                      </label>
                                    )} */}
                                    {type === undefined && "TYPE UNDEFINED"}
                                  </>
                                ) : (
                                  <>{value}</>
                                )}
                              </Cell>
                            );
                          })}
                        </Row>
                      );
                    }}
                  />
                </TableTree>
              </div>
            ) : (
              <Tree
                tree={tree}
                renderItem={({
                  item,
                  depth,
                  onExpand,
                  onCollapse,
                  provided,
                }) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => {
                        setTree(
                          mutateTree(tree!, item.id, {
                            isExpanded: !item.isExpanded,
                          })
                        );
                      }}
                      className="py-2 border-bottom cursor-pointer last-child-my-0"
                    >
                      <div className="px-2 py-1">
                        {item.children.length > 0 && (
                          <Icon
                            path={
                              item.isExpanded ? mdiChevronDown : mdiChevronRight
                            }
                            size={0.85}
                          />
                        )}
                        <span>{item.data ? item.data.label : ""}</span>
                      </div>
                    </div>
                  );
                }}
              />
            )}

            <PaginationFooter
              data={data}
              offset={offset}
              limit={limit}
              setLimit={setLimit}
              setOffset={setOffset}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface EditPageFooterProps<T> {
  data?: ServerResponse<ListResponse<T>>;
  limit: number;
  offset: number;
  setLimit: (n: number) => void;
  setOffset: (n: number) => void;
}

export const PaginationFooter = <T extends object>({
  data,
  limit,
  offset,
  setLimit,
  setOffset,
}: EditPageFooterProps<T>) => {
  if (!data || !data.result) return null;

  const page = Math.round(offset / limit);
  const maxPages = Math.max(1, Math.round(data.result.count / limit));
  const endSuggestion = Math.floor(page + (maxPages - page - 5) / 2);

  return (
    <div className="card-footer d-flex align-items-center">
      <p className="text-muted m-0">
        Showing {offset + 1}-{offset + data?.result.rows.length} of{" "}
        {data.result?.count} entries
      </p>
      <ul className="pagination ms-auto m-0">
        <li className={`page-item ${offset / limit <= 0 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={(_) => setOffset((Math.ceil(offset / limit) - 1) * limit)}
          >
            <Icon path={mdiChevronLeft} className="icon" />
            prev
          </button>
        </li>
        {page - 2 > 1 && (
          <Fragment>
            <li className="page-item">
              <button className="page-link" onClick={(_) => setOffset(0)}>
                1
              </button>
            </li>
            <li className="page-item">
              <button className="page-link">...</button>
            </li>
          </Fragment>
        )}

        {page > 0 &&
          range(Math.max(1, page - 2), page).map((e) => (
            <li key={`page-button${e}`} className={`page-item`}>
              <button
                className="page-link"
                onClick={(_) => setOffset((e - 1) * limit)}
              >
                {e}
              </button>
            </li>
          ))}
        <li key={`page-button-active`} className={`page-item active`}>
          <button className="page-link">{page + 1}</button>
        </li>
        {offset + limit < data.result.count &&
          range(
            page + 2,
            Math.min(
              page + 4,
              page + Math.round((data.result.count - offset) / limit)
            )
          ).map((e) => (
            <li key={`page-button${e}`} className={`page-item`}>
              <button
                className="page-link"
                onClick={(_) => setOffset((e - 1) * limit)}
              >
                {e}
              </button>
            </li>
          ))}
        {page + 5 < maxPages && (
          <Fragment>
            <li className="page-item">
              <button className="page-link">...</button>
            </li>
            {endSuggestion > page + 5 && (
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={(_) => setOffset((endSuggestion - 1) * limit)}
                >
                  {endSuggestion}
                </button>
              </li>
            )}
          </Fragment>
        )}
        <li
          className={`page-item ${
            offset + limit >= data.result.count ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={(_) => setOffset((Math.ceil(offset / limit) + 1) * limit)}
          >
            next
            <Icon path={mdiChevronRight} className="icon" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export const ExtraInformationBox = <T, K>({
  model,
  filter,
}: {
  model: ModelName;
  filter: EditPageProps<T, K>["filter"];
}) => {
  const [show, setShow] = useState(false);

  const { isLoading, data } = useModelAggregationQuery({
    model: model,
    enabled: show,
    filter,
  });

  return (
    <Fragment>
      <button
        className="btn"
        onClick={() => {
          setShow(true);
        }}
      >
        Show Extra Information
      </button>
      <CustomModal
        onHide={() => setShow(false)}
        show={show}
        primaryButtonText="Close"
        title="Extra Information"
        showCancelButton={false}
        onPrimaryButtonClick={() => setShow(false)}
      >
        {isLoading ? (
          <div className="my-3 d-flex justify-content-center">
            <MoonLoader color="var(--tblr-primary)" size={30} loading={true} />
          </div>
        ) : (
          <table className="table mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {data?.result?.map((each) => {
                return (
                  <tr>
                    <td>{each["name"]}</td>
                    <td>
                      {typeof each["value"] === "object"
                        ? JSON.stringify(each["value"])
                        : each["value"]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </CustomModal>
    </Fragment>
  );
};

export default EditPage;
