import "./DatePickerCustom.scss";

import DatePicker, {
  DatePickerProps,
} from "react-date-picker/dist/entry.nostyle";

export default function DatePickerCustom({ value, ...props }: DatePickerProps) {
  let dateValue = value;
  if (typeof value === "string") {
    dateValue = new Date(value);
  }
  return <DatePicker {...props} value={dateValue} format="yy/MM/dd" />;
}
