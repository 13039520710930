import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import {
  DataCenterAttributes,
  DataCenterModel,
  NodeTypeAttributes,
  NodeTypeModel,
  PoolModel,
  PoolProfileModel,
} from "common";
import {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { NodeAttributes, NodeModel } from "common";
import { NodeIpsAttributes, NodeIpsModel } from "common";
import { NodeM2MPlatformAttributes, NodeM2MPlatformModel } from "common";
import axios, { AxiosError } from "axios";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import { CheckBoxWithController } from "../../components/FormElements/OneStateInputs";
import ControlledInput from "../../components/FormElements/ControlledInput";
import CreateEditModal from "../../components/Modals/CreateEditModal";
import CustomModal from "../../components/Modals/Modal";
import { DevicePlatformAttributes } from "common";
import EditPage from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import { FormCheckContainer } from "../../components/FormElements/FormCheckContainer";
import Icon from "@mdi/react";
import Input from "../../components/FormElements/ControlledInput";
import { ListResponse } from "../../interfaces/ListResponse";
import { MoonLoader } from "react-spinners";
import RadioSelect, {
  RadioSelectWithController,
} from "../../components/FormElements/RadioSelect";
import React from "react";
import { ServerResponse } from "common";
import { VendorAttributes } from "common";
import { platformIconMap, NodeStatusMap } from "../../constants";
import { mdiCog, mdiDelete } from "@mdi/js";
import useAuthorizedQuery from "../../libs/hooks/useAuthorizedQuery";
import useFetcher from "../../libs/hooks/useFetcher";
import { usePermissions } from "../../libs/hooks/usePermissions";
import { useQuery } from "react-query";
import DatePickerCustom from "../../components/FormElements/DatePickerCustom";
import { ItemId, TreeItem, TreeData } from "@atlaskit/tree";
import { useAlert } from "react-alert";
import moment from "moment";
import { useIsNoc } from "../../libs/hooks/useIsNoc";

// import { MultiSelectWithController } from "../../components/FormElements/MultiSelect";

export const NodeControl = (props: {
  sshUserName?: string;
  id: number;
  nodeType: "node" | "proxyNode";
  sshPassword?: string;
  ip?: string;
  name: string;
}) => {
  const { sshPassword, sshUserName, id, ip, name, nodeType } = props;
  const [modelShown, setModelShown] = useState(false);
  const closeModel = () => setModelShown(false);
  const fetcher = useFetcher({});

  const { data: prevRecords } = useAuthorizedQuery<any>({
    filter: {
      actionName:
        nodeType === "node"
          ? "OpenConnect_InstallServer"
          : "Proxy_InstallServer",
      id,
    },
    model: "actionStatus",
    enabled: modelShown,
  });

  // const silentFetcher = useFetcher({ alerts: false });
  const [selectedRecord, setSelectedRecord] = useState<any>();

  const [isActionBusy, setActionAsBusy] = useState<Record<string, boolean>>({});

  const runAction = async (action: string) => {
    setActionAsBusy((prev) => ({ ...prev, [action]: true }));

    try {
      await fetcher({
        url: `/api/v1/data/${nodeType}/runAction`,
        method: "post",
        data: {
          action: action,
          nodeId: id,
        },
      });
    } catch (e) {}

    setActionAsBusy((prev) => ({ ...prev, [action]: false }));
  };

  const alert = useAlert();

  return (
    <Fragment>
      <CustomModal
        show={modelShown}
        title={`${name} / ${ip}`}
        onHide={closeModel}
        onPrimaryButtonClick={closeModel}
        primaryButtonText="Close"
        showCancelButton={false}
      >
        <div className="m-3">
          <div className="d-flex flex-row justify-content-between w-100">
            <div>
              <h3>Actions</h3>
              {sshPassword && sshUserName && (
                <div className="btn-group mb-3">
                  <button
                    className="btn btn-danger"
                    onClick={(_) => runAction("reboot")}
                    disabled={isActionBusy.reboot}
                  >
                    Reboot Server
                  </button>
                  {nodeType === "node" && (
                    <>
                      <button
                        className="btn btn-warning"
                        onClick={(_) => runAction("restartServer")}
                        disabled={isActionBusy.restartServer}
                      >
                        Service Restart
                      </button>
                    </>
                  )}
                  <button
                    className="btn btn-green"
                    onClick={(_) => runAction("installServer")}
                    disabled={isActionBusy.installServer}
                  >
                    {(prevRecords?.result?.count || 0) > 0 && "Re-"}Install
                    Server
                  </button>
                  <a
                    href={`ssh://${sshUserName}@${ip}:22`}
                    onClick={async (e) => {
                      await navigator.clipboard?.writeText(sshPassword || "");
                      alert.info("SSH Password copied to clipboard");
                    }}
                    className="btn btn-primary"
                  >
                    SSH
                  </a>
                </div>
              )}
            </div>
          </div>
          <h3>Install Logs</h3>
        </div>
        <table className="table card-table">
          <thead>
            <tr>
              <th>Action</th>
              <th>Status</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {prevRecords?.result?.rows.map((row) => (
              <tr>
                <td>{row.actionName}</td>
                <td
                  className={
                    row.status === "FAILED"
                      ? "text-danger"
                      : row.status === "RUNNING"
                      ? "text-yellow"
                      : "text-success"
                  }
                >
                  {row.status}
                </td>
                <td>{moment(row.startedAt).format("HH:mm - DD/MM/YYYY")}</td>
                <td>
                  {row.endedAt
                    ? moment(row.endedAt).format("HH:mm - DD/MM/YYYY")
                    : "-"}
                </td>
                <td>
                  <button
                    className="btn"
                    onClick={(_) => setSelectedRecord(row)}
                  >
                    Logs
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <CustomModal
          show={!!selectedRecord}
          showCancelButton={false}
          onHide={() => setSelectedRecord(undefined)}
          primaryButtonText="Close"
          title="Logs"
          onPrimaryButtonClick={() => setSelectedRecord(undefined)}
        >
          <div className="m-3">
            <h3>STDOUT</h3>
            <pre style={{ maxHeight: "40vh" }}>{selectedRecord?.stdout}</pre>
            <h3>STDERROR</h3>
            <pre style={{ maxHeight: "40vh" }} className="text-danger">
              {selectedRecord?.stderr}
            </pre>
          </div>
        </CustomModal>
      </CustomModal>
      <button className="btn p-1 btn-link" onClick={(_) => setModelShown(true)}>
        <Icon path={mdiCog} size={0.8} />
      </button>
    </Fragment>
  );
};

export function PlatformRelationInput({ nodeId }: { nodeId: number }) {
  const cacheName = `${nodeId}-NodeWithDeviceRelationData`;
  const fetcher = useFetcher({
    invalidateCache: true,
    queryKey: cacheName,
  });
  const queryFetcher = useFetcher({ invalidateCache: false, alerts: false });
  const { control, register, setValue } = useForm<NodeM2MPlatformAttributes>();
  const permissions = usePermissions("nodeM2MPlatform");
  const [busy, setBusy] = useState(false);
  const watch = useWatch({ control });

  const { isFetching, data: relationshipQueryData } = useQuery<
    ServerResponse<ListResponse<NodeM2MPlatformAttributes>>,
    AxiosError
  >(
    cacheName,
    () => {
      const source = axios.CancelToken.source();
      const params = {
        intPkNodeID: { equals: nodeId },
      };

      const promise = queryFetcher<
        ServerResponse<ListResponse<NodeM2MPlatformAttributes>>
      >({
        method: "get",
        url: `/api/v1/data/nodeM2MPlatform/list`,
        options: {
          params,
          cancelToken: source.token,
        },
      });

      // @ts-ignore
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };

      return promise;
    },
    {
      enabled: permissions.allowRead,
    }
  );

  const removeRelation = useCallback((relationID: number) => {
    setBusy(true);
    fetcher({
      url: `/api/v1/data/nodeM2MPlatform/${relationID}`,
      method: "delete",
      successMessage: "Platform deleted successfully",
    }).finally(() => {
      setValue("intPkDevicePlatformID", NaN);
      setBusy(false);
    });
  }, []);

  useEffect(() => {
    const data = watch;
    if (data.intPkDevicePlatformID) {
      fetcher({
        url: "/api/v1/data/nodeM2MPlatform/create",
        method: "post",
        data,
        successMessage: "Platform added successfully",
      }).finally(() => {
        setValue("intPkDevicePlatformID", NaN);
      });
    }
  }, [watch]);

  if (!permissions.allowRead) {
    return null;
  }

  return (
    <>
      <input
        type="hidden"
        {...register("intPkNodeID", { required: true, valueAsNumber: true })}
        value={nodeId}
      />
      <div className="form-group mt-3 flex-fill">
        <label className="form-label">Platforms</label>
        <AsyncSelectWithController<
          NodeM2MPlatformAttributes,
          DevicePlatformAttributes
        >
          control={control}
          valueFieldName="intPkDevicePlatformID"
          readOnly={!permissions.allowCreate}
          searchField="platform"
          filter={{
            // @ts-ignore
            intPkDevicePlatformID: {
              notIn: relationshipQueryData?.result?.rows.map(
                (e) => e.intPkDevicePlatformID
              ),
            },
          }}
          model="devicePlatform"
        />
      </div>
      {(isFetching || busy) && (
        <div className="my-3 d-flex justify-content-center">
          <MoonLoader color="var(--tblr-primary)" size={30} loading={true} />
        </div>
      )}
      {relationshipQueryData?.result &&
        relationshipQueryData.result.rows.map((e: NodeM2MPlatformModel) => {
          const platform = e.intPkDevicePlatform;

          return (
            <div className="card mt-2">
              <div className="card-body p-2 d-flex flex-row align-items-center justify-content-between">
                <span>
                  {platform && (
                    <Icon
                      className="me-2"
                      path={platformIconMap[platform.platform]}
                      size={0.7}
                    />
                  )}
                  {platform?.platform}
                </span>
                {permissions.allowDelete && (
                  <a
                    className="p-1 d-inline-block danger cursor-pointer"
                    onClick={(_) => {
                      removeRelation(e.intPkNodeM2mPlatformID);
                    }}
                  >
                    <Icon path={mdiDelete} size={0.7} />
                  </a>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
}

export type NodeFormAttributes = NodeAttributes & {
  firstIPAddress: string;
};

export type NodeModelExt = NodeModel & NodeFormAttributes & { nodeStat: any };

const NodesPage: FunctionComponent = () => {
  const isNoc = useIsNoc();
  const [filter, setFilter] = useState({});
  const [lastUpdateTime, setLastUpdateTime] = useState<Date>();

  return (
    <EditPage<NodeFormAttributes, NodeModelExt>
      modelName="node"
      buildTreeView={(data) => {
        if (!data?.result?.rows) return;

        const nodeInfos = data.result.rows.reduce((record, node) => {
          const rootId = node["intPkNodeID"];
          const poolsRootId = `${rootId}-pools`;

          const poolTreeItemIds = node.pools_m2m_nodes?.map((poolRelation) => {
            // @ts-ignore
            const pool: PoolModel = poolRelation.intPkPool;
            const poolItemId = `${poolsRootId}-${poolRelation.intPkPoolID}`;
            const poolProfileRootItemId = `${poolItemId}-profile`;

            const poolProfilesTreeItems = pool.pools_statuses
              ?.map((poolStatus) => {
                // @ts-ignore
                const profile: PoolProfileModel = poolStatus.intPkPoolProfile;
                const poolProfileItemId = `${poolProfileRootItemId}-${profile.intPkPoolProfileID}`;
                const brandRootItemId = `${poolProfileItemId}-brands`;

                if (record[poolProfileItemId]) return null;

                record[poolProfileItemId] = {
                  id: poolProfileItemId,
                  data: { label: profile.poolProfileName },
                  children: [brandRootItemId],
                };

                const brandTreeItems = profile.brands_statuses
                  ?.map((brandStatus) => {
                    // @ts-ignore
                    const brandItemId = `${brandRootItemId}-${brandStatus.intPkBrandID}`;

                    if (record[brandItemId]) return null;

                    record[brandItemId] = {
                      id: brandItemId,
                      // @ts-ignore
                      data: { label: brandStatus.intPkBrand.brandName },
                      children: [],
                    };
                    return brandItemId;
                  })
                  .filter((e) => e !== null) as string[];

                record[brandRootItemId] = {
                  id: brandRootItemId,
                  data: { label: "Brands" },
                  children: brandTreeItems || [],
                  hasChildren: false,
                  isExpanded: false,
                };

                return poolProfileItemId;
              })
              .filter((e) => e !== null) as string[];

            record[poolItemId] = {
              id: poolItemId,
              data: { label: pool.poolName },
              children: [poolProfileRootItemId],
            };

            record[poolProfileRootItemId] = {
              id: poolProfileRootItemId,
              data: { label: "Profiles" },
              children: poolProfilesTreeItems || [],
            };

            return poolItemId;
          });

          record[poolsRootId] = {
            id: poolsRootId,
            data: { label: "Pools" },
            children: poolTreeItemIds || [],
          };

          record[rootId] = {
            id: rootId,
            data: { label: node["name"] },
            children: [poolsRootId],
          };

          return record;
        }, {} as Record<ItemId, TreeItem>);

        const treeData: TreeData = {
          rootId: "node",
          items: {
            ...nodeInfos,
            node: {
              id: "node",
              data: { label: "Node" },
              children: data.result.rows.map((e) => e["intPkNodeID"]),
              hasChildren: true,
              isExpanded: false,
              isChildrenLoading: false,
            },
          },
        };

        console.log(treeData);

        return treeData;
      }}
      onNewData={(data) => {
        const rows = data.result?.rows;
        const lastUpdateTime = rows?.reduce((prev, acc) => {
          const cur = new Date(acc.nodeStat?.createdAt);
          if (prev < cur) {
            prev = cur;
          }

          return prev;
        }, new Date(0));

        if (lastUpdateTime && rows) {
          for (let i = 0; i < (rows?.length || 0); i++) {
            if (
              !rows[i].nodeStat?.createdAt ||
              Math.abs(
                new Date(rows[i].nodeStat.createdAt).getTime() -
                  lastUpdateTime.getTime()
              ) >
                3 * 60 * 1000 /* 3min */
            ) {
              if (!rows[i]?.nodeStat) continue;
            }
          }
        }

        setLastUpdateTime(lastUpdateTime);
      }}
      recordStyles={(z) => {
        if (!lastUpdateTime) return;
        if (
          !z.nodeStat?.createdAt ||
          Math.abs(
            new Date(z.nodeStat.createdAt).getTime() - lastUpdateTime.getTime()
          ) >
            5 * 60 * 1000 /* 5 min */
        ) {
          return {
            backgroundColor: "rgba(241,194,50, .15)",
          };
        }
      }}
      fields={[
        "name",
        {
          key: "intPkDataCenter.intPkCountry.flagImageUrl",
          type: "image",
          name: "Country",
        },
        "nodeIP",
        {
          key: "intPkDataCenter.dataCenterName",
          type: "string",
          name: "Data Center",
        },

        {
          key: "includeNodeInAutoFreeRouting",
          type: "boolean",
          name: "AFR",
        },
        {
          key: "includeNodeInAutoPremiumRouting",
          type: "boolean",
          name: "APR",
        },
        { name: "CPU", value: (z) => <>{z.nodeStat?.cpu?.toFixed(1)}%</> },
        { name: "MEM", value: (z) => <>{z.nodeStat?.mem?.toFixed(1)}%</> },
        {
          name: "RX",
          value: (z) => <>{((z.nodeStat?.rx || 0) / 1024 / 1024).toFixed(2)}</>,
        },
        {
          name: "TX",
          value: (z) => <>{((z.nodeStat?.tx || 0) / 1024 / 1024).toFixed(2)}</>,
        },
        { key: "nodeStat.connected_users", type: "string", name: "Con. Users" },
        {
          name: "Status",
          value: (z) => {
            const status =
              NodeStatusMap[z.nodeStat?.service_status] || NodeStatusMap.error;

            return (
              <Fragment>
                <Icon path={status?.icon} color={status?.color} size={0.9} />
                <span
                  title={z.nodeStat?.service_status}
                  className="ms-1"
                  style={{
                    color: status?.color,
                    borderBottom: z.nodeStat?.service_status   && "1px dashed",
                  }}
                >
                  {status?.text}
                </span>
              </Fragment>
            );
          },
        },
        { key: "enable", type: "switch", name: "Enable" },
      ]}
      filter={filter}
      isRecordEditable={(record) => !isNoc}
      renderExtraCardControls={(z) => {
        if (z && !isNoc) {
          return (
            <NodeControl
              nodeType="node"
              id={z?.intPkNodeID}
              sshPassword={z?.sshPassword}
              ip={z?.nodeIP}
              sshUserName={z?.sshUserName}
              name={z?.name}
            />
          );
        }
      }}
      showAggregations
      defaultValues={{
        enable: 1,
        startDate: new Date(),
        includeNodeInAutoFreeRouting: 1,
        maxUsers: 30,
        includeNodeInAutoPremiumRouting: 1,
      }}
      renderCardHeader={() => (
        <>
          <div className="d-flex align-items-center">
            <b>Status: </b>
            <span className="text-muted">
              (last update: {moment(lastUpdateTime).fromNow()})
            </span>
          </div>
          <FilterPicker modalSize="lg" filter={filter} onFilter={setFilter}>
            <NodesFilterForm />
          </FilterPicker>
        </>
      )}
      idField="intPkNodeID"
      nameField="name"
      renderForm={({ model, readOnly, isEditMode }) => (
        <NodesForm readOnly={readOnly} isEditMode={isEditMode} model={model} />
      )}
    />
  );
};

export const NodesForm = ({
  readOnly,
  isEditMode,
  model,
}: CRUDFormProps<NodeFormAttributes>) => {
  const {
    register,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useFormContext<NodeFormAttributes>();

  const [showIPAddressModal, setShowIPAddressModal] = useState(false);

  const [commentType] = watch(["commentType"]);

  useEffect(() => {
    if (commentType === undefined) {
      setValue("comment", undefined);
    }
  }, [commentType]);

  return (
    <div className="m-3">
      <div className="d-flex flex-row mb-3">
        <Input
          register={register("name", { required: "Required" })}
          title="Node Name"
          readOnly={readOnly}
          errorMessage={errors.name?.message}
        />
        <Input
          register={register("displayString", { required: "Required" })}
          title="Display String"
          readOnly={readOnly}
          className="mx-3"
          errorMessage={errors.displayString?.message}
        />
        <div className="form-group mb-3">
          <label className="form-label">Start Date</label>
          <Controller
            name="startDate"
            control={control}
            render={({ field: { name, onBlur, onChange, value } }) => {
              return (
                <DatePickerCustom
                  {...{ name, onBlur, value, onChange }}
                  disabled={readOnly}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="d-flex mb-3">
        <Input
          register={register("nodePort", {
            required: "Required",
          })}
          readOnly={readOnly}
          type="number"
          title="Node Port"
          errorMessage={errors.nodePort?.message}
        />
        <Input
          register={register("maxUsers", {
            required: "Required",
          })}
          readOnly={readOnly}
          className="mx-3"
          type="number"
          title="Max Users"
          errorMessage={errors.nodePort?.message}
        />
      </div>
      <div className="d-flex mb-3">
        <Input
          register={register("sshUserName", {
            required: "Required",
          })}
          readOnly={readOnly}
          title="SSH UserName"
          errorMessage={errors.sshUserName?.message}
        />
        <Input
          register={register("sshPassword", {
            required: "Required",
          })}
          readOnly={readOnly}
          className="mx-3"
          title="SSH Password"
          errorMessage={errors.sshPassword?.message}
        />
      </div>
      <div className="form-group mb-3 mt-3 flex-fill">
        <label className="form-label">Node Type</label>
        <AsyncSelectWithController<NodeFormAttributes, NodeTypeAttributes>
          searchField="type"
          model="nodeType"
          readOnly={readOnly}
          control={control}
          valueFieldName="intPkNodeTypeID"
        />
      </div>
      {isEditMode ? (
        <Fragment>
          <div>
            <b>Current IP:</b> {model?.nodeIP}
          </div>
          <button
            type="button"
            className="btn btn-primary mb-3"
            onClick={() => {
              setShowIPAddressModal(true);
            }}
          >
            Manage IP Addresses
          </button>
          <NodesIPAddressSelectModal
            nodeID={model?.intPkNodeID}
            show={showIPAddressModal}
            intPkNodeIPID={model?.intPkNodeIPID}
            onHide={() => setShowIPAddressModal(false)}
          />
        </Fragment>
      ) : (
        <Input
          register={register("firstIPAddress", {
            required: "Required",
          })}
          title="IP Address"
          readOnly={readOnly}
          className="mb-3"
          errorMessage={errors.firstIPAddress?.message}
        />
      )}
      <CheckboxTileGroup>
        <CheckboxTile
          name="enable"
          readOnly={readOnly}
          control={control}
          errorMessage={errors.enable?.message}
          title="Enable"
        />
        <CheckboxTile
          name="includeNodeInAutoFreeRouting"
          readOnly={readOnly}
          control={control}
          title="Include Node In Free Auto Routing"
        />
        <CheckboxTile
          name="includeNodeInAutoPremiumRouting"
          readOnly={readOnly}
          control={control}
          title="Include Node In Premium Auto Routing"
        />
      </CheckboxTileGroup>
      <div className="form-group mb-3 mt-3 flex-fill ">
        <label className="form-label">Data Center</label>
        <AsyncSelectWithController<NodeFormAttributes, DataCenterAttributes>
          searchField="dataCenterName"
          model="dataCenter"
          readOnly={readOnly}
          control={control}
          valueFieldName="intPkDataCenterID"
        />
      </div>
      <RadioSelectWithController
        control={control}
        title="Comment Type"
        name="commentType"
        options={[
          {
            label: "ℹ️ Info",
            value: "INFO",
          },
          {
            label: "⚠️ Warning",
            value: "WARNING",
          },
          {
            label: "💀 Danger",
            value: "DANGER",
          },
        ]}
      />
      {!!commentType && (
        <div className="form-group">
          <label className="form-label">Comment</label>
          <textarea
            className="form-control"
            {...register("comment")}
            placeholder="Type comment for this node..."
          />
        </div>
      )}
      {isEditMode && model && model.intPkNodeID && (
        <PlatformRelationInput nodeId={model.intPkNodeID} />
      )}
    </div>
  );
};

const NodesIPAddressSelectModal = ({
  show,
  onHide,
  nodeID,
  intPkNodeIPID,
}: {
  show: boolean;
  onHide: () => void;
  nodeID?: number;
  intPkNodeIPID?: number;
}) => {
  const [ipAddressQuery, setIpAddressQuery] = useState("");

  const { data, queryKey, isLoading } = useAuthorizedQuery<NodeIpsModel>({
    model: "nodeIP",
    enabled: !!nodeID,
    filter: {
      // @ts-ignore
      intPkNodeID: { equals: nodeID },
      ipAddress: ipAddressQuery !== "" ? ipAddressQuery : undefined,
    },
  });

  const { queryKey: nodeQueryKey } = useAuthorizedQuery({
    model: "node",
    enabled: false,
  });

  const ipFetcher = useFetcher({ queryKey: queryKey });
  const nodeFetcher = useFetcher({ queryKey: nodeQueryKey });

  const [activeIPAddress, setActiveIPAddress] = useState(intPkNodeIPID);
  const [selectedIPAddress, setSelectedIPAddress] =
    useState<NodeIpsAttributes>();

  const [busy, setBusy] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);

  const inputFieldRef = React.createRef<HTMLInputElement>();

  const changeActiveIPAddress = async (ip: NodeIpsAttributes) => {
    setBusy(true);
    const prev = activeIPAddress;
    setActiveIPAddress(ip.intPkNodeIPID);
    try {
      await nodeFetcher({
        method: "put",
        url: `/api/v1/data/node/${nodeID}/edit`,
        data: {
          intPkNodeIPID: ip.intPkNodeIPID,
        },
        successMessage: "Changed Active IP Successfully!",
      });
    } catch (e: any) {
      setActiveIPAddress(prev);
    } finally {
      setBusy(false);
      setSelectedIPAddress(undefined);
    }
  };

  const addIPAddress = async (ipAddress: string) => {
    setBusy(true);
    try {
      await ipFetcher({
        method: "post",
        url: "/api/v1/data/nodeIP/create",
        data: {
          intPkNodeID: nodeID,
          ipAddress,
        },
        successMessage: "IP Address Added Successfully!",
      });
    } finally {
      setBusy(false);
    }
  };

  const validateIPAddress = (s: string) =>
    s.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/i);

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const ipAddress = event.currentTarget.value;

    setIpAddressQuery(ipAddress);

    if (
      (event.code === "Enter" || event.code === "NumpadEnter") &&
      validateIPAddress(ipAddress) &&
      !data?.result?.rows.find((e) => e.ipAddress === ipAddress)
    ) {
      addIPAddress(ipAddress).finally(() => {
        if (inputFieldRef.current) inputFieldRef.current.value = "";
      });
    }
  };

  useEffect(() => {
    if (!reportModalShow) {
      if (
        selectedIPAddress &&
        activeIPAddress &&
        selectedIPAddress.intPkNodeIPID !== activeIPAddress
      ) {
        changeActiveIPAddress(selectedIPAddress);
      }
    }
  }, [reportModalShow]);

  useEffect(() => {
    if (!!selectedIPAddress) {
      setReportModalShow(true);
    }
  }, [selectedIPAddress]);

  return (
    <CustomModal
      show={show}
      onPrimaryButtonClick={onHide}
      onHide={onHide}
      primaryButtonText="Okay"
      size="sm"
      title="IP Address Settings"
    >
      <CreateEditModal<NodeIpsAttributes>
        open={reportModalShow}
        onHide={() => {
          setReportModalShow(false);
        }}
        idField="intPkNodeIPID"
        nameField="ipAddress"
        modelName="nodeIP"
        title="Node IP"
        model={data?.result?.rows.find(
          (e) => e.intPkNodeIPID === activeIPAddress!
        )}
        renderForm={({ register }) => (
          <div className="m-3">
            <ControlledInput
              register={register("ipAddress")}
              title="IP Address"
              readOnly={true}
              className="mb-3"
            />
            <div className="form-group">
              <label className="form-label">Comment</label>
              <textarea
                className="form-control"
                {...register("comment")}
              ></textarea>
            </div>
          </div>
        )}
      ></CreateEditModal>
      <div className="m-3">
        <input
          type="text"
          className="form-control mb-3"
          onKeyUp={handleKeyUp}
          placeholder="Search or Create IP Address"
          readOnly={busy}
          ref={inputFieldRef}
        />
        {isLoading && (
          <div className="d-flex flex-row justify-content-center">
            <MoonLoader size={30} />
          </div>
        )}
        {data?.result?.rows && data?.result?.rows.length < 1 && (
          <div className="text-center">
            <span className="text-info ">
              No IP Addresses
              {ipAddressQuery !== "" && validateIPAddress(ipAddressQuery)
                ? `, Create "${ipAddressQuery}" by Pressing Enter`
                : ""}
            </span>
          </div>
        )}
        {data?.result?.rows.map((value) => {
          return (
            <label className="form-selectgroup-item flex-fill mt-2">
              <input
                type="radio"
                name="active-ip-address"
                className="form-selectgroup-input"
                checked={value.intPkNodeIPID === activeIPAddress}
                onChange={(_) => {
                  setSelectedIPAddress(value);
                }}
                disabled={busy}
              />
              <div className="form-selectgroup-label d-flex p-3">
                <div className="me-3">
                  <span className="form-selectgroup-check"></span>
                </div>
                <div>{value.ipAddress}</div>
              </div>
            </label>
          );
        })}
      </div>
    </CustomModal>
  );
};

export const NodesFilterForm = () => {
  const methods = useFormContext<any>();
  const [brandFilterType, setBrandFilterType] = useState<string | undefined>(
    undefined
  );

  const [countryFilter, ispFilter, brandFilter] = methods.watch([
    "pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkCountryID",
    "pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkIspID",
    "pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkBrandID",
  ]);

  useEffect(() => {
    if (countryFilter === undefined && ispFilter === undefined) {
      setBrandFilterType("none");
    } else if (countryFilter === null && ispFilter === null) {
      setBrandFilterType("international");
    } else {
      setBrandFilterType("countrywise");
    }
  }, [countryFilter, ispFilter]);

  useEffect(() => {
    if (brandFilterType === "international") {
      methods.setValue(
        "pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkCountryID",
        null
      );
      methods.setValue(
        "pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkIspID",
        null
      );
    } else if (brandFilterType === "none") {
      methods.setValue(
        "pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkCountryID",
        undefined
      );
      methods.setValue(
        "pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkIspID",
        undefined
      );
    }
  }, [brandFilterType]);

  const [mainValue, intPkVendorID, intPkCountryID] = methods.watch([
    "or._0.name",
    "vendorFilter.intPkVendorID",
    "countryFilter.intPkCountryID",
  ]);

  const { data: platformRecords } =
    useAuthorizedQuery<DevicePlatformAttributes>({
      model: "devicePlatform",
    });

  return (
    <div className="m-3">
      <Input
        register={methods.register("or._0.name")}
        title="Search by IP, Name, Display String"
        className="mb-3"
      />
      <Input
        register={methods.register("or._1.displayString", { value: mainValue })}
        title=""
        type="hidden"
        readOnly
      />
      <Input
        register={methods.register("or._2.nodeIP", { value: mainValue })}
        title=""
        type="hidden"
        readOnly
      />
      <div className="d-flex flex-row mt-3">
        <div className="d-flex flex-row">
          <Input
            register={methods.register("nodeStat.cpu.gt", {
              valueAsNumber: true,
            })}
            title="CPU >"
            type="number"
            className="mb-3 me-3"
          />
          <Input
            register={methods.register("nodeStat.cpu.lt", {
              valueAsNumber: true,
            })}
            title="CPU <"
            type="number"
            className="mb-3 me-3"
          />
        </div>
        <div className="d-flex flex-row">
          <Input
            register={methods.register("nodeStat.mem.gt", {
              valueAsNumber: true,
            })}
            title="MEM >"
            type="number"
            className="mb-3 me-3"
          />
          <Input
            register={methods.register("nodeStat.mem.lt", {
              valueAsNumber: true,
            })}
            title="MEM <"
            type="number"
            className="mb-3"
          />
        </div>
      </div>
      <div className="d-flex flex-row">
        <Input
          register={methods.register("nodeStat.connected_users.gt", {
            valueAsNumber: true,
          })}
          title="CONNECTED USERS >"
          type="number"
          className="mb-3 me-3"
        />
        <Input
          register={methods.register("nodeStat.connected_users.lt", {
            valueAsNumber: true,
          })}
          title="CONNECTED USERS <"
          type="number"
          className="mb-3"
        />
      </div>
      <Input register={methods.register("comment")} title="Search by Comment" />
      <div className="d-flex flex-row mt-3">
        <div className="form-group flex-fill ">
          <label className="form-label">Vendor</label>
          <AsyncSelectWithController<any, VendorAttributes>
            searchField="vendorName"
            model="vendor"
            control={methods.control}
            valueFieldName="vendorFilter.intPkVendorID"
          />
        </div>

        <div className="form-group ms-3 flex-fill ">
          <label className="form-label">Country</label>
          <AsyncSelectWithController<any, any>
            searchField="countryName"
            model="country"
            imageField="flagImageUrl"
            filter={{ datacenters: { some: { intPkVendorID } } }}
            control={methods.control}
            valueFieldName="countryFilter.intPkCountryID"
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="form-group  mt-3 flex-fill ">
          <label className="form-label">Data Center</label>
          <AsyncSelectWithController<any, DataCenterModel>
            searchField="dataCenterName"
            model="dataCenter"
            imageField="intPkCountry.flagImageUrl"
            filter={{
              // @ts-ignore
              intPkVendorID: { equals: intPkVendorID },
              // @ts-ignore
              intPkCountryID: { equals: intPkCountryID },
            }}
            control={methods.control}
            valueFieldName="intPkDataCenterID"
          />
        </div>
        <div className="form-group ms-3 mt-3 flex-fill ">
          <label className="form-label">Node Type</label>
          <AsyncSelectWithController<any, NodeTypeModel>
            searchField="type"
            model="nodeType"
            control={methods.control}
            valueFieldName="intPkNodeTypeID"
          />
        </div>
      </div>
      <div className="d-flex flex-row mt-3">
        <div className="flex-fill">
          <RadioSelectWithController
            control={methods.control}
            name="enable"
            title="Enable Status"
            options={[
              { label: "All", value: undefined },
              { label: "Enabled", value: 1 },
              { label: "Disabled", value: 0 },
            ]}
          />
        </div>
        <div className="flex-fill">
          <RadioSelectWithController
            control={methods.control}
            name="nodeStat.service_status"
            title="Service Status"
            options={[
              { label: "All", value: undefined },
              ...Object.entries(NodeStatusMap).map(([key, value]) => {
                return { label: value.text, value: key };
              }),
            ]}
          />
        </div>
      </div>
      <label className="form-label">Platforms</label>
      {platformRecords?.result?.rows.map((e, index) => (
        <FormCheckContainer label={e.platform} key={`platform-input-${index}`}>
          <CheckBoxWithController
            control={methods.control}
            name={`devicePlatformFilter.intPkDevicePlatformID.in._${index}`}
            value={e.intPkDevicePlatformID}
          />
        </FormCheckContainer>
      ))}
      <hr />
      <div className="flex-fill">
        <RadioSelectWithController
          control={methods.control}
          name="pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.isFreeOrTrialOrPremium"
          title="BrandStatusType"
          options={[
            { label: "All", value: undefined },
            { label: "Free", value: 1 },
            { label: "Premium", value: 3 },
          ]}
        />
      </div>
      <div className="form-group mt-2"></div>
      <div className="form-group flex-fill mb-3">
        <label className="form-label">Brand</label>
        <AsyncSelectWithController<any, any>
          searchField="brandName"
          model="brand"
          imageField="iconUrl"
          control={methods.control}
          valueFieldName="pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkBrandID"
        />
      </div>
      <RadioSelect
        name="doesntmatter"
        title="Filter by"
        value={brandFilterType}
        options={[
          { label: "None", value: "none" },
          { label: "International", value: "international" },
          { label: "Countrywise", value: "countrywise" },
        ]}
        onOptionSelect={(e) => {
          setBrandFilterType(e as string);
        }}
      />
      {brandFilterType === "countrywise" && (
        <>
          <div className="form-group flex-fill mb-3">
            <label className="form-label">Brand {"->"} Country</label>
            <AsyncSelectWithController<any, any>
              searchField="countryName"
              model="country"
              filter={{
                brands_statuses: brandFilter && {
                  some: {
                    intPkBrandID: brandFilter,
                  },
                },
              }}
              imageField="flagImageUrl"
              control={methods.control}
              valueFieldName="pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkCountryID"
            />
          </div>
          <div className="form-group flex-fillmb-3">
            <label className="form-label">Brand {"->"} ISP</label>
            <AsyncSelectWithController<any, any>
              searchField="ispName"
              model="isp"
              filter={{
                brands_statuses: (brandFilter || countryFilter) && {
                  some: {
                    intPkBrandID: brandFilter,
                    intPkCountryID: countryFilter,
                  },
                },
              }}
              imageField="ispLogoUrl"
              control={methods.control}
              valueFieldName="pools_m2m_nodes.some.intPkPool.is.pools_statuses.some.intPkPoolProfile.brands_statuses.some.intPkIspID"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default NodesPage;
