import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import ControlledInput from "../../components/FormElements/ControlledInput";
import DatePickerCustom from "../../components/FormElements/DatePickerCustom";
import EditPage from "../../components/EditPage";
import { FunctionComponent } from "react";
import { PoolAttributes } from "common";
import { PoolProfileAttributes } from "common";
import { PoolsStatusAttributes } from "common";
import Select from "react-select";
import { optionsForStatus } from "../../constants";

type PoolProfileFormAttributes = PoolProfileAttributes & {
  statuses: PoolsStatusAttributes[];
};

const ProfilesPage: FunctionComponent = () => {
  return (
    <EditPage<PoolProfileFormAttributes>
      modelName="poolProfile"
      fields={[
        "intPkPoolProfileID",
        "poolProfileName",
        { key: "startDate", type: "date" },
        { key: "enable", type: "boolean" },
      ]}
      idField="intPkPoolProfileID"
      defaultValues={{
        startDate: new Date(),
        enable: 0,
        statuses: [
          { enable: 1, isFreeOrTrialOrPremium: 1, listPlaceNumber: 1 },
          { enable: 1, isFreeOrTrialOrPremium: 3, listPlaceNumber: 1 },
        ],
      }}
      nameField="poolProfileName"
      renderForm={({ readOnly, model, isEditMode }) => (
        <ProfilesForm
          readOnly={readOnly}
          model={model}
          isEditMode={isEditMode}
        />
      )}
    />
  );
};

const ProfilesForm = ({
  readOnly,
  isEditMode,
}: CRUDFormProps<PoolProfileAttributes>) => {
  const {
    formState: { errors },
    register,
    watch,
    control,
  } = useFormContext<PoolProfileFormAttributes>();

  const [statuses] = watch(["statuses"]);
  const canBeEnabled =
    (statuses && statuses.every((e) => e.enable)) || !statuses;

  const { fields } = useFieldArray({ control, name: "statuses" });

  return (
    <div className="m-3">
      <ControlledInput
        className="mb-3"
        register={register("poolProfileName", { required: "Required" })}
        errorMessage={errors.poolProfileName?.message}
        readOnly={readOnly}
        title="Pool Profile Name"
      />
      <ControlledInput
        className="mb-3"
        register={register("autoRoutingDisplayString", {
          required: "Required",
        })}
        errorMessage={errors.autoRoutingDisplayString?.message}
        readOnly={readOnly}
        title="Auto Routing Display String"
      />
      <ControlledInput
        className="mb-3"
        register={register("autoRoutingIconUrl", {
          required: "Required",
        })}
        errorMessage={errors.autoRoutingIconUrl?.message}
        readOnly={readOnly}
        title="Auto Routing Icon URL"
      />
      <div className="form-group mb-3">
        <label className="form-label">Start Date</label>
        <Controller
          name="startDate"
          control={control}
          render={({ field: { name, onBlur, onChange, value } }) => {
            return <DatePickerCustom {...{ name, onBlur, value, onChange }} disabled={readOnly} />;
          }}
        />
      </div>
      <CheckboxTileGroup>
        <CheckboxTile
          name="enable"
          control={control}
          title="Enable"
          readOnly={!canBeEnabled || readOnly}
          errorMessage={!canBeEnabled ? "Please enable all the switch below." : undefined}
        />
      </CheckboxTileGroup>

      {!isEditMode &&
        fields.map((_, index) => (
          <div className="card mt-3">
            <div className="card-header">
              <h1 className="card-title">Pool Status {index + 1}</h1>
            </div>
            <div className="card-body">
              <div className="d-flex flex-row">
                <ControlledInput
                  className="mb-3"
                  register={register(`statuses.${index}.listPlaceNumber` as const)}
                  type="number"
                  errorMessage={errors.statuses?.[index]?.listPlaceNumber?.message}
                  title="List Place Number"
                  defaultValue={1}
                  readOnly={true}
                />
                <div className="form-group ms-3 flex-fill ">
                  <label className="form-label">Status</label>
                  <Controller
                    name={`statuses.${index}.isFreeOrTrialOrPremium` as const}
                    control={control}
                    render={({ field: { name, onBlur, onChange, value } }) => (
                      <Select
                        onChange={(e) => onChange(e?.value)}
                        onBlur={onBlur}
                        isSearchable={false}
                        menuIsOpen={false}
                        isClearable={false}
                        name={name}
                        value={optionsForStatus.find((e) => e.value === value)}
                        options={optionsForStatus}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="form-group mb-3 flex-fill ">
                <label className="form-label">Pool</label>
                <AsyncSelectWithController<PoolProfileFormAttributes, PoolAttributes>
                  control={control}
                  valueFieldName={`statuses.${index}.intPkPoolID` as const}
                  searchField="poolName"
                  readOnly={readOnly}
                  rules={{ required: true }}
                  model="pool"
                />
              </div>
              <CheckboxTileGroup>
                <CheckboxTile
                  name={`statuses.${index}.enable` as const}
                  control={control}
                  title="Enable"
                />
              </CheckboxTileGroup>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ProfilesPage;
