import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";
import { Fragment, FunctionComponent } from "react";

import { CRUDFormProps } from "../../interfaces/CRUDFormProps";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import EditPage from "../../components/EditPage";
import Input from "../../components/FormElements/ControlledInput";
import { VendorAttributes } from "common";
import { useFormContext } from "react-hook-form";

const VendorsPage: FunctionComponent = () => {
  return (
    <EditPage<VendorAttributes>
      modelName="vendor"
      fields={[
        "intPkVendorID",
        "vendorName",
        "website",
        "contactDetails",
        "clientPortalAddress",
        "clientPortalUser",
        "clientPortalPassword",
        "supportContact",
        { key: "enable", type: "boolean" },
      ]}
      idField="intPkVendorID"
      defaultValues={{
        enable: 1,
      }}
      nameField="vendorName"
      renderForm={({ readOnly, model, isEditMode }) => (
        <VendorsForm
          readOnly={readOnly}
          model={model}
          isEditMode={isEditMode}
        />
      )}
    />
  );
};

const VendorsForm = ({ readOnly, model }: CRUDFormProps<VendorAttributes>) => {
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<VendorAttributes>();

  const enableState = watch("enable");

  return (
    <Fragment>
      <ConfirmModal
        title="Are you sure you want to delete this vendor?"
        description="Disabling this vendor will stop all the traffic on this vendor's servers"
        show={model !== undefined && enableState === 0 && model.enable === 1}
        onDenied={() => {
          setValue("enable", 1);
        }}
      />
      <div className="m-3">
        <div className="d-flex flex-row mb-3">
          <Input
            register={register("vendorName", { required: "Required" })}
            readOnly={readOnly}
            title="Vendor Name"
            errorMessage={errors.vendorName?.message}
          />
          <Input
            register={register("website", { required: "Required" })}
            title="Website"
            readOnly={readOnly}
            className="mx-3"
            errorMessage={errors.website?.message}
          />
          <Input
            register={register("contactDetails", { required: "Required" })}
            readOnly={readOnly}
            title="Contact Details"
            errorMessage={errors.contactDetails?.message}
          />
        </div>
        <div className="d-flex flex-row mb-3">
          <Input
            register={register("clientPortalAddress", {
              required: "Required",
            })}
            readOnly={readOnly}
            title="Client Portal Address"
            errorMessage={errors.clientPortalAddress?.message}
          />
          <Input
            register={register("clientPortalUser", { required: "Required" })}
            title="Client Portal User"
            readOnly={readOnly}
            className="mx-3"
            errorMessage={errors.clientPortalUser?.message}
          />
          <Input
            register={register("clientPortalPassword", {
              required: "Required",
            })}
            readOnly={readOnly}
            title="Client Portal Password"
            errorMessage={errors.clientPortalPassword?.message}
          />
        </div>
        <div className="row">
          <div className="col">
            <Input
              register={register("iconUrl", { required: "Required" })}
              readOnly={readOnly}
              title="Icon URL"
              className="mb-3"
              errorMessage={errors.iconUrl?.message}
            />
          </div>
          <div className="col">
            <Input
              register={register("supportContact", { required: "Required" })}
              readOnly={readOnly}
              title="Support Contact"
              className="mb-3"
              errorMessage={errors.supportContact?.message}
            />
          </div>
        </div>
        <CheckboxTileGroup>
          <CheckboxTile
            name="enable"
            readOnly={readOnly}
            control={control}
            title="Enable"
          />
        </CheckboxTileGroup>
      </div>
    </Fragment>
  );
};

export default VendorsPage;
