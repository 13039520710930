import axios from "axios";
import { ServerResponse } from "common";
import { useEffect, useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import { ModelName } from "../../constants";
import useFetcher from "./useFetcher";

interface UseModelAggregationQueryProps {
  filter?: Record<string, any>;
  model: ModelName;
  enabled?: boolean;
  cacheId?: string;
  queryOptions?: Omit<
    UseQueryOptions<ServerResponse<Record<string, any>[]>>,
    "enabled"
  >;
}

export default function useModelAggregationQuery({
  filter,
  model,
  enabled = true,
  cacheId,
  queryOptions = {},
}: UseModelAggregationQueryProps) {
  const fetcher = useFetcher({ alerts: false, invalidateCache: false });
  const querykey = useMemo(() => {
    const querykey = [`${model}_aggregations`] as any[];

    if (cacheId) {
      querykey.push(cacheId);
    }

    if (filter) {
      querykey.push(filter);
    }

    if (querykey.length === 1) {
      return querykey[0];
    }

    return querykey;
  }, [model, cacheId, filter]);

  const result = useQuery<ServerResponse<Record<string, any>[]>>(
    querykey,
    () => {
      const source = axios.CancelToken.source();

      const promise = fetcher<ServerResponse<Record<string, any>[]>>({
        method: "get",
        url: `/api/v1/data/${model}/aggregations`,
        options: {
          params: filter,
          cancelToken: source.token,
        },
      });

      // @ts-ignore Cancel is invalid, but react-query uses it.
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };

      return promise;
    },
    {
      ...queryOptions,
      enabled,
    }
  );

  return result;
}
