import "./Page.scss";

import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { NavLinkCProps as NavLinkProps, navLinks } from "../constants";
import React, { FunctionComponent, useContext, useState } from "react";
import CustomModal from "../components/Modals/Modal";

import { AppContext } from "../libs/state";
import Icon from "@mdi/react";
import useFetcher from "../libs/hooks/useFetcher";
import { useAlert } from "react-alert";
import { useIsNoc } from "../libs/hooks/useIsNoc";

type NavLinkComponentProps = NavLinkProps & {
  onNavLinkClick?: () => void;
};

function NavLink({
  title,
  icon,
  url,
  children,
  secondaryTitle,
  modelName,
  onNavLinkClick,
}: React.PropsWithChildren<NavLinkComponentProps>) {
  const { url: baseUrl } = useRouteMatch();
  const { pathname: currentPath } = useLocation();
  const isNoc  = useIsNoc();
  const [dropDownShown, setDropDownShown] = useState(false);
  const context = useContext(AppContext);
  const allowedTableNames = context.allowedTables.map(
    (e) => e.intPkTable?.tableName
  );

  const link = `${baseUrl}${url}`;
  const dropDownClass = children ? "dropdown" : "";
  const dropDownShowClass = dropDownShown ? "show" : "";
  const mainLinkClassName = `nav-link ${
    currentPath.startsWith(link) ? "active" : ""
  } ${dropDownClass}-toggle ${dropDownShowClass}`;

  const mainkLinkChildren = () => (
    <>
      {icon && (
        <span className="nav-link-icon d-inline-block">
          <Icon path={icon} size={1} />
        </span>
      )}
      <span className="nav-link-title">{title}</span>
    </>
  );

  if (
    allowedTableNames.includes(modelName) ||
    (children && children?.some((e) => allowedTableNames.includes(e.modelName) && (!e.forceHideForNoc || !isNoc)))
  )
    return (
      <li className={`nav-item ${dropDownClass}`}>
        {children ? (
          <a
            className={mainLinkClassName}
            onClick={(_) => setDropDownShown(!dropDownShown)}
          >
            {mainkLinkChildren()}
          </a>
        ) : (
          <Link
            className={mainLinkClassName}
            to={link}
            onClick={onNavLinkClick}
          >
            {mainkLinkChildren()}
          </Link>
        )}
        {children && (
          <div className={`dropdown-menu ${dropDownShowClass}`}>
            {allowedTableNames.includes(modelName) && (
              <Link
                className={`dropdown-item ${
                  currentPath === link ? "active" : ""
                }`}
                onClick={onNavLinkClick}
                to={link}
              >
                {secondaryTitle ? secondaryTitle : "Manage"}
              </Link>
            )}
            {children.map((e) => {
              if (allowedTableNames.includes(e.modelName))
                return (
                  <Link
                    key={link + e.url}
                    className={`dropdown-item  ${
                      currentPath === link + e.url ? "active" : ""
                    }`}
                    to={`${link}${e.url}`}
                    onClick={onNavLinkClick}
                  >
                    {e.title}
                  </Link>
                );

              return null;
            })}
          </div>
        )}
      </li>
    );

  return null;
}

const UserProfileButton = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const context = useContext(AppContext);

  return (
    <div className="nav-item dropdown">
      <a
        href="#"
        className="nav-link d-flex lh-1 text-reset p-0"
        aria-label="Open user menu"
        onClick={(_) => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span className="avatar avatar-sm border overflow-hidden">
          <img src="/images/default_pp.jpg" alt="avatar" />
        </span>
        <div className="d-none d-xl-block ps-2">
          <div>{context.user?.userName}</div>
          <div className="mt-1 small text-muted">{context.user?.role}</div>
        </div>
      </a>
      <div
        className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${
          isDropdownOpen ? "show" : ""
        }`}
      >
        <a
          href="#"
          onClick={async (_) => {
            context.logout();
          }}
          className="dropdown-item"
        >
          Logout
        </a>
      </div>
    </div>
  );
};
const Page: FunctionComponent<{}> = ({ children }) => {
  const [navBarCollapsed, setNavBarCollapsed] = useState(true);
  const [confirmationShowed, setConfirmationShown] = useState(false);
  const alert = useAlert();
  const fetcher = useFetcher({ alerts: false });

  const handleNavBarToggleClick = () => {
    setNavBarCollapsed(!navBarCollapsed);
  };

  return (
    <div className="wrapper">
      <CustomModal
        onHide={() => setConfirmationShown(false)}
        onPrimaryButtonClick={() => {
          fetcher({
            url: "/api/v1/shutdown",
            method: "get",
          }).catch((e) => {
            alert.success("Successfully shutdown");
          });

          setConfirmationShown(false);
        }}
        show={confirmationShowed}
        theme="danger"
        title="Reboot Vezmon"
        primaryButtonText="Confirm"
        size="sm"
      >
        <div className="m-3">
          <p>Are you sure?</p>
        </div>
      </CustomModal>
      <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <button
            className={`navbar-toggler ${navBarCollapsed ? "collapsed" : ""}`}
            type="button"
            onClick={handleNavBarToggleClick}
            aria-expanded={!navBarCollapsed}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark">
            <Link to="/dashboard">VPN Dashboard</Link>
          </h1>

          <div className="d-lg-none">
            <UserProfileButton />
          </div>
          <button
            className="btn btn-danger"
            onClick={() => {
              setConfirmationShown(true);
            }}
          >
            Reboot VEZMON
          </button>

          <div
            className={`mt-3 mt-lg-0 navbar-collapse collapse ${
              navBarCollapsed ? "" : "show"
            }`}
          >
            <ul className="navbar-nav pt-lg-3">
              {navLinks.map((each) => (
                <NavLink
                  key={each.title}
                  {...each}
                  onNavLinkClick={handleNavBarToggleClick}
                />
              ))}
            </ul>
          </div>

          {!navBarCollapsed && <div className="p-3"></div>}
        </div>
      </aside>
      <header className="navbar navbar-expand navbar-transparent d-none d-lg-flex">
        <div className="container-xl justify-content-end">
          <div className="navbar-nav">
            <UserProfileButton />
          </div>
        </div>
      </header>
      {children}
    </div>
  );
};

export default Page;
