import CheckboxTile, {
  CheckboxTileGroup,
} from "../../components/FormElements/CheckboxTile";

import { AsnAttributes } from "common";
import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import EditPage from "../../components/EditPage";
import { FunctionComponent } from "react";
import Input from "../../components/FormElements/ControlledInput";
import { IspAttributes } from "common";

type AsnFormAttributes = AsnAttributes & {
  intPkIsp: IspAttributes;
};

const AsnPage: FunctionComponent = () => {
  return (
    <EditPage<AsnFormAttributes>
      modelName="asn"
      fields={[
        "intPkAsnID",
        "ASN",
        "intPkIsp.ispName",
        { key: "enable", type: "boolean" },
      ]}
      idField="intPkAsnID"
      nameField="ASN"
      title="ASN"
      defaultValues={{ enable: 1 }}
      renderForm={({
        register,
        errors,
        control,
        readOnly,
        model,
      }) => {
        return (
          <div className="m-3">
            <div className="d-flex flex-row mb-3">
              <Input
                register={register("ASN", {
                  required: true,
                })}
                title="ASN"
                readOnly={readOnly}
                errorMessage={errors.ASN ? "Required" : ""}
              />
            </div>
            <div className="form-group mb-3 flex-fill ">
              <label className="form-label">ISP</label>
              <AsyncSelectWithController<AsnFormAttributes, IspAttributes>
                control={control}
                valueFieldName="intPkIspID"
                readOnly={readOnly}
                searchField="ispName"
                imageField="ispLogoUrl"
                rules={{ required: true }}
                model="isp"
              />
            </div>

            <CheckboxTileGroup>
              <CheckboxTile
                control={control}
                name="enable"
                title="Enable"
                readOnly={readOnly}
              />
            </CheckboxTileGroup>
          </div>
        );
      }}
    />
  );
};

export default AsnPage;
