import {
  AsnAttributes,
  BrandAttributes,
  BrandsStatusAttributes,
  CountryAttributes,
  DataCenterAttributes,
  DeviceAttributes,
  DevicePlatformAttributes,
  IspAttributes,
  JsonConfigAttributes,
  NodeAttributes,
  NodeLegSessionAttributes,
  NodeLegSessionModel,
  NodeTypeAttributes,
  PoolProfileAttributes,
  SplitTunnelProfileAttributes,
  VendorAttributes,
  VezgateProxyProfileAttributes,
} from "common";
import { useState, useMemo, useEffect, Fragment } from "react";
import { useFormContext } from "react-hook-form";
import EditPage, { getFieldKey } from "../../components/EditPage";
import {
  FilterPicker,
  removeNullishValuesExceptNull,
} from "../../components/FilterPicker";
import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import Input from "../../components/FormElements/ControlledInput";
import RadioSelect, {
  RadioSelectWithController,
} from "../../components/FormElements/RadioSelect";
import FieldSelector from "../../components/Modals/FieldsSelector";
import { NullComponent } from "../../components/NullComponent";
import {
  connectSessionDefaultShownFields,
  connectSessionFields,
} from "../../constants";
import useUserPreferencesQuery from "../../libs/hooks/useUserPreferences";

interface NodeLegSessionFilterProps {
  current?: boolean;
}

export function NodeLegSessionFilter({
  current = false,
}: NodeLegSessionFilterProps) {
  const methods = useFormContext();
  const [gate, setGate] = useState<"and" | "or">();

  useEffect(() => {
    if (!gate) {
      const and = removeNullishValuesExceptNull(methods.getValues("and"));
      const or = removeNullishValuesExceptNull(methods.getValues("or"));

      if (!and && or) {
        setGate("or");
      } else if (!or && and) {
        setGate("and");
      } else if (!or && !and) {
        setGate("and");
      }
    }
  }, []);

  const [
    intPkVendorID,
    intPkCountryID,
    intPkIspID,
    intPkDataCenterID,
    sessionConnectTimeGte,
    sessionConnectTimeLte,
  ] = methods.watch([
    "intPkVendorID",
    "intPkCountryID",
    "intPkIspID",
    "intPkDataCenterID",
    "and._11.or._0.sessionConnectTime.gte",
    "and._12.or._0.sessionConnectTime.lte",
  ]);

  return (
    <div className="m-3">
      <div className="w-50 mb-2">
        <RadioSelect<"or" | "and">
          options={[
            { label: "AND", value: "and" },
            { label: "OR", value: "or" },
          ]}
          onOptionSelect={(e) => {
            if (e && typeof e === "string") {
              setGate(e);
              if (e === "and") {
                methods.setValue("or", undefined);
              } else {
                methods.setValue("and", undefined);
              }
            }
          }}
          name="doesntmatter"
          title="Search Logic"
          value={gate}
        />
      </div>
      <div className="row">
        <div className="col">
          <Input
            register={methods.register(`${gate}._0.nodeIP`)}
            title="Node IP"
            className="mb-3"
          />
        </div>
        <div className="col">
          <Input
            register={methods.register(`${gate}._1.userRequestSourceIPv4`)}
            title="User Request Source IPv4"
            className="mb-3"
          />
        </div>
        <div className="col">
          <Input
            register={methods.register(`${gate}._2.natIP`)}
            title="NatIP"
            className="mb-3"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {" "}
          <Input
            register={methods.register(`${gate}._3.natInterface`)}
            title="NatInterface"
            className="mb-3"
          />
        </div>
        <div className="col">
          <Input
            register={methods.register(`${gate}._4.deviceID`)}
            title="DeviceID"
            className="mb-3"
          />
        </div>
        <div className="col">
          <Input
            register={methods.register(`${gate}._5.serverSessionID`, {
              valueAsNumber: true,
            })}
            type="number"
            className="mb-3"
            title="Server Session ID"
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Vendor</label>
            <AsyncSelectWithController<any, VendorAttributes>
              searchField="vendorName"
              model="vendor"
              control={methods.control}
              valueFieldName="intPkVendorID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Data Center</label>
            <AsyncSelectWithController<any, DataCenterAttributes>
              searchField="dataCenterName"
              model="dataCenter"
              filter={{ intPkVendorID }}
              control={methods.control}
              valueFieldName="intPkDataCenterID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Node</label>
            <AsyncSelectWithController<any, NodeAttributes>
              searchField="name"
              model="node"
              filter={{ intPkDataCenterID }}
              control={methods.control}
              valueFieldName="intPkNodeID"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Device</label>
            <AsyncSelectWithController<any, DeviceAttributes>
              searchField="intPkDeviceID"
              model="device"
              control={methods.control}
              valueFieldName="intPkDeviceID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">User's Country</label>
            <AsyncSelectWithController<any, CountryAttributes>
              searchField="countryName"
              model="country"
              imageField="flagImageUrl"
              control={methods.control}
              valueFieldName="intPkCountryID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">ISP</label>
            <AsyncSelectWithController<any, IspAttributes>
              searchField="ispName"
              filter={{ intPkCountryID }}
              model="isp"
              control={methods.control}
              valueFieldName="intPkIspID"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">ASN</label>
            <AsyncSelectWithController<any, AsnAttributes>
              searchField="ASN"
              filter={{ intPkIspID, countryFilter: { intPkCountryID } }}
              model="asn"
              control={methods.control}
              valueFieldName="intPkAsnID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">JSON Config</label>
            <AsyncSelectWithController<any, JsonConfigAttributes>
              searchField="configName"
              model="jsonConfig"
              control={methods.control}
              valueFieldName="intPkConfigID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Split Tunnel</label>
            <AsyncSelectWithController<any, SplitTunnelProfileAttributes>
              searchField="configName"
              model="splitTunnelProfile"
              control={methods.control}
              valueFieldName="intPkSplitTunnelID"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Vezgate Proxy Profile</label>
            <AsyncSelectWithController<any, VezgateProxyProfileAttributes>
              searchField="profileName"
              model="vezgateProxyProfile"
              control={methods.control}
              valueFieldName="intPkVezgateProxyProfileID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Node Type</label>
            <AsyncSelectWithController<any, NodeTypeAttributes>
              searchField="type"
              model="nodeType"
              control={methods.control}
              valueFieldName="intPkNodeTypeID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Brand Status</label>
            <AsyncSelectWithController<any, BrandsStatusAttributes>
              searchField="intPkBrandsStatusID"
              model="brandStatus"
              control={methods.control}
              valueFieldName="intPkBrandsStatusID"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Pool Profile</label>
            <AsyncSelectWithController<any, PoolProfileAttributes>
              searchField="poolProfileName"
              model="poolProfile"
              control={methods.control}
              valueFieldName="intPkPoolProfileID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Device Platform</label>
            <AsyncSelectWithController<any, DevicePlatformAttributes>
              searchField="platform"
              model="devicePlatform"
              control={methods.control}
              valueFieldName="intPkDevicePlatformID"
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 flex-fill">
            <label className="form-label">Brand</label>
            <AsyncSelectWithController<any, BrandAttributes>
              searchField="brandName"
              model="brand"
              control={methods.control}
              valueFieldName="intPkBrandID"
            />
          </div>
        </div>
      </div>
      <div className="row">
        {!current && (
          <div className="col mb-3">
            <RadioSelectWithController
              name="isConnectOrDisconnect"
              control={methods.control}
              title="Connect Status"
              options={[
                {
                  label: "All",
                  value: undefined,
                },
                { label: "Connected", value: 1 },
                { label: "Disconnected", value: 0 },
              ]}
            />
          </div>
        )}
        <div className="col mb-3">
          <RadioSelectWithController
            name="isTestDevice"
            control={methods.control}
            title="isTestDevice"
            options={[
              {
                label: "All",
                value: undefined,
              },
              { label: "Yes", value: 1 },
              { label: "No", value: 0 },
            ]}
          />
        </div>
      </div>

      <h4>Session Duration</h4>
      <div className="row mb-3">
        <div className="col">
          <label className="form-label">Min</label>
          <input
            type="number"
            className="form-control"
            placeholder="0"
            {...methods.register("and._7.durationSeconds.gte")}
          />
        </div>
        <div className="col">
          <label className="form-label">Max</label>
          <input
            type="number"
            placeholder="0"
            className="form-control"
            {...methods.register("and._8.durationSeconds.lte")}
          />
        </div>
      </div>
      <h4>Total Bytes</h4>
      <div className="row mb-3">
        <div className="col">
          <label className="form-label">Min</label>
          <input
            type="number"
            className="form-control"
            placeholder="0"
            {...methods.register("and._9.totalBytes.gte")}
          />
        </div>
        <div className="col">
          <label className="form-label">Max</label>
          <input
            type="number"
            placeholder="0"
            className="form-control"
            {...methods.register("and._10.totalBytes.lte")}
          />
        </div>
      </div>
      <h4>Session Connect Time</h4>
      <div className="row">
        <div className="col">
          <label className="form-label">From</label>
          <input
            type="datetime-local"
            className="form-control"
            {...methods.register("and._11.or._0.sessionConnectTime.gte")}
          />
          <input
            type="hidden"
            {...methods.register(
              "and._11.or._1.estimatedSessionConnectTime.gte"
            )}
            value={sessionConnectTimeGte}
          />
        </div>
        <div className="col">
          <label className="form-label">To</label>
          <input
            type="datetime-local"
            className="form-control"
            {...methods.register("and._12.or._0.sessionConnectTime.lte")}
          />
          <input
            type="hidden"
            {...methods.register(
              "and._12.or._1.estimatedSessionConnectTime.lte"
            )}
            value={sessionConnectTimeLte}
          />
        </div>
      </div>
      {!current && (
        <Fragment>
          <h4 className="mt-3">Session Disconnect Time</h4>
          <div className="row">
            <div className="col">
              <label className="form-label">From</label>
              <input
                type="datetime-local"
                className="form-control"
                {...methods.register("and._13.sessionDisconnectTime.gte")}
              />
            </div>
            <div className="col">
              <label className="form-label">To</label>
              <input
                type="datetime-local"
                className="form-control"
                {...methods.register("and._14.sessionDisconnectTime.lte")}
              />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

const NodeLegSessionPage = () => {
  const { data, savePreference } = useUserPreferencesQuery(
    "nodeLegSession.shownFields"
  );

  const [shownFieldNames, setShownFieldNames] = useState(
    connectSessionDefaultShownFields
  );

  const shownFields = useMemo(
    () =>
      connectSessionFields.filter((e) => {
        const fieldName = getFieldKey(e, false);
        return shownFieldNames[fieldName] !== false; // true if undefined
      }),
    [shownFieldNames]
  );

  useEffect(() => {
    if (data?.preferenceValue)
      setShownFieldNames({ ...shownFieldNames, ...data.preferenceValue });
  }, [data]);

  const [filter, setFilter] = useState<Record<string, any>>({});

  return (
    <EditPage<NodeLegSessionAttributes, NodeLegSessionModel>
      modelName="nodeLegSession"
      idField="intPkNodeLegSessionID"
      fields={shownFields as any}
      filter={filter}
      showAggregations
      renderCardHeader={(data) => (
        <Fragment>
          <FieldSelector<NodeLegSessionModel>
            selectedFields={shownFieldNames}
            onFieldsSelectionChange={(e) => {
              setShownFieldNames(e);
              savePreference(e);
            }}
          />
          <FilterPicker
            filter={filter}
            onFilter={(e) => setFilter(e)}
            modalSize="lg"
          >
            <NodeLegSessionFilter />
          </FilterPicker>
        </Fragment>
      )}
      nameField="intPkNodeLegSessionID"
      renderForm={(_) => <NullComponent />}
      isRecordDeletable={() => false}
      showCreateButton={false}
      isRecordEditable={() => false}
    />
  );
};

export default NodeLegSessionPage;
