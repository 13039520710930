import React, { FunctionComponent } from "react";
import {
  UserAttributes,
  UserModel,
} from "common";

import AsyncSelectWithController from "../../components/FormElements/AsyncSelect";
import EditPage from "../../components/EditPage";
import Input from "../../components/FormElements/ControlledInput";
import { RoleAttributes } from "common";

const UsersPage: FunctionComponent = () => {
  return (
    <EditPage<UserModel>
      modelName="user"
      fields={["intPkUserId", "userName", "intPkRole.roleName"]}
      idField="intPkUserId"
      nameField="userName"
      renderForm={({ register, errors, isEditMode, control, readOnly, model }) => (
        <div className="m-3">
          <Input
            register={register("userName", { required: "Required" })}
            readOnly={readOnly}
            title="User Name"
            errorMessage={errors.userName?.message}
          />
          <Input
            register={register("password", {
              required: !isEditMode,
              setValueAs: (p) => (p === "" ? undefined : p),
            })}
            title={`Password ${isEditMode ? "(leave blank if don't want to edit)" : ""}`}
            className="mt-3"
            readOnly={readOnly}
            type="password"
            errorMessage={errors.password?.message}
          />
          <div className="form-group flex-fill mt-3">
            <label className="form-label">Role</label>
            <AsyncSelectWithController<UserAttributes, RoleAttributes>
              control={control}
              readOnly={readOnly}
              searchField="roleName"
              valueFieldName="intPkRoleID"
              rules={{ required: "Required" }}
              model="role"
            />
          </div>
        </div>
      )}
    />
  );
};

export default UsersPage;
