import { GoogleIapSubscriptionAttributes } from "common";
import EditPage from "../../components/EditPage";
import { NullComponent } from "../../components/NullComponent";

export const GoogleIAPSubscriptionsPage: React.FunctionComponent = () => {
  return (
    <EditPage<GoogleIapSubscriptionAttributes, GoogleIapSubscriptionAttributes>
      modelName="googleIAPSubscription"
      nameField="intPkGoogleIapSubscriptionID"
      idField="intPkGoogleIapSubscriptionID"
      title="Google IAP Subscriptions"
      fields={["intPkBrandID", "orderIDFromApp", "purchaseTime", "purchaseState"]}
      renderForm={(_) => (<NullComponent/>)}
      isRecordDeletable={() => false}
      isRecordEditable={() => false}
      showCreateButton={false}
    />
  );
};

