import {
  CurrentConnectedSessionAttributes,
  CurrentConnectedSessionModel,
} from "common";
import { Fragment, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import EditPage, { getFieldKey } from "../../components/EditPage";
import { FilterPicker } from "../../components/FilterPicker";
import FieldSelector from "../../components/Modals/FieldsSelector";
import { NullComponent } from "../../components/NullComponent";
import {
  connectSessionDefaultShownFields,
  connectSessionFields,
} from "../../constants";
import useUserPreferencesQuery from "../../libs/hooks/useUserPreferences";
import { NodeLegSessionFilter } from "./NodeLegSession";

const CurrentConnectedSessionPage = () => {
  const { data, savePreference } = useUserPreferencesQuery(
    "currentConnectedSession.shownFields"
  );

  const [shownFieldNames, setShownFieldNames] = useState(
    connectSessionDefaultShownFields
  );

  const shownFields = useMemo(
    () =>
      connectSessionFields.filter((e) => {
        const fieldName = getFieldKey(e, false);
        return shownFieldNames[fieldName] !== false; // true if undefined
      }),
    [shownFieldNames]
  );

  useEffect(() => {
    if (data?.preferenceValue)
      setShownFieldNames({ ...shownFieldNames, ...data.preferenceValue });
  }, [data]);

  const [filter, setFilter] = useState({});

  return (
    <EditPage<CurrentConnectedSessionAttributes, CurrentConnectedSessionModel>
      modelName="currentConnectedSession"
      filter={filter}
      idField="intPkCurrentConnectedSessionID"
      renderCardHeader={() => (
        <Fragment>
          <FieldSelector<CurrentConnectedSessionModel>
            selectedFields={shownFieldNames}
            onFieldsSelectionChange={(e) => {
              setShownFieldNames(e);
              savePreference(e);
            }}
          />
          <FilterPicker
            filter={filter}
            onFilter={(e) => setFilter(e)}
            modalSize="lg"
          >
            <NodeLegSessionFilter current />
          </FilterPicker>
        </Fragment>
      )}
      fields={shownFields as any}
      nameField="intPkCurrentConnectedSessionID"
      renderForm={(_) => <NullComponent />}
      isRecordDeletable={() => false}
      isRecordEditable={() => false}
      showCreateButton={false}
    />
  );
};

export default CurrentConnectedSessionPage;
